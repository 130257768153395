//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/styles/signupPage.module.css";
import login_bg_right from "../../assets/images/login_bg_right.png";
import login_bg_rightTabPhone from "../../assets/images/login_bg_rightTabPhone.png";
import login_bg_top from "../../assets/images/login_bg_top.png";
import login_bg_bottom from "../../assets/images/login_bg_bottom.png";
import login_bg_topTabPhone from "../../assets/images/login_bg_topTabPhone.png";
import login_bg_bottomTabPhone from "../../assets/images/login_bg_bottomTabPhone.png";
import login_bg_centre from "../../assets/images/login_bg_centre.png";
import A_jain_logo from "../../assets/images/A_Jain_logo.png";
import instagramHandle from "../../assets/images/instagram.png";
import facebookHandle from "../../assets/images/facebook.png";
import openEye from "../../assets/images/openEye.png";
import closedEye from "../../assets/images/closedEye.png";
import errorIcon from "../../assets/images/error-icon.png";
import MediumPassword from "../../assets/images/error_medium.png";
import strongPassword from "../../assets/images/error_strong.png";
import Request from "axios";
import { BASE_URL } from "../../components/baseURL";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function SignupPage() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [showPass, setShowPass] = useState(false);
  const [showPassconfirm, setShowPassconfirm] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const firstName = useRef();
  const lastName = useRef();
  const email = useRef();
  const mobileNumber = useRef();
  const password = useRef();
  const [passVali, setPassVali] = useState();
  const confirmPass = useRef();
  const navigation = useNavigate();
  const [error, setError] = useState(false);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function validate() {
    let checklist = [
      { id: "emailValidate", name: "errorMessageEmail", ref: email, type: "email" },
      { id: "passwordValidate", name: "errorMessagePassword", ref: password, type: "password" },
      { id: "firstname", name: "errorMessageFirstName", ref: firstName },
      { id: "lastname", name: "errorMessageLastName", ref: lastName },
      { id: "mobilenumber", name: "errorMessageMobileNumber", ref: mobileNumber, type: "number" },
      {
        id: "confirmpass",
        name: "errorMessageConfirmPassword",
        ref: confirmPass,
        type: "password",
      },
    ];
    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      let regrx = new RegExp(
        val.type === "email"
          ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
          : val.type === "password"
          ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
          : val.type === "number"
          ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
          : ""
      );
      if (val.ref.current.value?.length < 1) {
        setError(true);
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else if (!regrx.test(val.ref.current.value)) {
        setError(true);
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter Valid ${val.type}`;
      } else {
        setError(false);
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
      return 0;
    });
    if (password.current.value !== confirmPass.current.value) {
      let propStyle = document.getElementById("confirmpass");
      let propText = document.getElementById("errorMessageConfirmPassword");
      propStyle.style.border = "2px solid red";
      propText.style.display = "block";
      propText.textContent = `Please Enter same password`;
    } else {
      let propStyle = document.getElementById("confirmpass");
      let propText = document.getElementById("errorMessageConfirmPassword");
      propStyle.style.border = "1.5px solid #D9D9D9";
      propText.style.display = "none";
      propText.textContent = "none";

      !error && createUser();
    }
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

  useEffect(() => {
    if (passVali === "") {
      setError(true);
      document.getElementById("passwordTooltipicn").style.display = "block";
      document.getElementById("mediumPasswordTooltipicn").style.display = "none";
      document.getElementById("strongPasswordTooltipicn").style.display = "none";
      document.getElementById("showpasswordId").style.right = "52px";
    } else {
      setError(false);
      document.getElementById("passwordBlank").style.display = "none";
      document.getElementById("passwordTooltipicn").style.display = "none";
      document.getElementById("mediumPasswordTooltipicn").style.display = "none";
      document.getElementById("strongPasswordTooltipicn").style.display = "none";
      document.getElementById("passworderrorWeak").style.display = "none";
      document.getElementById("passworderrorMedium").style.display = "none";
      document.getElementById("passworderrorStrong").style.display = "none";
      // document.getElementById("signupPage_showPasswd__KhXEf").style.right = "25px";
    }

    if (passVali?.length < 8 && passVali <= 18) {
      setError(true);

      document.getElementById("passwordTooltipicn").style.display = "block";
      document.getElementById("mediumPasswordTooltipicn").style.display = "none";
      document.getElementById("strongPasswordTooltipicn").style.display = "none";
      document.getElementById("passwordBlank").style.display = "none";
      document.getElementById("passworderrorWeak").style.display = "block";
      document.getElementById("passworderrorMedium").style.display = "none";
      document.getElementById("passworderrorStrong").style.display = "none";
      document.getElementById("showpasswordId").style.right = "52px";
    } else if (
      (passVali?.search(/[a-z]/) < 0 && passVali?.search(/[A-Z]/) < 0) ||
      passVali?.search(/\d/) < 0 ||
      passVali?.search(/[^a-zA-Z\d]/) < 0
    ) {
      setError(true);
      document.getElementById("passwordTooltipicn").style.display = "none";
      document.getElementById("mediumPasswordTooltipicn").style.display = "block";
      document.getElementById("strongPasswordTooltipicn").style.display = "none";
      document.getElementById("passwordBlank").style.display = "none";
      document.getElementById("passworderrorWeak").style.display = "none";
      document.getElementById("passworderrorMedium").style.display = "block";
      document.getElementById("passworderrorStrong").style.display = "none";
      document.getElementById("showpasswordId").style.right = "52px";
    } else if (
      (passVali?.match(/[a-z]/) && passVali?.match(/[A-Z]/)) ||
      passVali?.match(/\d/) ||
      passVali?.match(/[^a-zA-Z\d]/)
    ) {
      setError(true);
      document.getElementById("passwordTooltipicn").style.display = "none";
      document.getElementById("mediumPasswordTooltipicn").style.display = "none";
      document.getElementById("strongPasswordTooltipicn").style.display = "block";
      document.getElementById("passwordBlank").style.display = "none";
      document.getElementById("passworderrorWeak").style.display = "none";
      document.getElementById("passworderrorMedium").style.display = "none";
      document.getElementById("passworderrorStrong").style.display = "block";
      document.getElementById("showpasswordId").style.right = "52px";

      //  else {
      //   setFirstLoad(true);
      // }
      // }
    }
  }, [passVali]);

  //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//
  
  //---------------------------------------------------------Create User Function-----------------------------------------------------------------------//

  async function createUser() {
    if (document.getElementById("rememberme").checked) {
      Request.post(`${BASE_URL}client/registration`, {
        Firstname: firstName.current.value,
        Lastname: lastName.current.value,
        Emailid: email.current.value,
        Mobilenumber: mobileNumber.current.value,
        Password: password.current.value,
      })
        .then((res) => {
          if (res?.data?.status === 200) {
            alert("Account created. Please verify yourself on the email sent to you");
            navigation("/");
          } else if (res?.data?.error === "Email already registered!") {
            alert("Email already registered");
          } else {
            alert("Error creating account");
          }
        })
        .catch((err) => {
          console.log("Error registering user: ", err);
        });
    } else {
      alert("Please agree to the terms and conditions");
    }
  }
  
  //---------------------------------------------------------Create User Function-----------------------------------------------------------------------//

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftInputs}>
        <img className={styles.AJainLogo} src={A_jain_logo} alt="" />
        <label className={styles.signInText}>Sign Up</label>
        <label className={styles.belowSignInText}>Create your new account</label>
        <div className={styles.inputCred}>
          <input type="text" placeholder="First Name" ref={firstName} id="firstname" />
          <label className={styles.errorSpan} id="errorMessageFirstName"></label>
          <input type="text" placeholder="Last Name" ref={lastName} id="lastname" />
          <label className={styles.errorSpan} id="errorMessageLastName"></label>
          <input type="email" placeholder="Email ID" ref={email} id="emailValidate" />
          <label className={styles.errorSpan} id="errorMessageEmail"></label>

          <input type="text" placeholder="Mobile No." ref={mobileNumber} id="mobilenumber" />
          <label className={styles.errorSpan} id="errorMessageMobileNumber"></label>
          <div className={styles.passwordWithEye}>
            <input
              type={showPass ? "text" : "password"}
              name="pwd"
              placeholder="Password"
              ref={password}
              id="passwordValidate"
              onChange={(text) => setPassVali(text?.target?.value)}
            />
            <button
              type="button"
              className={styles.showPasswd}
              id="showpasswordId"
              onClick={() => {
                setShowPass(() => !showPass);
              }}
            >
              {/* <img src={openEye} alt="" /> */}
              <img src={showPass ? openEye : closedEye} alt="" />
            </button>

            <div
              className={styles.tooltipinput + " " + styles.passwordTooltip}
              id="passwordTooltip"
            >
              <img
                className={styles.cvvinputicncls}
                src={errorIcon}
                alt=""
                id="passwordTooltipicn"
                style={{ display: "none" }}
              />
              <img
                className={styles.cvvinputicncls}
                src={MediumPassword}
                alt=""
                id="mediumPasswordTooltipicn"
                style={{ display: "none" }}
              />
              <img
                className={styles.cvvinputicncls}
                src={strongPassword}
                alt=""
                id="strongPasswordTooltipicn"
                style={{ display: "none" }}
              />
              <label
                className={styles.tooltiptextinput + " " + styles.passwordBlankcls}
                style={{ display: "none" }}
                id="passwordBlank"
              >
                Please enter Password.
              </label>
              <label
                className={styles.tooltiptextinput + " " + styles.passworderrorWeakcls}
                id="passworderrorWeak"
                style={{ display: "none" }}
              >
                . Weak: (should have at least 8 character long.)
              </label>
              <label
                className={styles.tooltiptextinput + " " + styles.passworderrorMediumcls}
                id="passworderrorMedium"
                style={{ display: "none" }}
              >
                . Medium: (Password must have at least one alphabet, number and special character).
              </label>
              <label
                className={styles.tooltiptextinput + " " + styles.passworderrorStrongcls}
                id="passworderrorStrong"
                style={{ display: "none" }}
              >
                . Strong Password.
              </label>
            </div>
          </div>
          <label className={styles.errorSpan} id="errorMessagePassword"></label>
          <div className={styles.passwordWithEye}>
            <input
              type={showPassconfirm ? "text" : "password"}
              name="pwd"
              placeholder=" Confirm Password"
              id="confirmpass"
              ref={confirmPass}
            />
            <button
              type="button"
              className={styles.showPasswd}
              onClick={() => {
                setShowPassconfirm(() => !showPassconfirm);
              }}
            >
              <img src={showPassconfirm ? openEye : closedEye} alt="" />
            </button>
          </div>
          <label className={styles.errorSpan} id="errorMessageConfirmPassword"></label>
        </div>

        <div className={styles.rememberMeAndForgotPass}>
          <div className={styles.rememberMeDiv}>
            <input type="checkbox" id="rememberme" />
            <label className={styles.conditonText}>
              I agree to the terms and conditions of the Healvick.com and consent to follow the
              norms as stated. <br />
              <a
                className={styles.conditonText}
                onClick={() => {
                  window.open("https://healvick.com/privacy-policy");
                }}
              >
                Click here{" "}
              </a>
              to view our Terms of Use
            </label>
          </div>
        </div>
        <button className={styles.signInButton} onClick={() => validate()}>
          Sign Up
        </button>
        <div className={styles.accountDiv}>
          <label>Already have an account?</label>{" "}
          <label
            onClick={() => {
              navigation("/");
            }}
            className={styles.signupRedirectALink}
          >
            Sign In
          </label>
        </div>
        <div className={styles.followUsTextAndTag}>
          <div className={styles.followUsText}>
            <label>Follow Us</label>
          </div>
          <div className={styles.followUsTag}>
            <a
              href="https://instagram.com/dt.arpita_healvick?igshid=NGVhN2U2NjQ0Yg=="
              target="_blank"
            >
              <img src={instagramHandle} alt="" />
            </a>
            <a href="https://www.facebook.com/healvick?mibextid=hrBMPu" target="_blank">
              <img src={facebookHandle} alt="" />
            </a>
            {/* <img src={twitterHandle} alt="" /> */}
          </div>
        </div>
      </div>
      <div className={styles.rightImages}>
        <div className={styles.rightImagesInsideParentOne}>
          <div className={styles.rightImagesInsideChildOne}>
            <img className={styles.loginBgTop} src={login_bg_top} alt="" />
            <img className={styles.loginBgBottom} src={login_bg_bottom} alt="" />
          </div>
          <img className={styles.loginBgRight} src={login_bg_right} alt="" />
        </div>
        <div className={styles.rightImagesInsideParentTwo}>
          <img className={styles.loginBgCentre} src={login_bg_centre} alt="" />
        </div>
      </div>
      <div className={styles.rightImagesTabPhone}>
        <div className={styles.rightImagesInsideParentOneTabPhone}>
          <img className={styles.loginBgRightTabPhone} src={login_bg_rightTabPhone} alt="" />
          <div className={styles.rightImagesInsideParentTwoTabPhone}>
            <img className={styles.loginBgCentreTabPhone} src={login_bg_centre} alt="" />
          </div>
          <div className={styles.rightImagesInsideChildOneTabPhone}>
            <img className={styles.loginBgTopTabPhone} src={login_bg_topTabPhone} alt="" />
            <img className={styles.loginBgBottomTabPhone} src={login_bg_bottomTabPhone} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
