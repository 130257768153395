//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from "react";
import crossButton from "../assets/images/notificationCloseButotn.png";
import styles from "../assets/styles/Parent.module.css";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function TrackerNotification({ tracker, val, refresh, client, date }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  let todays_date = moment();
  let CreatedDate = moment(val.CreatedDate);
  if (client) {
    CreatedDate = moment(date);
  }
  let dateDiff = todays_date.diff(CreatedDate, "days");
  let duration = ""
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //-------------------------------------------------------------------Set Duration-----------------------------------------------------------------------------//

  if (dateDiff == 1) {
    duration = "1 day ago";
  } else if (dateDiff == 0) {
    duration = "Today";
  } else {
    duration = `${dateDiff} days ago`;
  }

  //-------------------------------------------------------------------Set Duration-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------Remove Notification Function----------------------------------------------------------------------------//

  function removeNotification() {
    Request.post(`${BASE_URL}notifications/removeNotification`, {
      id: val["Notification_id"],
      table_name:
        tracker == "Weight"
          ? "weighttracker"
          : tracker == "Inch"
          ? "inchlosstracker"
          : tracker == "Invoice"
          ? "invoice"
          : tracker == "Photo"
          ? "phototracker"
          : "",
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          refresh();
        }
      })
      .catch((err) => {
        console.log("Error removing notification: ", err);
      });
  }

  //-------------------------------------------------------------------Remove Notification Function----------------------------------------------------------------------------//

  return (
    <>
      <div className={styles.divider}></div>
      <div className={styles.notificationTwo}>
        <div className={styles.dotDiv}></div>
        <label className={styles.notificationTwoLabel}>
          {tracker} has been updated{" "}
          {client && tracker === "Invoice"
            ? `for you. You can download it from my profile section (${duration})`
            : `by ${val.Firstname + " " + val.Lastname} (${duration})`}
          .
        </label>
        <img
          className={styles.crossButtonNoti}
          onClick={() => {
            removeNotification();
          }}
          src={crossButton}
          alt=""
        />
      </div>
    </>
  );
}

export default TrackerNotification;
