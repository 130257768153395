//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA8 = forwardRef(
  (
    {
      setPageIndex,
      adminData,
      profileData,
      pizza,
      pasta,
      maggie,
      biscuits,
      chaat,
      ghee,
      butter,
      fruits,
      fishChickenMeat,
      bread,
      popcornChips,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [flag, setFlag] = useState(false);
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [
        pizza,
        pasta,
        maggie,
        biscuits,
        chaat,
        ghee,
        butter,
        fruits,
        fishChickenMeat,
        bread,
        popcornChips,
      ];
      checklist.map((val) => {
        if (val.current?.value?.length < 1) {
          val.current.focus();
          return alert(`Please select any one option for ${val}`);
        }
      });
      !error && setPageIndex(9);
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      pizza.current.value = "N/A";
      pasta.current.value = "N/A";
      maggie.current.value = "N/A";
      biscuits.current.value = "N/A";
      chaat.current.value = "N/A";
      ghee.current.value = "N/A";
      butter.current.value = "N/A";
      fruits.current.value = "N/A";
      fishChickenMeat.current.value = "N/A";
      bread.current.value = "N/A";
      popcornChips.current.value = "N/A";
      setFlag(!flag);
      let cache = JSON.parse(localStorage.getItem("CA8"));
      let data = adminData ? adminData : profileData;
      if (adminData) {
        pizza.current.value = data?.Pizza;
        pasta.current.value = data?.Pasta;
        maggie.current.value = data?.Maggie;
        biscuits.current.value = data?.Biscuits;
        chaat.current.value = data?.Chaat;
        ghee.current.value = data?.Ghee;
        butter.current.value = data?.Butter;
        fruits.current.value = data?.Fruits;
        fishChickenMeat.current.value = data?.FishChickenMeat;
        bread.current.value = data?.Bread;
        popcornChips.current.value = data?.PopcornChips;
        setFlag(!flag);
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        pizza.current.value = data?.Pizza;
        pasta.current.value = data?.Pasta;
        maggie.current.value = data?.Maggie;
        biscuits.current.value = data?.Biscuits;
        chaat.current.value = data?.Chaat;
        ghee.current.value = data?.Ghee;
        butter.current.value = data?.Butter;
        fruits.current.value = data?.Fruits;
        fishChickenMeat.current.value = data?.FishChickenMeat;
        bread.current.value = data?.Bread;
        popcornChips.current.value = data?.PopcornChips;
        setFlag(!flag);
      }
    }, [adminData, profileData]);
   
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      pizza.current.value = cache?.Pizza;
      pasta.current.value = cache?.Pasta;
      maggie.current.value = cache?.Maggie;
      biscuits.current.value = cache?.Biscuits;
      chaat.current.value = cache?.Chaat;
      ghee.current.value = cache?.Ghee;
      butter.current.value = cache?.Butter;
      fruits.current.value = cache?.Fruits;
      fishChickenMeat.current.value = cache?.FishChickenMeat;
      bread.current.value = cache?.Bread;
      popcornChips.current.value = cache?.PopcornChips;
      setFlag(!flag);
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.dietaryRecallFormTwo}>
        {setFlag ? <></> : <></>}
        <label className={style.additionalInformationOneLabel}>Dietary Recall</label>
        <div className={style.dailyMonthlyConsumtionRadioInput}>
          <label>
            What is your daily / Monthly consumption of these foods? <span>*</span>
          </label>
          <div className={style.pizzaRadioDR} ref={pizza}>
            <label className={style.pizzaRadioDRLabel}>Pizza</label>
            <div className={style.pizzaRadioDiv}>
              <div
                className={style.pizzaDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  pizza.current.value = "Daily";
                }}
              >
                <input
                  id="pizzaDailyDR"
                  type="radio"
                  name="pizzaRadioDiv"
                  onChange={() => {}}
                  checked={pizza?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="pizzaDailyDR">Daily</label>
              </div>
              <div
                className={style.pizzaOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  pizza.current.value = "Once a week";
                }}
              >
                <input
                  id="pizzaOneWeekDR"
                  type="radio"
                  name="pizzaRadioDiv"
                  onChange={() => {}}
                  checked={pizza?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="pizzaOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.pizzaTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  pizza.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="pizzaRadioDiv"
                  id="pizzaTwoWeekDR"
                  onChange={() => {}}
                  checked={pizza?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="pizzaTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.pizzaMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  pizza.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="pizzaRadioDiv"
                  id="pizzaMonthDR"
                  onChange={() => {}}
                  checked={pizza?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="pizzaMonthDR">Once a month</label>
              </div>
              <div
                className={style.pizzaRareDR}
                onClick={() => {
                  setFlag(!flag);
                  pizza.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="pizzaRadioDiv"
                  id="pizzaRareDR"
                  onChange={() => {}}
                  checked={pizza?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="pizzaRareDR">Rarely</label>
              </div>
              <div
                className={style.pizzaNADR}
                onClick={() => {
                  setFlag(!flag);
                  pizza.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="pizzaRadioDiv"
                  id="pizzaNADR"
                  onChange={() => {}}
                  checked={pizza?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="pizzaNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.pastaRadioDR} ref={pasta}>
            <label className={style.pastaRadioDRLabel}>Pasta</label>
            <div className={style.pastaRadioDiv}>
              <div
                className={style.pastaDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  pasta.current.value = "Daily";
                }}
              >
                <input
                  id="pastaDailyDR"
                  type="radio"
                  name="pastaRadioDiv"
                  onChange={() => {}}
                  checked={pasta?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="pastaDailyDR">Daily</label>
              </div>
              <div
                className={style.pastaOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  pasta.current.value = "Once a week";
                }}
              >
                <input
                  id="pastaOneWeekDR"
                  type="radio"
                  name="pastaRadioDiv"
                  onChange={() => {}}
                  checked={pasta?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="pastaOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.pastaTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  pasta.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="pastaRadioDiv"
                  id="pastaTwoWeekDR"
                  onChange={() => {}}
                  checked={pasta?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="pastaTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.pastaMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  pasta.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="pastaRadioDiv"
                  id="pastaMonthDR"
                  onChange={() => {}}
                  checked={pasta?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="pastaMonthDR">Once a month</label>
              </div>
              <div
                className={style.pastaRareDR}
                onClick={() => {
                  setFlag(!flag);
                  pasta.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="pastaRadioDiv"
                  id="pastaRareDR"
                  onChange={() => {}}
                  checked={pasta?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="pastaRareDR">Rarely</label>
              </div>
              <div
                className={style.pastaNADR}
                onClick={() => {
                  setFlag(!flag);
                  pasta.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="pastaRadioDiv"
                  id="pastaNADR"
                  onChange={() => {}}
                  checked={pasta?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="pastaNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.maggieRadioDR} ref={maggie}>
            <label className={style.maggieRadioDRLabel}>Maggie</label>
            <div className={style.maggieRadioDiv}>
              <div
                className={style.maggieDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  maggie.current.value = "Daily";
                }}
              >
                <input
                  id="maggieDailyDR"
                  type="radio"
                  name="maggieRadioDiv"
                  onChange={() => {}}
                  checked={maggie?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="maggieDailyDR">Daily</label>
              </div>
              <div
                className={style.maggieOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  maggie.current.value = "Once a week";
                }}
              >
                <input
                  id="maggieOneWeekDR"
                  type="radio"
                  name="maggieRadioDiv"
                  onChange={() => {}}
                  checked={maggie?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="maggieOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.maggieTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  maggie.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="maggieRadioDiv"
                  id="maggieTwoWeekDR"
                  onChange={() => {}}
                  checked={maggie?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="maggieTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.maggieMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  maggie.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="maggieRadioDiv"
                  id="maggieMonthDR"
                  onChange={() => {}}
                  checked={maggie?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="maggieMonthDR">Once a month</label>
              </div>
              <div
                className={style.maggieRareDR}
                onClick={() => {
                  setFlag(!flag);
                  maggie.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="maggieRadioDiv"
                  id="maggieRareDR"
                  onChange={() => {}}
                  checked={maggie?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="maggieRareDR">Rarely</label>
              </div>
              <div
                className={style.maggieNADR}
                onClick={() => {
                  setFlag(!flag);
                  maggie.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="maggieRadioDiv"
                  id="maggieNADR"
                  onChange={() => {}}
                  checked={maggie?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="maggieNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.biscuitsRadioDR} ref={biscuits}>
            <label className={style.biscuitsRadioDRLabel}>Biscuits</label>
            <div className={style.biscuitsRadioDiv}>
              <div
                className={style.biscuitsDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  biscuits.current.value = "Daily";
                }}
              >
                <input
                  id="biscuitsDailyDR"
                  type="radio"
                  name="biscuitsRadioDiv"
                  onChange={() => {}}
                  checked={biscuits?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="biscuitsDailyDR">Daily</label>
              </div>
              <div
                className={style.biscuitsOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  biscuits.current.value = "Once a week";
                }}
              >
                <input
                  id="biscuitsOneWeekDR"
                  type="radio"
                  name="biscuitsRadioDiv"
                  onChange={() => {}}
                  checked={biscuits?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="biscuitsOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.biscuitsTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  biscuits.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="biscuitsRadioDiv"
                  id="biscuitsTwoWeekDR"
                  onChange={() => {}}
                  checked={biscuits?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="biscuitsTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.biscuitsMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  biscuits.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="biscuitsRadioDiv"
                  id="biscuitsMonthDR"
                  onChange={() => {}}
                  checked={biscuits?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="biscuitsMonthDR">Once a month</label>
              </div>
              <div
                className={style.biscuitsRareDR}
                onClick={() => {
                  setFlag(!flag);
                  biscuits.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="biscuitsRadioDiv"
                  id="biscuitsRareDR"
                  onChange={() => {}}
                  checked={biscuits?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="biscuitsRareDR">Rarely</label>
              </div>
              <div
                className={style.biscuitsNADR}
                onClick={() => {
                  setFlag(!flag);
                  biscuits.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="biscuitsRadioDiv"
                  id="biscuitsNADR"
                  onChange={() => {}}
                  checked={biscuits?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="biscuitsNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.chaatRadioDR} ref={chaat}>
            <label className={style.chaatRadioDRLabel}>Chaat</label>
            <div className={style.chaatRadioDiv}>
              <div
                className={style.chaatDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  chaat.current.value = "Daily";
                }}
              >
                <input
                  id="chaatDailyDR"
                  type="radio"
                  name="chaatRadioDiv"
                  onChange={() => {}}
                  checked={chaat?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="chaatDailyDR">Daily</label>
              </div>
              <div
                className={style.chaatOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  chaat.current.value = "Once a week";
                }}
              >
                <input
                  id="chaatOneWeekDR"
                  type="radio"
                  name="chaatRadioDiv"
                  onChange={() => {}}
                  checked={chaat?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="chaatOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.chaatTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  chaat.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="chaatRadioDiv"
                  id="chaatTwoWeekDR"
                  onChange={() => {}}
                  checked={chaat?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="chaatTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.chaatMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  chaat.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="chaatRadioDiv"
                  id="chaatMonthDR"
                  onChange={() => {}}
                  checked={chaat?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="chaatMonthDR">Once a month</label>
              </div>
              <div
                className={style.chaatRareDR}
                onClick={() => {
                  setFlag(!flag);
                  chaat.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="chaatRadioDiv"
                  id="chaatRareDR"
                  onChange={() => {}}
                  checked={chaat?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="chaatRareDR">Rarely</label>
              </div>
              <div
                className={style.chaatNADR}
                onClick={() => {
                  setFlag(!flag);
                  chaat.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="chaatRadioDiv"
                  id="chaatNADR"
                  onChange={() => {}}
                  checked={chaat?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="chaatNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.gheeRadioDR} ref={ghee}>
            <label className={style.gheeRadioDRLabel}>ghee</label>
            <div className={style.gheeRadioDiv}>
              <div
                className={style.gheeDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  ghee.current.value = "Daily";
                }}
              >
                <input
                  id="gheeDailyDR"
                  type="radio"
                  name="gheeRadioDiv"
                  onChange={() => {}}
                  checked={ghee?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="gheeDailyDR">Daily</label>
              </div>
              <div
                className={style.gheeOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  ghee.current.value = "Once a week";
                }}
              >
                <input
                  id="gheeOneWeekDR"
                  type="radio"
                  name="gheeRadioDiv"
                  onChange={() => {}}
                  checked={ghee?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="gheeOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.gheeTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  ghee.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="gheeRadioDiv"
                  id="gheeTwoWeekDR"
                  onChange={() => {}}
                  checked={ghee?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="gheeTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.gheeMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  ghee.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="gheeRadioDiv"
                  id="gheeMonthDR"
                  onChange={() => {}}
                  checked={ghee?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="gheeMonthDR">Once a month</label>
              </div>
              <div
                className={style.gheeRareDR}
                onClick={() => {
                  setFlag(!flag);
                  ghee.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="gheeRadioDiv"
                  id="gheeRareDR"
                  onChange={() => {}}
                  checked={ghee?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="gheeRareDR">Rarely</label>
              </div>
              <div
                className={style.gheeNADR}
                onClick={() => {
                  setFlag(!flag);
                  ghee.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="gheeRadioDiv"
                  id="gheeNADR"
                  onChange={() => {}}
                  checked={ghee?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="gheeNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.butterRadioDR} ref={butter}>
            <label className={style.butterRadioDRLabel}>butter</label>
            <div className={style.butterRadioDiv}>
              <div
                className={style.butterDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  butter.current.value = "Daily";
                }}
              >
                <input
                  id="butterDailyDR"
                  type="radio"
                  name="butterRadioDiv"
                  onChange={() => {}}
                  checked={butter?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="butterDailyDR">Daily</label>
              </div>
              <div
                className={style.butterOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  butter.current.value = "Once a week";
                }}
              >
                <input
                  id="butterOneWeekDR"
                  type="radio"
                  name="butterRadioDiv"
                  onChange={() => {}}
                  checked={butter?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="butterOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.butterTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  butter.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="butterRadioDiv"
                  id="butterTwoWeekDR"
                  onChange={() => {}}
                  checked={butter?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="butterTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.butterMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  butter.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="butterRadioDiv"
                  id="butterMonthDR"
                  onChange={() => {}}
                  checked={butter?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="butterMonthDR">Once a month</label>
              </div>
              <div
                className={style.butterRareDR}
                onClick={() => {
                  setFlag(!flag);
                  butter.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="butterRadioDiv"
                  id="butterRareDR"
                  onChange={() => {}}
                  checked={butter?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="butterRareDR">Rarely</label>
              </div>
              <div
                className={style.butterNADR}
                onClick={() => {
                  setFlag(!flag);
                  butter.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="butterRadioDiv"
                  id="butterNADR"
                  onChange={() => {}}
                  checked={butter?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="butterNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.fruitsRadioDR} ref={fruits}>
            <label className={style.fruitsRadioDRLabel}>fruits</label>
            <div className={style.fruitsRadioDiv}>
              <div
                className={style.fruitsDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  fruits.current.value = "Daily";
                }}
              >
                <input
                  id="fruitsDailyDR"
                  type="radio"
                  name="fruitsRadioDiv"
                  onChange={() => {}}
                  checked={fruits?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="fruitsDailyDR">Daily</label>
              </div>
              <div
                className={style.fruitsOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  fruits.current.value = "Once a week";
                }}
              >
                <input
                  id="fruitsOneWeekDR"
                  type="radio"
                  name="fruitsRadioDiv"
                  onChange={() => {}}
                  checked={fruits?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="fruitsOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.fruitsTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  fruits.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="fruitsRadioDiv"
                  id="fruitsTwoWeekDR"
                  onChange={() => {}}
                  checked={fruits?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="fruitsTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.fruitsMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  fruits.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="fruitsRadioDiv"
                  id="fruitsMonthDR"
                  onChange={() => {}}
                  checked={fruits?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="fruitsMonthDR">Once a month</label>
              </div>
              <div
                className={style.fruitsRareDR}
                onClick={() => {
                  setFlag(!flag);
                  fruits.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="fruitsRadioDiv"
                  id="fruitsRareDR"
                  onChange={() => {}}
                  checked={fruits?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="fruitsRareDR">Rarely</label>
              </div>
              <div
                className={style.fruitsNADR}
                onClick={() => {
                  setFlag(!flag);
                  fruits.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="fruitsRadioDiv"
                  id="fruitsNADR"
                  onChange={() => {}}
                  checked={fruits?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="fruitsNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.fcmRadioDR} ref={fishChickenMeat}>
            <label className={style.fcmRadioDRLabel}>Fish/Chicken/Meat</label>
            <div className={style.fcmRadioDiv}>
              <div
                className={style.fcmDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  fishChickenMeat.current.value = "Daily";
                }}
              >
                <input
                  id="fishChickenMeatDailyDR"
                  type="radio"
                  name="fishChickenMeatRadioDiv"
                  onChange={() => {}}
                  checked={fishChickenMeat?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="fishChickenMeatDailyDR">Daily</label>
              </div>
              <div
                className={style.fcmOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  fishChickenMeat.current.value = "Once a week";
                }}
              >
                <input
                  id="fishChickenMeatOneWeekDR"
                  type="radio"
                  name="fishChickenMeatRadioDiv"
                  onChange={() => {}}
                  checked={fishChickenMeat?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="fishChickenMeatOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.fcmTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  fishChickenMeat.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="fishChickenMeatRadioDiv"
                  id="fishChickenMeatTwoWeekDR"
                  onChange={() => {}}
                  checked={fishChickenMeat?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="fishChickenMeatTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.fcmMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  fishChickenMeat.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="fishChickenMeatRadioDiv"
                  id="fishChickenMeatMonthDR"
                  onChange={() => {}}
                  checked={fishChickenMeat?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="fishChickenMeatMonthDR">Once a month</label>
              </div>
              <div
                className={style.fcmRareDR}
                onClick={() => {
                  setFlag(!flag);
                  fishChickenMeat.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="fishChickenMeatRadioDiv"
                  id="fishChickenMeatRareDR"
                  onChange={() => {}}
                  checked={fishChickenMeat?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="fishChickenMeatRareDR">Rarely</label>
              </div>
              <div
                className={style.fcmNADR}
                onClick={() => {
                  setFlag(!flag);
                  fishChickenMeat.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="fishChickenMeatRadioDiv"
                  id="fishChickenMeatNADR"
                  onChange={() => {}}
                  checked={fishChickenMeat?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="fishChickenMeatNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.breadRadioDR} ref={bread}>
            <label className={style.breadRadioDRLabel}>bread</label>
            <div className={style.breadRadioDiv}>
              <div
                className={style.breadDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  bread.current.value = "Daily";
                }}
              >
                <input
                  id="breadDailyDR"
                  type="radio"
                  name="breadRadioDiv"
                  onChange={() => {}}
                  checked={bread?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="breadDailyDR">Daily</label>
              </div>
              <div
                className={style.breadOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  bread.current.value = "Once a week";
                }}
              >
                <input
                  id="breadOneWeekDR"
                  type="radio"
                  name="breadRadioDiv"
                  onChange={() => {}}
                  checked={bread?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="breadOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.breadTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  bread.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="breadRadioDiv"
                  id="breadTwoWeekDR"
                  onChange={() => {}}
                  checked={bread?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="breadTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.breadMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  bread.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="breadRadioDiv"
                  id="breadMonthDR"
                  onChange={() => {}}
                  checked={bread?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="breadMonthDR">Once a month</label>
              </div>
              <div
                className={style.breadRareDR}
                onClick={() => {
                  setFlag(!flag);
                  bread.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="breadRadioDiv"
                  id="breadRareDR"
                  onChange={() => {}}
                  checked={bread?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="breadRareDR">Rarely</label>
              </div>
              <div
                className={style.breadNADR}
                onClick={() => {
                  setFlag(!flag);
                  bread.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="breadRadioDiv"
                  id="breadNADR"
                  onChange={() => {}}
                  checked={bread?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="breadNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.popchiRadioDR} ref={popcornChips}>
            <label className={style.popchiRadioDRLabel}>popcornChips</label>
            <div className={style.popchiRadioDiv}>
              <div
                className={style.popchiDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  popcornChips.current.value = "Daily";
                }}
              >
                <input
                  id="popcornChipsDailyDR"
                  type="radio"
                  name="popcornChipsRadioDiv"
                  onChange={() => {}}
                  checked={popcornChips?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="popcornChipsDailyDR">Daily</label>
              </div>
              <div
                className={style.popchiOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  popcornChips.current.value = "Once a week";
                }}
              >
                <input
                  id="popcornChipsOneWeekDR"
                  type="radio"
                  name="popcornChipsRadioDiv"
                  onChange={() => {}}
                  checked={popcornChips?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="popcornChipsOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.popchiTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  popcornChips.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="popcornChipsRadioDiv"
                  id="popcornChipsTwoWeekDR"
                  onChange={() => {}}
                  checked={popcornChips?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="popcornChipsTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.popchiMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  popcornChips.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="popcornChipsRadioDiv"
                  id="popcornChipsMonthDR"
                  onChange={() => {}}
                  checked={popcornChips?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="popcornChipsMonthDR">Once a month</label>
              </div>
              <div
                className={style.popchiRareDR}
                onClick={() => {
                  setFlag(!flag);
                  popcornChips.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="popcornChipsRadioDiv"
                  id="popcornChipsRareDR"
                  onChange={() => {}}
                  checked={popcornChips?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="popcornChipsRareDR">Rarely</label>
              </div>
              <div
                className={style.popchiNADR}
                onClick={() => {
                  setFlag(!flag);
                  popcornChips.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="popcornChipsRadioDiv"
                  id="popcornChipsNADR"
                  onChange={() => {}}
                  checked={popcornChips?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="popcornChipsNADR">N/A</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CA8;
