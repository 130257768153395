//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import style from "../../assets/styles/ClientDashboard.module.css";
import infoIcon from "../../assets/images/info.png";
import closeIcon from "../../assets/images/close.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PhotoModal from "../../components/PhotoModal";
import MealModal from "../../components/MealModal";
import ProgressTracker from "../../components/ProgressTracker";
import UpdateInfoModal from "../../components/UpdateInfoModal";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ClientDashboard() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [showWeightModal, setShowWeightModal] = useState(false);
  const navigation = useNavigate();
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showMealModal, setShowMealModal] = useState(false);
  const [showInchModal, setShowInchModal] = useState(false);
  const [showUpdateInfoModal, setShowUpdateInfoModal] = useState(false);
  let temp = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to Dispatch Routes-----------------------------------------------------------------------//

  useEffect(() => {
    dispatch({ type: "ROUTES", payload: "My Account" });
    checkClientAssessment();
  }, []);

  //---------------------------------------------------------UseEffect to Dispatch Routes-----------------------------------------------------------------------//
  
  //---------------------------------------------------------Check Client Assessment Function -----------------------------------------------------------------------//

  function checkClientAssessment() {
    let temp = JSON.parse(localStorage.getItem("user"));
    temp?.id &&
      Request.post(`${BASE_URL}client/getClientAssessment`, {
        Client_id: temp?.id,
      })
        .then((res) => {
          if (res?.data?.status === 200) {
            if (res?.data?.data?.length < 1) {
              alert("Please fill your Assessment Form first");
              navigation("/clientassessment");
            }
          } else {
            alert("Error checking for assessment form");
            console.log("Error checking for assessment", res?.data?.error);
          }
        })
        .catch((err) => {
          console.log("Error checking for assessment form", err);
        });
  }

  //---------------------------------------------------------Check Client Assessment Function -----------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to Check Plan Status-----------------------------------------------------------------------//

  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem("user"));
    if (temp?.PlanStatus) {
      if (temp && ((temp?.PlanStatus != 1 && temp?.PlanStatus != 4) || temp?.PlanExpired != 0)) {
        navigation("/plans");
        alert("Please purchase a plan first");
      }
    } else {
      localStorage.clear();
      // routeName.includes("changepassword") && navigation("");
    }
  }, []);

  //---------------------------------------------------------UseEffect to Check Plan Status-----------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to show sidebar -----------------------------------------------------------------------//

  useEffect(() => {
    if (
      !showInchModal &&
      !showPhotoModal &&
      !showMealModal &&
      !showWeightModal &&
      !showUpdateInfoModal
    ) {
      document.getElementById("sidebarMainDiv").style.zIndex = 5;
    } else {
      document.getElementById("sidebarMainDiv").style.zIndex = 1;
    }
  }, [showInchModal, showPhotoModal, showMealModal, showWeightModal, showUpdateInfoModal]);

  //---------------------------------------------------------UseEffect to show sidebar -----------------------------------------------------------------------//

  return (
    <div className={style.dashboardContainer}>
      {temp?.PlanStartDate === null &&
        moment(temp?.Logindate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") && (
          <div className={style.outerReadDocPopup} id="outerReadDocPopup">
            <div className={style.innerReadDocPopup}>
              <img src={infoIcon} alt="" />
              <label>Please carefully read all the documents.</label>
              <img
                src={closeIcon}
                alt=""
                onClick={() => {
                  document.getElementById("outerReadDocPopup").style.display = "none";
                }}
              />
            </div>
          </div>
        )}
      {showPhotoModal && <PhotoModal setShowPhotoModal={setShowPhotoModal} />}
      {showMealModal && <MealModal setShowMealModal={setShowMealModal} />}
      {showUpdateInfoModal && <UpdateInfoModal setShowUpdateInfoModal={setShowUpdateInfoModal} />}
      <div className={style.firstMainContainer}>
        <div className={style.firstSection}>
          <div className={style.titleButtonMain}>
            <label className={style.firstLabel}>Progress Tracker</label>
            <button
              className={style.updateButton}
              onClick={() => {
                setShowUpdateInfoModal(true);
              }}
            >
              Update Physical Information
            </button>
          </div>
          <div className={style.titleButtonMainMobile}>
            <label className={style.firstLabel}>Progress Tracker</label>
            <button
              className={style.updateButton}
              onClick={() => {
                setShowUpdateInfoModal(true);
              }}
            >
              Update Physical Information
            </button>
          </div>
          <ProgressTracker
            showWeightModal={showWeightModal}
            setShowPhotoModal={setShowPhotoModal}
            setShowMealModal={setShowMealModal}
            setShowWeightModal={setShowWeightModal}
            setShowInchModal={setShowInchModal}
          />
        </div>
        {/* <div className={style.secondSection}>
          <div className={style.headerLabel}>
            <label className={style.headingLabel}>Guide to Follow</label>
            <label className={style.innerLabel}>
              Healthy Lifestyle Guide
              <span className={style.readDocument}>
                <a
                  className={style.customHover}
                  onClick={() => {
                    window.open(`https://server.healvick.com/guide/Healthy-Lifestyle-Guide.pdf`);
                  }}>
                  Read Document
                </a>
              </span>
            </label>
            <label className={style.innerLabel}>
              Important pointers
              <span className={style.readDocument}>
                <a
                  className={style.customHover}
                  onClick={() => {
                    window.open(`https://server.healvick.com/guide/Important-Pointers.pdf`);
                  }}>
                  Read Document
                </a>
              </span>
            </label>
            <label className={style.innerLabel}>
              How to measure inch guide
              <span className={style.readDocument}>
                <a
                  className={style.customHover}
                  onClick={() => {
                    window.open(`https://server.healvick.com/guide/Inch-Guide.pdf`);
                  }}>
                  Read Document
                </a>
              </span>
            </label>
          </div>
        </div> */}
      </div>
      {/* <RecipeCard /> */}
      <div className={style.secondMainContainer}>
        <div className={style.belowSection}>
          <label>Guide to Follow</label>
        </div>
        <div>
          <div className={style.mainRecipeContainer}>
            <div className={style.keyDescription}>
              <img className={style.imageMobile} alt=""></img>
              <label>Healthy Lifestyle Guide</label>
            </div>
            <div className={style.viewRecipe}>
              <span className={style.readDocument}>
                <a
                  className={style.customHover}
                  onClick={() => {
                    window.open(`https://server.healvick.com/guide/Healthy-Lifestyle-Guide.pdf`);
                  }}
                >
                  Read Document
                </a>
              </span>
            </div>
          </div>
          <div className={style.mainRecipeContainer}>
            <div className={style.keyDescription}>
              <img className={style.imageMobile} alt=""></img>
              <label>Important pointers</label>
            </div>
            <div className={style.viewRecipe}>
              <span className={style.readDocument}>
                <a
                  className={style.customHover}
                  onClick={() => {
                    window.open(`https://server.healvick.com/guide/Important-Pointers.pdf`);
                  }}
                >
                  Read Document
                </a>
              </span>
            </div>
          </div>
          <div className={style.mainRecipeContainer}>
            <div className={style.keyDescription}>
              <img className={style.imageMobile} alt=""></img>
              <label>How to measure inch guide</label>
            </div>
            <div className={style.viewRecipe}>
              <span className={style.readDocument}>
                <a
                  className={style.customHover}
                  onClick={() => {
                    window.open(`https://server.healvick.com/guide/Inch-Guide.pdf`);
                  }}
                >
                  Read Document
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // <div>Hii {userDetails?.Firstname ? userDetails?.Firstname : "Cheater"}</div>;
}

export default ClientDashboard;
