//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useRef, useState, useEffect } from "react";
import style from "../assets/styles/PaymentModal.module.css";
import paymentimg from "../assets/images/Payment.png";
import cloudIcon from "../assets/images/cloudIcon.png";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function PaymentModal({ purchasePlan, setShowModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [img1, setImg1] = useState();
  const upload1 = useRef();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Get Image Data Function-----------------------------------------------------------------------//

  function getImgData(id, img) {
    const imgPreview = document.getElementById(id);
    const files = img;
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        imgPreview.innerHTML =
          '<img src="' + this.result + `" className=${style.abc} style="width: 100%">`;
        imgPreview.style.display = "flex";
        imgPreview.style.width = "100%";
      });
    }
  }

  //---------------------------------------------------------Get Image Data Function-----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to Show Image-----------------------------------------------------------------------//

  useEffect(() => {
    img1 && getImgData("img-preview", img1);
  }, [img1]);

  //---------------------------------------------------------useEffect to Show Image-----------------------------------------------------------------------//

  return (
    <div className={style.greyclr} id="paymentmodal" onClick={() => {}}>
      <div className={style.modalContent}>
        <div className={style.titleflex}>Purchase Plan</div>
        <div className={style.newflexbank}>
          <p className={style.thesixteen}>BANK DETAILS :</p>
          <p>Name - HEALVICK</p>
          <p>Union Bank</p>
          <p>Borivali (West), Mumbai- 400092</p>
          <p>
            <span className={style.boldnew}>Account number-</span> 510101007004919
          </p>
          <p>
            <span className={style.boldnew}>IFSC code-</span> UBIN0914673
          </p>
        </div>
        <div className={style.mainflex}>
          <div className={style.childone}>
            <img src={paymentimg} alt="" className={style.paymentSize}></img>
          </div>
          <div className={style.childtwo}>
            <div className={style.uploadtitle}>Upload Receipt</div>
            <div
              className={style.uploadbordermaindiv}
              onClick={() => {
                upload1.current.click();
              }}
            >
              <div className={style.uploadborder}>
                <div id="img-preview" className={style.imgPreview}></div>
                {!img1 && (
                  <>
                    <img src={cloudIcon} alt="" className={style.imgsize}></img>
                    <label className={style.thejpgname}>.jpg, .png</label>
                    <labe className={style.theuploadname}>Upload Payment Receipt</labe>
                  </>
                )}
                <input
                  type="file"
                  id="file"
                  ref={upload1}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var file = event.target.files[0];
                    setImg1(() => file);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.theflex}>
          <button
            className={style.paid}
            onClick={() => {
              if (img1) {
                purchasePlan(img1);
                setShowModal(false);
              } else {
                alert("Please upload a receipt");
              }
            }}
          >
            {" "}
            Submit{" "}
          </button>
          <button
            className={style.cancelbtn}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {" "}
            Cancel{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
