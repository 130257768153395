//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA9 = forwardRef(
  (
    {
      adminData,
      profileData,
      clientassessment,
      updateClientAssessment,
      chocolate,
      egg,
      iceCream,
      lateNightBinging,
      samosaVadapav,
      cheese,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [flag, setFlag] = useState(false);
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [chocolate, egg, iceCream, lateNightBinging, samosaVadapav, cheese];
      checklist.map((val) => {
        if (val.current?.value?.length < 1) {
          val.current.focus();
          return alert(`Please select any one option for ${val}`);
        }
      });
      !error && profileData && updateClientAssessment();
      !error && !profileData && clientassessment();
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      chocolate.current.value = "N/A";
      egg.current.value = "N/A";
      iceCream.current.value = "N/A";
      lateNightBinging.current.value = "N/A";
      samosaVadapav.current.value = "N/A";
      cheese.current.value = "N/A";

      setFlag(!flag);
      let cache = JSON.parse(localStorage.getItem("CA9"));
      let data = adminData ? adminData : profileData;
      if (adminData) {
        chocolate.current.value = data?.Chocolate;
        egg.current.value = data?.Egg;
        iceCream.current.value = data?.Icecream;
        lateNightBinging.current.value = data?.LateNightBinging;
        samosaVadapav.current.value = data?.SamosaVadapav;
        cheese.current.value = data?.Cheese;

        setFlag(!flag);
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        chocolate.current.value = data?.Chocolate;
        egg.current.value = data?.Egg;
        iceCream.current.value = data?.Icecream;
        lateNightBinging.current.value = data?.LateNightBinging;
        samosaVadapav.current.value = data?.SamosaVadapav;
        cheese.current.value = data?.Cheese;

        setFlag(!flag);
      }
    }, [adminData, profileData]);
   
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      chocolate.current.value = cache?.Chocolate;
      egg.current.value = cache?.Egg;
      iceCream.current.value = cache?.Icecream;
      lateNightBinging.current.value = cache?.LateNightBinging;
      samosaVadapav.current.value = cache?.SamosaVadapav;
      cheese.current.value = cache?.Cheese;

      setFlag(!flag);
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.dietaryRecallFormTwo}>
        <label className={style.additionalInformationOneLabel}>Dietary Recall</label>
        <div className={style.dailyMonthlyConsumtionRadioInput}>
          <label>
            What is your daily / Monthly consumption of these foods? <span>*</span>
          </label>
          <div className={style.chocolateRadioDR} ref={chocolate}>
            <label className={style.chocolateRadioDRLabel}>Chocolate</label>
            <div className={style.chocolateRadioDiv}>
              <div
                className={style.chocolateDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  chocolate.current.value = "Daily";
                }}
              >
                <input
                  id="chocolateDailyDR"
                  type="radio"
                  name="chocolateRadioDiv"
                  onChange={() => {}}
                  checked={chocolate?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="chocolateDailyDR">Daily</label>
              </div>
              <div
                className={style.chocolateOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  chocolate.current.value = "Once a week";
                }}
              >
                <input
                  id="chocolateOneWeekDR"
                  type="radio"
                  name="chocolateRadioDiv"
                  onChange={() => {}}
                  checked={chocolate?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="chocolateOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.chocolateTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  chocolate.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="chocolateRadioDiv"
                  id="chocolateTwoWeekDR"
                  onChange={() => {}}
                  checked={chocolate?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="chocolateTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.chocolateMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  chocolate.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="chocolateRadioDiv"
                  id="chocolateMonthDR"
                  onChange={() => {}}
                  checked={chocolate?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="chocolateMonthDR">Once a month</label>
              </div>
              <div
                className={style.chocolateRareDR}
                onClick={() => {
                  setFlag(!flag);
                  chocolate.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="chocolateRadioDiv"
                  id="chocolateRareDR"
                  onChange={() => {}}
                  checked={chocolate?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="chocolateRareDR">Rarely</label>
              </div>
              <div
                className={style.chocolateNADR}
                onClick={() => {
                  setFlag(!flag);
                  chocolate.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="chocolateRadioDiv"
                  id="chocolateNADR"
                  onChange={() => {}}
                  checked={chocolate?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="chocolateNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.eggRadioDR} ref={egg}>
            <label className={style.eggRadioDRLabel}>egg</label>
            <div className={style.eggRadioDiv}>
              <div
                className={style.eggDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  egg.current.value = "Daily";
                }}
              >
                <input
                  id="eggDailyDR"
                  type="radio"
                  name="eggRadioDiv"
                  onChange={() => {}}
                  checked={egg?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="eggDailyDR">Daily</label>
              </div>
              <div
                className={style.eggOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  egg.current.value = "Once a week";
                }}
              >
                <input
                  id="eggOneWeekDR"
                  type="radio"
                  name="eggRadioDiv"
                  onChange={() => {}}
                  checked={egg?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="eggOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.eggTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  egg.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="eggRadioDiv"
                  id="eggTwoWeekDR"
                  onChange={() => {}}
                  checked={egg?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="eggTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.eggMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  egg.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="eggRadioDiv"
                  id="eggMonthDR"
                  onChange={() => {}}
                  checked={egg?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="eggMonthDR">Once a month</label>
              </div>
              <div
                className={style.eggRareDR}
                onClick={() => {
                  setFlag(!flag);
                  egg.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="eggRadioDiv"
                  id="eggRareDR"
                  onChange={() => {}}
                  checked={egg?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="eggRareDR">Rarely</label>
              </div>
              <div
                className={style.eggNADR}
                onClick={() => {
                  setFlag(!flag);
                  egg.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="eggRadioDiv"
                  id="eggNADR"
                  onChange={() => {}}
                  checked={egg?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="eggNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.iceCreamRadioDR} ref={iceCream}>
            <label className={style.iceCreamRadioDRLabel}>iceCream</label>
            <div className={style.iceCreamRadioDiv}>
              <div
                className={style.iceCreamDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  iceCream.current.value = "Daily";
                }}
              >
                <input
                  id="iceCreamDailyDR"
                  type="radio"
                  name="iceCreamRadioDiv"
                  onChange={() => {}}
                  checked={iceCream?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="iceCreamDailyDR">Daily</label>
              </div>
              <div
                className={style.iceCreamOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  iceCream.current.value = "Once a week";
                }}
              >
                <input
                  id="iceCreamOneWeekDR"
                  type="radio"
                  name="iceCreamRadioDiv"
                  onChange={() => {}}
                  checked={iceCream?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="iceCreamOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.iceCreamTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  iceCream.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="iceCreamRadioDiv"
                  id="iceCreamTwoWeekDR"
                  onChange={() => {}}
                  checked={iceCream?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="iceCreamTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.iceCreamMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  iceCream.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="iceCreamRadioDiv"
                  id="iceCreamMonthDR"
                  onChange={() => {}}
                  checked={iceCream?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="iceCreamMonthDR">Once a month</label>
              </div>
              <div
                className={style.iceCreamRareDR}
                onClick={() => {
                  setFlag(!flag);
                  iceCream.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="iceCreamRadioDiv"
                  id="iceCreamRareDR"
                  onChange={() => {}}
                  checked={iceCream?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="iceCreamRareDR">Rarely</label>
              </div>
              <div
                className={style.iceCreamNADR}
                onClick={() => {
                  setFlag(!flag);
                  iceCream.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="iceCreamRadioDiv"
                  id="iceCreamNADR"
                  onChange={() => {}}
                  checked={iceCream?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="iceCreamNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.lateNightBingingRadioDR} ref={lateNightBinging}>
            <label className={style.lateNightBingingRadioDRLabel}>Late Night Binging</label>
            <div className={style.lateNightBingingRadioDiv}>
              <div
                className={style.lateNightBingingDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  lateNightBinging.current.value = "Daily";
                }}
              >
                <input
                  id="lateNightBingingDailyDR"
                  type="radio"
                  name="lateNightBingingRadioDiv"
                  onChange={() => {}}
                  checked={lateNightBinging?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="lateNightBingingDailyDR">Daily</label>
              </div>
              <div
                className={style.lateNightBingingOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  lateNightBinging.current.value = "Once a week";
                }}
              >
                <input
                  id="lateNightBingingOneWeekDR"
                  type="radio"
                  name="lateNightBingingRadioDiv"
                  onChange={() => {}}
                  checked={lateNightBinging?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="lateNightBingingOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.lateNightBingingTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  lateNightBinging.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="lateNightBingingRadioDiv"
                  id="lateNightBingingTwoWeekDR"
                  onChange={() => {}}
                  checked={lateNightBinging?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="lateNightBingingTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.lateNightBingingMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  lateNightBinging.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="lateNightBingingRadioDiv"
                  id="lateNightBingingMonthDR"
                  onChange={() => {}}
                  checked={lateNightBinging?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="lateNightBingingMonthDR">Once a month</label>
              </div>
              <div
                className={style.lateNightBingingRareDR}
                onClick={() => {
                  setFlag(!flag);
                  lateNightBinging.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="lateNightBingingRadioDiv"
                  id="lateNightBingingRareDR"
                  onChange={() => {}}
                  checked={lateNightBinging?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="lateNightBingingRareDR">Rarely</label>
              </div>
              <div
                className={style.lateNightBingingNADR}
                onClick={() => {
                  setFlag(!flag);
                  lateNightBinging.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="lateNightBingingRadioDiv"
                  id="lateNightBingingNADR"
                  onChange={() => {}}
                  checked={lateNightBinging?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="lateNightBingingNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.samosaVadapavRadioDR} ref={samosaVadapav}>
            <label className={style.samosaVadapavRadioDRLabel}>Samosa / Vada Pav</label>
            <div className={style.samosaVadapavRadioDiv}>
              <div
                className={style.samosaVadapavDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  samosaVadapav.current.value = "Daily";
                }}
              >
                <input
                  id="samosaVadapavDailyDR"
                  type="radio"
                  name="samosaVadapavRadioDiv"
                  onChange={() => {}}
                  checked={samosaVadapav?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="samosaVadapavDailyDR">Daily</label>
              </div>
              <div
                className={style.samosaVadapavOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  samosaVadapav.current.value = "Once a week";
                }}
              >
                <input
                  id="samosaVadapavOneWeekDR"
                  type="radio"
                  name="samosaVadapavRadioDiv"
                  onChange={() => {}}
                  checked={samosaVadapav?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="samosaVadapavOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.samosaVadapavTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  samosaVadapav.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="samosaVadapavRadioDiv"
                  id="samosaVadapavTwoWeekDR"
                  onChange={() => {}}
                  checked={samosaVadapav?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="samosaVadapavTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.samosaVadapavMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  samosaVadapav.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="samosaVadapavRadioDiv"
                  id="samosaVadapavMonthDR"
                  onChange={() => {}}
                  checked={samosaVadapav?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="samosaVadapavMonthDR">Once a month</label>
              </div>
              <div
                className={style.samosaVadapavRareDR}
                onClick={() => {
                  setFlag(!flag);
                  samosaVadapav.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="samosaVadapavRadioDiv"
                  id="samosaVadapavRareDR"
                  onChange={() => {}}
                  checked={samosaVadapav?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="samosaVadapavRareDR">Rarely</label>
              </div>
              <div
                className={style.samosaVadapavNADR}
                onClick={() => {
                  setFlag(!flag);
                  samosaVadapav.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="samosaVadapavRadioDiv"
                  id="samosaVadapavNADR"
                  onChange={() => {}}
                  checked={samosaVadapav?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="samosaVadapavNADR">N/A</label>
              </div>
            </div>
          </div>
          <div className={style.cheeseRadioDR} ref={cheese}>
            <label className={style.cheeseRadioDRLabel}>Cheese</label>
            <div className={style.cheeseRadioDiv}>
              <div
                className={style.cheeseDailyDR}
                onClick={() => {
                  setFlag(!flag);
                  cheese.current.value = "Daily";
                }}
              >
                <input
                  id="cheeseDailyDR"
                  type="radio"
                  name="cheeseRadioDiv"
                  onChange={() => {}}
                  checked={cheese?.current?.value == "Daily" ? true : false}
                />
                <label htmlFor="cheeseDailyDR">Daily</label>
              </div>
              <div
                className={style.cheeseOneWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  cheese.current.value = "Once a week";
                }}
              >
                <input
                  id="cheeseOneWeekDR"
                  type="radio"
                  name="cheeseRadioDiv"
                  onChange={() => {}}
                  checked={cheese?.current?.value == "Once a week" ? true : false}
                />
                <label htmlFor="cheeseOneWeekDR">Once a week</label>
              </div>
              <div
                className={style.cheeseTwoWeekDR}
                onClick={() => {
                  setFlag(!flag);
                  cheese.current.value = "Twice a week";
                }}
              >
                <input
                  type="radio"
                  name="cheeseRadioDiv"
                  id="cheeseTwoWeekDR"
                  onChange={() => {}}
                  checked={cheese?.current?.value == "Twice a week" ? true : false}
                />
                <label htmlFor="cheeseTwoWeekDR">Twice a week</label>
              </div>
              <div
                className={style.cheeseMonthDR}
                onClick={() => {
                  setFlag(!flag);
                  cheese.current.value = "Once a month";
                }}
              >
                <input
                  type="radio"
                  name="cheeseRadioDiv"
                  id="cheeseMonthDR"
                  onChange={() => {}}
                  checked={cheese?.current?.value == "Once a month" ? true : false}
                />
                <label htmlFor="cheeseMonthDR">Once a month</label>
              </div>
              <div
                className={style.cheeseRareDR}
                onClick={() => {
                  setFlag(!flag);
                  cheese.current.value = "Rarely";
                }}
              >
                <input
                  type="radio"
                  name="cheeseRadioDiv"
                  id="cheeseRareDR"
                  onChange={() => {}}
                  checked={cheese?.current?.value == "Rarely" ? true : false}
                />
                <label htmlFor="cheeseRareDR">Rarely</label>
              </div>
              <div
                className={style.cheeseNADR}
                onClick={() => {
                  setFlag(!flag);
                  cheese.current.value = "N/A";
                }}
              >
                <input
                  type="radio"
                  name="cheeseRadioDiv"
                  id="cheeseNADR"
                  onChange={() => {}}
                  checked={cheese?.current?.value == "N/A" ? true : false}
                />
                <label htmlFor="cheeseNADR">N/A</label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={style.secondRowOfDR}>
          <div className={style.describeInputDR}>
            <label>
              We would love to know more about you and your current lifestyle. Please explain in
              brief.<span> *</span>
            </label>
            <textarea
              className=""
              cols="10"
              rows="10"
              placeholder="Describe"
              id="describe"
            ></textarea>
            <span className={style.errorSpan} id="errorMessagesDescribe"></span>
          </div>
        </div> */}
      </div>
    );
  }
);
export default CA9;
