//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from "react";
import style from "../assets/styles/ClientDashboard.module.css";
import meanDownload from "../assets/images/mealDownload.png";
import weightTracker from "../assets/images/weightTracker.png";
import inchTracker from "../assets/images/inchTracker.png";
import photoTracker from "../assets/images/photoTracker.png";
import bloodTracker from "../assets/images/bloodTracker.png";
import periodTracker from "../assets/images/periodTracker.png";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ProgressTracker({
  setShowPhotoModal,
  setShowMealModal,
}) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const navigation = useNavigate();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  return (
    <>
      {" "}
      <div className={style.trackerContainer}>
        <div
          className={style.mealPlanMain}
          onClick={() => {
            setShowMealModal(true);
          }}>
          <img className={style.imageFirst} src={meanDownload} alt=""></img>
          <label className={style.mealPlanLabel}>
            Download <br /> Meal Plan
          </label>
        </div>
        <div
          className={style.weightTrackerMain}
          onClick={() => {
            navigation("/weighttracker");
          }}>
          <img className={style.imageFirst} src={weightTracker} alt=""></img>
          <label className={style.weightTrackerLabel}>
            Weight <br /> Tracker
          </label>
        </div>

        <div
          className={style.inchTrackerMain}
          onClick={() => {
            navigation("/inchtracker");
          }}>
          <img className={style.imageFirst} src={inchTracker} alt=""></img>
          <label className={style.inchTrackerLabel}>
            Inch Loss <br /> Tracker
          </label>
        </div>
        <div
          className={style.photoTrackerMain}
          id="myBtn"
          onClick={() => {
            setShowPhotoModal(true);
          }}>
          <img className={style.imageFirst} src={photoTracker} alt=""></img>
          <label className={style.photoTrackerLabel}>
            Photo <br /> Tracker
          </label>
        </div>
        <div
          className={style.bloodTrackerMain}
          onClick={() => {
            navigation("/bloodtracker");
          }}>
          <img className={style.imageFirst} src={bloodTracker} alt=""></img>
          <label className={style.bloodTrackerLabel}>
            Blood <br /> Tracker
          </label>
        </div>
        <div
          className={style.periodTrackerMain}
          onClick={() => {
            navigation("/periodtracker");
          }}>
          <img className={style.imageFirst} src={periodTracker} alt=""></img>
          <label className={style.periodTrackerLabel}>
            Period <br /> Tracker
          </label>
        </div>
      </div>
      {/* for mobile div */}
      <div className={style.trackerContainerMobile}>
        <div className={style.firstMobile}>
          <div
            className={style.mealPlanMain}
            id="mealPlanBtn"
            onClick={() => {
              setShowMealModal(true);
            }}>
            <img className={style.imageFirst} src={meanDownload} alt=""></img>
            <label className={style.mealPlanLabel}>
              Download <br /> Meal Plan
            </label>
          </div>
          <div
            className={style.weightTrackerMain}
            onClick={() => {
              navigation("/weighttracker");
            }}>
            <img className={style.imageFirst} src={weightTracker} alt=""></img>
            <label className={style.weightTrackerLabel}>
              Weight <br /> Tracker
            </label>
          </div>
          <div
            className={style.inchTrackerMain}
            onClick={() => {
              navigation("/inchtracker");
            }}>
            <img className={style.imageFirst} src={inchTracker} alt=""></img>
            <label className={style.inchTrackerLabel}>
              Inch Loss <br /> Tracker
            </label>
          </div>
        </div>
        <div className={style.secondMobile}>
          <div
            className={style.photoTrackerMain}
            onClick={() => {
              setShowPhotoModal(true);
            }}>
            <img className={style.imageFirst} src={photoTracker} alt=""></img>
            <label className={style.photoTrackerLabel}>
              Photo <br /> Tracker
            </label>
          </div>
          <div
            className={style.bloodTrackerMain}
            onClick={() => {
              navigation("/bloodtracker");
            }}>
            <img className={style.imageFirst} src={bloodTracker} alt=""></img>
            <label className={style.bloodTrackerLabel}>
              Blood <br /> Tracker
            </label>
          </div>
          <div
            className={style.periodTrackerMain}
            onClick={() => {
              navigation("/periodtracker");
            }}>
            <img className={style.imageFirst} src={periodTracker} alt=""></img>
            <label className={style.periodTrackerLabel}>
              Period <br /> Tracker
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgressTracker;
