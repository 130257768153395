function openSidebar() {
  document.getElementById("blackBackgroundNotification").style.display = "block";
  document.getElementById("sidebarMainDiv").style.position = "fixed";
  if (window.innerWidth <= "400") {
    document.getElementById("sidebarMainDiv").style.width = "100%";
    document.getElementById("clientInfo").style.display = "flex";
    document.getElementById("redirectionLinks").style.display = "block";
  } else if (window.innerWidth > "400" && window.innerWidth < "600") {
    document.getElementById("sidebarMainDiv").style.width = "70%";
    document.getElementById("clientInfo").style.display = "flex";
    document.getElementById("redirectionLinks").style.display = "block";
  } else if (window.innerWidth > "600" && window.innerWidth < "800") {
    document.getElementById("sidebarMainDiv").style.width = "50%";
    document.getElementById("clientInfo").style.display = "flex";
    document.getElementById("redirectionLinks").style.display = "block";
  } else if (window.innerWidth > "800" && window.innerWidth < "900") {
    document.getElementById("sidebarMainDiv").style.width = "30%";
    document.getElementById("clientInfo").style.display = "flex";
    document.getElementById("redirectionLinks").style.display = "block";
  } else {
    document.getElementById("clientInfo").style.display = "flex";
    document.getElementById("redirectionLinks").style.display = "block";
    document.getElementById("sidebarMainDiv").style.position = "relative";
    document.getElementById("sidebarMainDiv").style.width = "20%";
    document.getElementById("blackBackgroundNotification").style.display = "none";
  }
}
function openNav() {
  if (window.innerWidth <= "400") {
    document.getElementById("notificationPanel").style.width = "88%";
  } else if (window.innerWidth > "400" && window.innerWidth < "600") {
    document.getElementById("notificationPanel").style.width = "90%";
  } else if (window.innerWidth > "600" && window.innerWidth < "800") {
    document.getElementById("notificationPanel").style.width = "94%";
  } else if (window.innerWidth > "800" && window.innerWidth < "1024") {
    document.getElementById("notificationPanel").style.width = "50%";
  } else {
    document.getElementById("notificationPanel").style.width = "26%";
  }
  document.getElementById("notificationPanel").style.padding = "0px 24px";
  document.getElementById("blackBackgroundNotification").style.display = "block";
}
function closeSidebar() {
  document.getElementById("sidebarMainDiv").style.width = "0%";
  document.getElementById("sidebarMainDiv").style.padding = "0px";
  document.getElementById("blackBackgroundNotification").style.display = "none";
  document.getElementById("clientInfo").style.display = "none";
  document.getElementById("redirectionLinks").style.display = "none";
}

function closeNav() {
  document.getElementById("notificationPanel").style.width = "0";
  document.getElementById("notificationPanel").style.padding = "0px";
  document.getElementById("blackBackgroundNotification").style.display = "none";
}
export { openSidebar, openNav, closeNav, closeSidebar };
