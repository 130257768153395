//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useRef, useEffect, useState } from "react";
import styles from "../assets/styles/weightTrackerModal.module.css";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import { useSelector } from "react-redux";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function InchModal({ setShowUpdateInfoModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const Session = useRef();
  const Chest = useRef();
  const Waist = useRef();
  const Hips = useRef();
  const Stomach = useRef();
  const Thighs = useRef();
  const Arms = useRef();
  let initialDetails = useSelector((state) => state.allData?.user_details);
  const [userDetails, setUserDetails] = useState(initialDetails);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  useEffect(() => {
    let userDetailsTemp = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userDetailsTemp);
    document.getElementById("date").valueAsDate = new Date();
  }, []);

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let error = false;
    let checklist = [
      // { id: "session", name: "errorMessageSession", ref: Session },
      { id: "chest", name: "errorMessageChest", ref: Chest },
      { id: "waist", name: "errorMessageWaist", ref: Waist },
      { id: "hips", name: "errorMessageHips", ref: Hips },
      { id: "stomach", name: "errorMessageStomach", ref: Stomach },
      { id: "thighs", name: "errorMessageThighs", ref: Thighs },
      { id: "arms", name: "errorMessageArms", ref: Arms },
    ];

    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
    });
    !error && addInchloss(error);
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  //---------------------------------------------------------Add Inch loss Function-----------------------------------------------------------------------//

  function addInchloss() {
    Request.post(`${BASE_URL}tracker/add_inchlosstracker`, {
      Session: Session?.current?.value,
      Chest: Chest?.current?.value,
      Waist: Waist?.current?.value,
      Hips: Hips?.current?.value,
      Stomach: Stomach?.current?.value,
      Arms: Arms?.current?.value,
      Thighs: Thighs?.current?.value,
      Date: Date?.current?.value,
      Client_id: userDetails?.id,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("inchloss data added!");
          setShowUpdateInfoModal(false);
        } else if (res?.data?.message === "Inch tracker exausted for the session") {
          alert("Maximum entries of inch reached for this session!");
          setShowUpdateInfoModal(false);
        } else if (res?.data?.message === "No meal plan assigned") {
          alert("Please wait till a meal plan is assigned to you!");
          setShowUpdateInfoModal(false);
        } else {
          alert("Error addding inchloss tracking");
          console.log("Error adding inchloss tracking data", res?.data?.message);
        }
      })
      .catch((err) => {
        console.error("Error adding inchloss tracking data", err);
      });
  }
  
  //---------------------------------------------------------Add Inch loss Function-----------------------------------------------------------------------//


  return (
    <>
      {/* <div className={styles.modalBackground}></div>
      <div className={styles.modal}>
        
      </div> */}
      <div className={styles.modalContent}>
        <div>
          <p className={styles.title}>Inch Tracker </p>
        </div>
        <div className={styles.mainContainerWB}>
          {/* <div className={styles.container}>
            <div>
              <label className={styles.font}> Session <span className={styles.span}>*</span></label>
            </div>

            <input ref={Session} type="text" className={styles.input} placeholder="Session" required id="session"></input>
            <span className={styles.errorSpan} id="errorMessageSession"></span>
          </div> */}
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Chest <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Chest}
                type="text"
                className={styles.input}
                placeholder="0 in"
                required
                id="chest"
              ></input>
              <span className={styles.errorSpan} id="errorMessageChest"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Date <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input type="date" className={styles.date} required id="date" readOnly></input>
              <span className={styles.errorSpan} id="errorMessageDate"></span>
            </div>
          </div>
        </div>

        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Waist <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Waist}
                type="text"
                className={styles.input}
                placeholder="0 in"
                required
                id="waist"
              ></input>
              <span className={styles.errorSpan} id="errorMessageWaist"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Hips <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Hips}
                type="text"
                className={styles.input}
                placeholder="0 in"
                required
                id="hips"
              ></input>
              <span className={styles.errorSpan} id="errorMessageHips"></span>
            </div>
          </div>
        </div>

        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Stomach <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Stomach}
                type="text"
                className={styles.input}
                placeholder="0 in"
                required
                id="stomach"
              ></input>
              <span className={styles.errorSpan} id="errorMessageStomach"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Thighs <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Thighs}
                type="text"
                className={styles.input}
                placeholder="0 in"
                required
                id="thighs"
              ></input>
              <span className={styles.errorSpan} id="errorMessageThighs"></span>
            </div>
          </div>
        </div>
        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Arms <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Arms}
                type="text"
                className={styles.input}
                placeholder="0 in"
                required
                id="arms"
              ></input>
              <span className={styles.errorSpan} id="errorMessageArms"></span>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.forsizeoffifty}>
            <button
              className={styles.submit + " " + styles.forsizeofone}
              onClick={() => {
                Validate();
              }}
            >
              <label className={styles.sumitText}>Submit</label>
            </button>
          </div>

          <div className={styles.forsizeoffifty}>
            <button
              className={styles.cancel + " " + styles.forsizeofone}
              onClick={() => {
                setShowUpdateInfoModal(false);
              }}
              id="cancelBtn"
            >
              <label className={styles.cancelText}>Cancel</label>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default InchModal;
