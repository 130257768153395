//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import style from "../../assets/styles/ViewClient.module.css";
import vector from "../../assets/images/Vector.png";
import viewclient from "../../assets/images/viewclient.png";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import moment from "moment";
import Request from "axios";
import { BASE_URL } from "../../components/baseURL";
import MealModal from "../../components/MealModal";
import TabularAssessment from "../../components/TabularAssessment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ViewClient() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const location = useLocation();
  const [data, setData] = useState(location?.state?.data);
  const [photos, setPhotos] = useState();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [showModal, setShowModal] = useState(false);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //--------------------------------------------------------------------UseEffect to Dispatch Routes ---------------------------------------------------------------------------//

  useEffect(() => {
    let select = document.getElementById("status");
    if (data) {
      localStorage.setItem("viewclient", location?.state?.data);
      let status =
        location?.state?.data?.PlanStatus == 1 && location?.state?.data?.Isexpired === 1
          ? "Completed"
          : location?.state?.data?.PlanStatus == 3
          ? "Dormant"
          : location?.state?.data?.PlanStatus == 1
          ? "Active"
          : location?.state?.data?.PlanStatus == 0
          ? "Rejected"
          : location?.state?.data?.PlanStatus == 2
          ? "Pending"
          : location?.state?.data?.PlanStatus == 4
          ? "Incomplete"
          : "Select Status";
      select.value = status;
    } else {
      let temp = localStorage.getItem("viewclient");
      let status =
        temp.PlanStatus === 1
          ? "Active"
          : temp.PlanStatus === 3
          ? "Dormant"
          : temp.PlanStatus === 1 && temp.Isexpired === 1
          ? "Completed"
          : temp.PlanStatus === 0
          ? "Rejected"
          : temp.PlanStatus === 2
          ? "Pending"
          : "Select Status";
      select.value = status;
      setData(temp);
    }
    getClientPhoto();
    console.log("Select", select.value);
    dispatch({ type: "ROUTES", payload: "View Assessment" });
  }, []);

  //--------------------------------------------------------------------UseEffect to Dispatch Routes ---------------------------------------------------------------------------//
  
  //--------------------------------------------------------------------Get Client Photo Function ---------------------------------------------------------------------------//

  function getClientPhoto() {
    Request.get(`${BASE_URL}tracker/getPhotoTracker?id=${data?.Rid ? data?.Rid : data?.id}`)
      .then((res) => {
        setPhotos(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log("Error fetching client photo", err);
      });
  }

  //--------------------------------------------------------------------Get Client Photo Function ---------------------------------------------------------------------------//

  //--------------------------------------------------------------------UseEffect to style SideBar ---------------------------------------------------------------------------//

  useEffect(() => {
    if (!showModal) {
      document.getElementById("sidebarMainDiv").style.zIndex = 5;
    } else {
      document.getElementById("sidebarMainDiv").style.zIndex = 1;
    }
  }, [showModal]);

  //--------------------------------------------------------------------UseEffect to style SideBar ---------------------------------------------------------------------------//

  return (
    <div>
      {showModal && (
        <MealModal
          setShowMealModal={setShowModal}
          id={data?.Rid ? data?.Rid : data?.id}
          planId={data?.Planid}
        />
      )}
      <div className={style.maindiv}>
        <div
          className={style.backdiv}
          onClick={() => {
            navigation(-1);
          }}
        >
          <img src={vector} alt="" className={style.backimage}></img>
          <p className={style.backp}>Back</p>
        </div>
        <div className={style.secondmaindiv}>
          <div className={style.seconddiv}>
            <div
              className={style.colset + " " + style.chngeafterfive + " " + style.paddingrightremove}
            >
              <p className={style.pchange}>Before</p>

              <img
                src={
                  photos
                    ? `https://server.healvick.com/beforeafter/${photos.Beforeurl}`
                    : viewclient
                }
                alt=""
                className={style.viewchange}
              ></img>
            </div>
            <div
              className={style.colset + " " + style.chngeafterfive + " " + style.paddingleftremove}
            >
              <p className={style.pchange}>After</p>
              <img
                src={
                  photos ? `https://server.healvick.com/beforeafter/${photos.Afterurl}` : viewclient
                }
                alt=""
                className={style.viewchange}
              ></img>
            </div>
          </div>
          <div className={style.secondnamediv}>
            <div className={style.clientnameflex + " " + style.changesofflex}>
              <div className={style.colset + " " + style.threezero}>
                <p className={style.greyname}>Client Name</p>
                <p className={style.blackname}>
                  {data?.Firstname ? data?.Firstname : "-"} {data?.Lastname ? data?.Lastname : "-"}
                </p>
              </div>

              <div className={style.colset + " " + style.twofive}>
                <p className={style.greyname}>Date of Register</p>
                <p className={style.blackname}>{moment(data?.CreatedDate).format("YYYY-MM-DD")}</p>
              </div>

              <div className={style.colset + " " + style.onefive}>
                <p className={style.greyname}>Gender</p>
                <p className={style.blackname}>{data?.Gender ? data?.Gender : "-"}</p>
              </div>

              <div className={style.colset + " " + style.threezero}>
                <p className={style.greyname}>Age</p>
                <p className={style.blackname}>{data?.Age ? data?.Age : "-"}</p>
              </div>
            </div>
            <div
              className={style.clientnameflex + " " + style.changesofflex + " " + style.thestart}
            >
              <div className={style.colset}>
                <p className={style.greyname}>Email</p>
                <p className={style.blackname}>{data?.EmailId ? data?.EmailId : "-"}</p>
              </div>

              <div className={style.colset + " " + style.thethree}>
                <p className={style.greyname}>Mobile No.</p>
                <p className={style.blackname}>{data?.Mobilenumber ? data?.Mobilenumber : "-"}</p>
              </div>

              <div className={style.colset + " " + style.thethree}>
                <p className={style.greyname}>Meal Plan Date</p>
                <p className={style.blackname}>
                  {data?.MealStartDate ? moment(data?.MealStartDate).format("YYYY-MM-DD") : "-"}{" "}
                  {" to "}
                  {data?.MealEndDate ? moment(data?.MealEndDate).format("YYYY-MM-DD") : "-"}
                </p>
              </div>
            </div>
            <div
              className={
                style.clientnameflex +
                " " +
                style.removemargin +
                " " +
                style.changesofflex +
                " " +
                style.thestart
              }
            >
              <div className={style.colset + " " + style.thefifty}>
                <p className={style.greyname}>Plan Purchase Date</p>
                <p className={style.blackname}>
                  {data?.Purchasedate ? moment(data?.Purchasedate).format("YYYY-MM-DD") : "-"}
                </p>
              </div>
              <div className={style.colset + " " + style.thefifty}>
                <p className={style.greyname}>Plan Expiration Date</p>
                <p className={style.blackname}>
                  {data?.Expirydate ? moment(data?.Expirydate).format("YYYY-MM-DD") : "-"}
                </p>
              </div>
              <div className={style.colset + " " + style.thefifty}>
                <p className={style.greyname}>Assessment Edited Date</p>
                <p className={style.blackname}>
                  {data?.EditedDate ? moment(data?.EditedDate).format("YYYY-MM-DD") : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.mainWeightChangeClient}>
          <div className={style.mainWeightChangeClientLeft}>
            <div>
              <p className={style.weightinfo}>Weight Information</p>
            </div>
            <div className={style.clientnameflexnew + " " + style.changeofblocktonone}>
              <div className={style.colset}>
                <p className={style.greyname}>Weight</p>
                <p className={style.blackname}>{data?.Weight} kgs</p>
              </div>

              <div className={style.colset}>
                <p className={style.greyname}>Last weight you saw on scale</p>
                <p className={style.blackname}>{data?.latestWeight} kgs</p>
              </div>

              {/* <div className={style.colset}>
            <p className={style.greyname}>Weight difference</p>
            <p className={style.blackname}>4.06 kgs</p>
          </div> */}

              {/* <div className={style.colset}>
            <p className={style.greyname}>Weight measure date</p>
            <p className={style.blackname}>2023-09-15</p>
          </div> */}
            </div>

            <div className={style.clientnameflexnewcol + " " + style.noneforfive}>
              <div className={style.newcols}>
                <div className={style.colset + " " + style.changecenter}>
                  <p className={style.greyname}>Weight</p>
                  <p className={style.blackname}>{data?.Weight} kgs</p>
                </div>

                <div className={style.colset + " " + style.changecenter}>
                  <p className={style.greyname}>Last weight you saw on scale</p>
                  <p className={style.blackname}>{data?.Lastweight} kgs</p>
                </div>
              </div>
            </div>

            <div className={style.btnend}>
              <button
                className={style.btnviewmeal}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                View Meal Plan
              </button>
            </div>
          </div>
          <div className={style.mainWeightChangeClientRight}>
            <div>
              <p className={style.weightinfo}>Change Client Status</p>
            </div>
            <div className={style.clientnameflexnew}>
              <div className={style.colset}>
                <p className={style.greyname}>
                  Client Status <span>*</span>
                </p>
                <div className={style.customSelect}>
                  <select
                    id="status"
                    onChange={(e) => {
                      let statusNo =
                        e.target.value === "Active"
                          ? 1
                          : e.target.value === "Dormant"
                          ? 3
                          : e.target.value === "Completed"
                          ? 1
                          : e.target.value === "Incomplete"
                          ? 4
                          : e.target.value === "Rejected"
                          ? 0
                          : e.target.value === "Pending"
                          ? 2
                          : -1;
                      let Isexpired = e.target.value === "Completed" ? 1 : 0;
                      Request.put(`${BASE_URL}client/changestatus`, {
                        Client_id: data?.Client_id,
                        Isexpired: Isexpired,
                        PlanStatus: statusNo,
                      })
                        .then(() => {
                          alert("Status updated!");
                          navigation("/manageclients");
                        })
                        .catch((err) => {
                          console.log("Error changing status", err);
                        });
                    }}
                  >
                    <option>Select Status:</option>
                    <option value="Active">Active</option>
                    <option value="Dormant">Dormant</option>
                    <option value="Completed">Completed</option>
                    <option value="Incomplete">Incomplete</option>
                    {/* <option value="Rejected">Rejected</option>
                    <option value="Pending">Pending</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.divideline}></div>
      <div className={style.bottomHealthAssessmentdiv}>
        <div className={style.divider}>
          <div className={style.innerDivider}></div>
        </div>
        <label className={style.personalInformationLabel}>Health Assessment Details</label>
        <TabularAssessment mealpage={false} id={data?.Client_id} />
      </div>
    </div>
  );
}

export default ViewClient;
