import { USER_DETAILS, ROUTES, NOTIFICATION_COUNT } from "../actions/ActionCreators";

const initialState = {
  user_details: null,
  routes: "",
  notification_count: 0,
};

export function allData(state = initialState, action) {
  switch (action.type) {
    case USER_DETAILS:
      return { ...state, user_details: action.payload };
    case ROUTES:
      return { ...state, routes: action.payload };
    case NOTIFICATION_COUNT:
      return { ...state, notification_count: action.payload };
    default:
      return state;
    // break;
  }
}
