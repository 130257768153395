//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import style from "../../assets/styles/MyProfileAdmin.module.css";
import profileImage from "../../assets/images/personIcon.png";
import uploadButton from "../../assets/images/upload.png";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function MyProfileAdmin() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const firstName = useRef();
  const lastName = useRef();
  const email = useRef();
  const number = useRef();
  const oldpassword = useRef();
  const newpassword = useRef();
  const confirmpassword = useRef();
  const upload = useRef();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [img, setImg] = useState();
  const userDetails = useSelector((state) => state?.allData?.user_details);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------Update Admin Details Function -----------------------------------------------------------------------//

  function updateAdminDetails() {
    Request.put(`${BASE_URL}admin/updateAdminDetails`, {
      Firstname: firstName.current.value,
      Lastname: lastName.current.value,
      EmailId: email.current.value,
      Mobilenumber: number.current.value,
      id: userDetailsCache?.id,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("updated");
        }
      })
      .catch((err) => {
        console.error("Error in updating admin profile", err);
      });
  }
  console.log(userDetails);
  let userDetailsCache = JSON.parse(localStorage.getItem("user"));

  //---------------------------------------------------------Update Admin Details Function -----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to Dispatch Routes-----------------------------------------------------------------------//

  useEffect(() => {
    firstName.current.value = userDetailsCache.Firstname;
    lastName.current.value = userDetailsCache.Lastname;
    email.current.value = userDetailsCache.Emailid;
    number.current.value = userDetailsCache.Mobilenumber;
    dispatch({ type: "ROUTES", payload: "My Profile" });
  }, []);

  //---------------------------------------------------------useEffect to Dispatch Routes-----------------------------------------------------------------------//

  //---------------------------------------------------------Get Image Data Function-----------------------------------------------------------------------//

  function getImgData(id, img) {
    const files = img;
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        // setImgUrl(this.result);
      });
      sendImageData();
    }
  }

  //---------------------------------------------------------Get Image Data Function-----------------------------------------------------------------------//
  
  //--------------------------------------------------------Send Image Data Function-----------------------------------------------------------------------//

  function sendImageData() {
    const data = new FormData();
    data.append("photo", img);
    data.append("id", userDetailsCache?.id);
    Request.post(`${BASE_URL}client/updateProfilePhoto`, data)
      .then((res) => {
        if (res?.data?.status === 200) {
          getLoginDetails();
        } else {
          alert("Error updating profile photo");
          console.log("Error updating profile photo", res?.data);
        }
      })
      .catch((err) => {
        console.log("Error updating profile photo", err);
      });
  }

  //--------------------------------------------------------Send Image Data Function-----------------------------------------------------------------------//

  //--------------------------------------------------------Get Login Details Function-----------------------------------------------------------------------//

  function getLoginDetails() {
    Request.post(`${BASE_URL}login/loginDetails`, { Client_id: userDetailsCache?.id })
      .then((res) => {
        let temp = userDetailsCache;

        temp = {
          ...temp,
          PlanStatus: res?.data?.data[0]?.PlanStatus,
          PlanType: res?.data?.data[0]?.Type,
          PlanPurchasedate: res?.data?.data[0]?.Purchasedate,
          PlanExpired: res?.data?.data[0]?.isExpired,
          Profilepicture: res?.data?.data[0]?.Profilepicture,
          PlanStartDate: res?.data?.data[0]?.PlanStartDate,
          Logindate: res?.data?.data[0]?.Logindate,
          Firstname: res?.data?.data[0]?.Firstname,
          Lastname: res?.data?.data[0]?.Lastname,
          Mobilenumber: res?.data?.data[0]?.Mobilenumber,
          Planid: res?.data?.data[0]?.Planid,
        };
        res?.data?.data[0]?.Profilepicture &&
          // setImgUrl(`http://localhost:8000/profile/${res?.data?.data[0]?.Profilepicture}`);
          dispatch({ type: "USER_DETAILS", payload: temp });
        localStorage.setItem("user", JSON.stringify(temp));
      })
      .catch((err) => {
        console.log("Error fetching login details", err);
      });
  }

  //--------------------------------------------------------Get Login Details Function-----------------------------------------------------------------------//

  //--------------------------------------------------------UseEffect to validate Image Size-----------------------------------------------------------------------//

  useEffect(() => {
    if (img?.size > 1000000) {
      alert("Please upload file of size 1MB or less!");
      setImg();
    } else {
      img && getImgData("profilepic", img);
      getLoginDetails();
    }
  }, [img]);

  //--------------------------------------------------------UseEffect to validate Image Size-----------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let error = false;
    let checklist = [
      { id: "email", name: "errorMessageEmail", ref: email, type: "email" },
      { id: "firstName", name: "errorMessageFirst", ref: firstName },
      { id: "lastName", name: "errorMessageLast", ref: lastName },
      { id: "mobileno", name: "errorMessageMobile", ref: number, type: "number" },
    ];

    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      let regrx = new RegExp(
        val.type === "email"
          ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
          : val.type === "password"
          ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
          : val.type === "number"
          ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
          : ""
      );
      if (val.ref?.current?.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else if (!regrx.test(val.ref?.current?.value)) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter Valid ${val.type}`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
    });
    if (oldpassword.current.value?.length > 0) {
      let check2 = [
        { id: "newpassword", name: "errorMessagenewPassword", ref: newpassword, type: "password" },
        {
          id: "confirmpassword",
          name: "errorMessageconfirmPassword",
          ref: confirmpassword,
          type: "password",
        },
      ];
      check2.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);
        let regrx = new RegExp(
          val.type === "email"
            ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            : val.type === "password"
            ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
            : val.type === "number"
            ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
            : ""
        );
        if (val.ref?.current?.value?.length < 1) {
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
        } else if (!regrx.test(val.ref?.current?.value)) {
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter Valid ${val.type}`;
        } else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }
      });

      if (newpassword.current.value !== confirmpassword.current.value) {
        error = true;
        document.getElementById("confirmpassword").style.border = "2px solid red";
        document.getElementById("errorMessageconfirmPassword").style.display = "block";
        document.getElementById("errorMessageconfirmPassword").textContent =
          "Please Enter Same Password";
      } else {
        document.getElementById("confirmpassword").style.border = "1.5px solid #D9D9D9";
        document.getElementById("errorMessageconfirmPassword").style.display = "none";
        document.getElementById("errorMessageconfirmPassword").textContent = "none";
      }
    }

    !error && updateAdmin();
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//
  
  //---------------------------------------------------------Update Admin Function-----------------------------------------------------------------------//

  function updateAdmin() {
    if (newpassword.current.value?.length > 0) {
      changePassword();
    }
    updateAdminDetails();
  }

  //---------------------------------------------------------Update Admin Function-----------------------------------------------------------------------//

  //---------------------------------------------------------Change Password Function-----------------------------------------------------------------------//

  function changePassword() {
    if (
      newpassword?.current?.value?.length > 0 &&
      confirmpassword?.current?.value?.length > 0 &&
      oldpassword?.current?.value?.length > 0
    ) {
      if (newpassword?.current?.value === confirmpassword?.current?.value) {
        Request.post(`${BASE_URL}client/changePassword`, {
          Emailid: userDetails?.Emailid ? userDetails?.Emailid : userDetailsCache?.Emailid,
          Password: newpassword?.current?.value,
          CurrentPassword: oldpassword?.current?.value,
        })
          .then((res) => {
            if (res?.data?.status === 200) {
              alert("Password updated successfully!");
              newpassword.current.value = "";
              confirmpassword.current.value = "";
              oldpassword.current.value = "";
            } else {
              if (res?.data?.error === "Incorrect Current Password") {
                alert("Incorrect password");
              } else {
                alert("Error updating password");
                console.log("Error updating password", res?.data?.error);
              }
            }
          })
          .catch((err) => {
            console.log("Error updating password", err);
          });
      } else {
        alert("Password doesn't match");
      }
    } else {
      alert("Please fill all details to update!");
    }
  }

  //---------------------------------------------------------Change Password Function-----------------------------------------------------------------------//
  return (
    <div>
      <div className={style.maindiv}>
        <div className={style.theflexnew}>
          <div className={style.profilePicture}>
            <img
              src={
                userDetailsCache?.Profilepicture
                  ? `https://server.healvick.com/profile/${userDetailsCache?.Profilepicture}`
                  : profileImage
              }
              alt=""
              className={style.profileImg}
            />
            <div
              className={style.bottomUploadButton}
              onClick={() => {
                upload.current.click();
              }}
            >
              <img src={uploadButton} alt="" />
              {/* <img id="profilepic" alt="" /> */}
              <input
                type="file"
                id="file"
                ref={upload}
                style={{ display: "none" }}
                onChange={(event) => {
                  var file = event.target.files[0];
                  setImg(file);
                }}
              />
            </div>
          </div>
          <div className={style.thesecond}>
            <p className={style.blacklabelnew}>
              {userDetails?.Firstname} {userDetails?.Lastname}
            </p>
            <p className={style.greylabel}>Admin</p>
          </div>
        </div>

        <div className={bootstrap["bootstrap-iso"] + " " + style.setpadding}>
          <div className={bootstrap["form-row"] + " " + style.marginoftwofive}>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                First Name <span className={style.redlabel}>*</span>
              </label>
              <input
                ref={firstName}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="First Name"
                id="firstName"
              ></input>
              <span className={style.errorSpan} id="errorMessageFirst"></span>
            </div>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Last Name <span className={style.redlabel}>*</span>
              </label>
              <input
                ref={lastName}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Last Name"
                id="lastName"
              ></input>
              <span className={style.errorSpan} id="errorMessageLast"></span>
            </div>
          </div>
          <div className={bootstrap["form-row"] + " " + style.marginoftwofive}>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Email <span className={style.redlabel}>*</span>
              </label>
              <input
                readOnly
                ref={email}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Email"
                id="email"
              ></input>
              <span className={style.errorSpan} id="errorMessageEmail"></span>
            </div>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Mobile No. <span className={style.redlabel}>*</span>
              </label>
              <input
                ref={number}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Mobile No."
                id="mobileno"
              ></input>
              <span className={style.errorSpan} id="errorMessageMobile"></span>
            </div>
          </div>
          <div>
            <p className={style.changeps}>Change Password</p>
          </div>
          <div
            className={
              bootstrap["form-row"] +
              " " +
              style.marginoftwofive +
              " " +
              style.theflex +
              " " +
              style.marginofonesixnine +
              " " +
              style.changecolumns
            }
          >
            <div className={bootstrap[("form-group", "col-md-4")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Old Password <span className={style.redlabel}>*</span>
              </label>
              <input
                ref={oldpassword}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Old Password"
                id="oldpassword"
              ></input>
              <span className={style.errorSpan} id="errorMessageoldPassword"></span>
            </div>

            <div className={bootstrap[("form-group", "col-md-4")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                New Password <span className={style.redlabel}>*</span>
              </label>
              <input
                ref={newpassword}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="New Password"
                id="newpassword"
              ></input>
              <span className={style.errorSpan} id="errorMessagenewPassword"></span>
            </div>
            <div className={bootstrap[("form-group", "col-md-4")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Confirm Password <span className={style.redlabel}>*</span>
              </label>
              <input
                ref={confirmpassword}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Confirm Password"
                id="confirmpassword"
              ></input>
              <span className={style.errorSpan} id="errorMessageconfirmPassword"></span>
            </div>
          </div>
          <div className={style.theflexend}>
            <button
              className={style.updatebtn}
              onClick={() => {
                Validate();
              }}
            >
              {" "}
              Update{" "}
            </button>
            <button
              className={style.cancelbtn}
              onClick={() => {
                navigation("/admindashboard");
              }}
            >
              {" "}
              Cancel{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfileAdmin;
