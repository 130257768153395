//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useRef, useEffect, useState } from "react";
import styles from "../assets/styles/bloodTrackerModal.module.css";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import { useSelector } from "react-redux";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function BloodTrackerModal({ setShowUpdateInfoModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const Session = useRef();
  const Haemoglobin = useRef();
  const Uricacid = useRef();
  const Creatine = useRef();
  const Calcium = useRef();
  const BloodPressure = useRef();
  const GlucoseFasting = useRef();
  const glucodePostPrandial = useRef();
  const HbA1c = useRef();
  const T3 = useRef();
  const T4 = useRef();
  const TSH = useRef();
  const LDL = useRef();
  const HDL = useRef();
  const Triglycerides = useRef();
  const VLDL = useRef();
  let initialDetails = useSelector((state) => state.allData?.user_details);
  const [userDetails, setUserDetails] = useState(initialDetails);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  useEffect(() => {
    let userDetailsTemp = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userDetailsTemp);
  }, []);

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  //---------------------------------------------------------Add Blood Tracker Function-----------------------------------------------------------------------//

  function addBloodTracker() {
    Request.post(`${BASE_URL}tracker/add_getBloodTracker`, {
      Session: Session?.current?.value,
      Haemoglobin: Haemoglobin?.current?.value,
      Uricacid: Uricacid?.current?.value,
      Creatine: Creatine?.current?.value,
      Calcium: Calcium?.current?.value,
      Bloodpressure: BloodPressure?.current?.value,
      Glucosefasting: GlucoseFasting?.current?.value,
      Glucosepostprandial: glucodePostPrandial?.current?.value,
      HbA1c: HbA1c?.current?.value,
      ThyroidT3: T3?.current?.value,
      ThyroidT4: T4?.current?.value,
      ThyroidTSH: TSH?.current?.value,
      CholesterolLDL: LDL?.current?.value,
      CholesterolTriglycerides: Triglycerides?.current?.value,
      CholesterolHDL: HDL?.current?.value,
      CholesterolVLDL: VLDL?.current?.value,
      HbA1c: HbA1c?.current?.value,
      Client_id: userDetails?.id,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Blood tracker data added!");
          setShowUpdateInfoModal(false);
          Session.current.value = "";
          Haemoglobin.current.value = "";
          Uricacid.current.value = "";
          Creatine.current.value = "";
          Calcium.current.value = "";
          BloodPressure.current.value = "";
          GlucoseFasting.current.value = "";
          glucodePostPrandial.current.value = "";
          HbA1c.current.value = "";
          T3.current.value = "";
          T4.current.value = "";
          TSH.current.value = "";
          LDL.current.value = "";
          Triglycerides.current.value = "";
          HDL.current.value = "";
          VLDL.current.value = "";
          HbA1c.current.value = "";
        } else if (res?.data?.message === "Blood tracker exausted for the session") {
          alert("Maximum entries of Blood reached for this session!");
          setShowUpdateInfoModal(false);
        } else if (res?.data?.message === "No meal plan assigned") {
          alert("Please wait till a meal plan is assigned to you!");
          setShowUpdateInfoModal(false);
        } else {
          alert("Error addding Blood Tracker");
          console.log("Error adding Blood Tracker", res?.data?.message);
          console.log("Error adding blood tracker data!", res?.data);
        }
      })
      .catch((err) => {
        console.error("Error adding Blood Tracker", err);
      });
  }

  //---------------------------------------------------------Add Blood Tracker Function-----------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let error = false;
    let checklist = [
      // { id: "session", name: "errorMessageSession", ref: Session },
      // { id: "Haemoglobin", name: "errorMessageHaemoglobin", ref: Haemoglobin },
      // { id: "UricAcid", name: "errorMessageUricAcid", ref: Uricacid },
      // { id: "Creatine", name: "errorMessageCreatine", ref: Creatine },
      // { id: "Calcium", name: "errorMessageCalcium", ref: Calcium },
      // { id: "BloodPressure", name: "errorMessageBloodPressure", ref: BloodPressure },
      // { id: "GlucoseFasting", name: "errorMessageGlucoseFasting", ref: GlucoseFasting },
      // {
      //   id: "glucodePostPrandial",
      //   name: "errorMessageglucodePostPrandial",
      //   ref: glucodePostPrandial,
      // },
      // { id: "HbA1c", name: "errorMessageHbA1c", ref: HbA1c },
      // { id: "TSH", name: "errorMessageTSH", ref: TSH },
      // { id: "T3", name: "errorMessageT3", ref: T3 },
      // { id: "T4", name: "errorMessageT4", ref: T4 },
      // { id: "LDL", name: "errorMessageLDL", ref: LDL },
      // { id: "HDL", name: "errorMessageHDL", ref: HDL },
      // { id: "VLDL", name: "errorMessageVLDL", ref: VLDL },
      // { id: "Triglycerides", name: "errorMessageTriglycerides", ref: Triglycerides },
    ];

    checklist.map((val, i) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
    });
    console.log("==>", error);
    !error && addBloodTracker();
  }
  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//


  return (
    <>
      <div className={styles.modalContent}>
        <div>
          <p className={styles.title}>Blood Tracker </p>
        </div>
        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Hemoglobin <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={Haemoglobin}
                type="text"
                className={styles.input}
                placeholder="Hemoglobin"
                required
                id="Haemoglobin"
              ></input>
              <span className={styles.errorSpan} id="errorMessageHaemoglobin"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Uric Acid <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={Uricacid}
                type="text"
                className={styles.date}
                placeholder="Uric Acid"
                required
                id="UricAcid"
              ></input>
              <span className={styles.errorSpan} id="errorMessageUricAcid"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Creatine <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={Creatine}
                type="text"
                className={styles.input}
                placeholder="Creatine"
                required
                id="Creatine"
              ></input>
              <span className={styles.errorSpan} id="errorMessageCreatine"></span>
            </div>
          </div>
        </div>

        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Calcium <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={Calcium}
                type="text"
                className={styles.input}
                placeholder="Calcium"
                required
                id="Calcium"
              ></input>
              <span className={styles.errorSpan} id="errorMessageCalcium"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Blood Pressure <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={BloodPressure}
                type="text"
                className={styles.input}
                placeholder="BloodPressure"
                required
                id="BloodPressure"
              ></input>
              <span className={styles.errorSpan} id="errorMessageBloodPressure"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Glucose Fasting<span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={GlucoseFasting}
                type="text"
                className={styles.input}
                placeholder="GlucoseFasting"
                required
                id="GlucoseFasting"
              ></input>
              <span className={styles.errorSpan} id="errorMessageGlucoseFasting"></span>
            </div>
          </div>
        </div>

        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Glucose Post Prandial <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={glucodePostPrandial}
                type="text"
                className={styles.input}
                placeholder="GlucodePostPrandial"
                required
                id="glucodePostPrandial"
              ></input>
              <span className={styles.errorSpan} id="errorMessageglucodePostPrandial"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                HbA1c:<span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={HbA1c}
                type="text"
                className={styles.input}
                placeholder="HbA1c"
                required
                id="HbA1c"
              ></input>
              <span className={styles.errorSpan} id="errorMessageHbA1c"></span>
            </div>
          </div>
        </div>

        <div>
          <p className={styles.title}>Thyroid</p>
        </div>

        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                T3 <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={T3}
                type="text"
                className={styles.input}
                placeholder="T3"
                required
                id="T3"
              ></input>
              <span className={styles.errorSpan} id="errorMessageT3"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                T4:<span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={T4}
                type="text"
                className={styles.input}
                placeholder="T4"
                required
                id="T4"
              ></input>
              <span className={styles.errorSpan} id="errorMessageT4"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                T4:<span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={TSH}
                type="text"
                className={styles.input}
                placeholder="TSH"
                required
                id="TSH"
              ></input>
              <span className={styles.errorSpan} id="errorMessageTSH"></span>
            </div>
          </div>
        </div>

        <div>
          <p className={styles.title}>Cholestrol</p>
        </div>
        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                HDL <span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={HDL}
                type="text"
                className={styles.input}
                placeholder="HDL"
                required
                id="HDL"
              ></input>
              <span className={styles.errorSpan} id="errorMessageHDL"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                LDL:<span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={LDL}
                type="text"
                className={styles.input}
                placeholder="LDL"
                required
                id="LDL"
              ></input>
              <span className={styles.errorSpan} id="errorMessageLDL"></span>
            </div>
          </div>
        </div>

        <div className={styles.mainContainerWB}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                Triglycerides:<span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={Triglycerides}
                type="text"
                className={styles.input}
                placeholder="Triglycerides"
                required
                id="Triglycerides"
              ></input>
              <span className={styles.errorSpan} id="errorMessageTriglycerides"></span>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                {" "}
                VLDL:<span className={styles.span}></span>
              </label>
            </div>
            <div>
              <input
                ref={VLDL}
                type="text"
                className={styles.input}
                placeholder="VLDL"
                required
                id="VLDL"
              ></input>
              <span className={styles.errorSpan} id="errorMessageVLDL"></span>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.forsizeoffifty}>
            <button
              className={styles.submit + " " + styles.forsizeofone}
              onClick={() => {
                Validate();
              }}
            >
              <label className={styles.sumitText}>Submit</label>
            </button>
          </div>

          <div className={styles.forsizeoffifty}>
            <button
              className={styles.cancel + " " + styles.forsizeofone}
              onClick={() => {
                setShowUpdateInfoModal(false);
              }}
              id="cancelBtn"
            >
              <label className={styles.cancelText}>Cancel</label>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BloodTrackerModal;
