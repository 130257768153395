//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../assets/styles/Dropdown.module.css";
import table_dropDown_icon from "../assets/images/table_dropDown_icon.png";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const MealPlanDropdown = ({ setMask, mask, data }) => {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  let temp = JSON.parse(localStorage.getItem("user"));
  const options = ["Print Meal Plan"];
  const [selectedItem, setSelectedItem] = useState();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set Show-----------------------------------------------------------------------//

  useEffect(() => {
    !mask && setShow(false);
  }, [mask]);

  //---------------------------------------------------------UseEffect to set Show-----------------------------------------------------------------------//

  //---------------------------------------------------------On Item Click Function-----------------------------------------------------------------------//

  function onItemClick(val) {
    if (val === "Print Meal Plan") {
      localStorage.setItem("planpdf", JSON.stringify(data));
      window.open("/planpdf");
    }
  }

  //---------------------------------------------------------On Item Click Function-----------------------------------------------------------------------//

  return (
    <div className="" style={{ width: "100%", position: "relative" }}>
      <a
        role="button"
        onClick={() => {
          setShow(true);
          setMask(true);
        }}
      >
        <img src={table_dropDown_icon} />
      </a>
      {show && (
        <div className={styles["dropCont"]} onBlur={() => setShow(false)}>
          {options?.map((val, i) => {
            return (
              <a
                key={String(i)}
                onClick={() => {
                  onItemClick(val);
                  setShow(false);
                  setMask(false);
                }}
                className={styles["items"]}
                style={{
                  backgroundColor:
                    val === selectedItem ? "#E6F8F1" : "transparent",
                }}
              >
                {val}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MealPlanDropdown;
