//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../assets/styles/Dropdown.module.css";
import table_dropDown_icon from "../assets/images/table_dropDown_icon.png";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const Dropdown = ({ setMask, mask, data }) => {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  let temp = JSON.parse(localStorage.getItem("user"));
  const options =
    temp?.Role === "Admin"
      ? [
          "Program",
          "View Assessment",
          "Create Meal Plan",
          "Client Progress",
          "Edit Meal Plan",
          "Print Meal Plan",
          "Blood History",
          "Period History",
          "Meal Plan History",
        ]
      : [
          "Program",
          "View Assessment",
          "Create Meal Plan",
          "Client Progress",
          "Print Meal Plan",
          "Blood History",
          "Period History",
          "Meal Plan History",
        ];
  const [selectedItem, setSelectedItem] = useState();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set Show-----------------------------------------------------------------------//

  useEffect(() => {
    !mask && setShow(false);
  }, [mask]);

  //---------------------------------------------------------UseEffect to set Show-----------------------------------------------------------------------//

  //---------------------------------------------------------On Item Click Function-----------------------------------------------------------------------//

  function onItemClick(val) {
    if (val === "View Assessment") {
      navigation("/viewclient", { state: { data: data } });
    }
    if (val === "Edit Meal Plan") {
      let tdata = {
        id: data?.id,
        Firstname: data?.Firstname,
        Lastname: data?.Lastname,
        Planid: data?.Planid,
        currentSession: data?.currentSession,
        Max_Sessions: data?.Max_Sessions,
        Weight: data?.Weight,
        latestWeight: data?.latestWeight,
      };
      tdata = { ...tdata, edit: true };
      navigation("/newmealplan", { state: { data: tdata } });
    }
    if (val === "Create Meal Plan") {
      let stdata = {
        id: data?.id,
        Firstname: data?.Firstname,
        Lastname: data?.Lastname,
        Planid: data?.Planid,
        currentSession: data?.currentSession,
        Max_Sessions: data?.Max_Sessions,
        Weight: data?.Weight,
        latestWeight: data?.latestWeight,
      };
      stdata = { ...stdata, create: true };
      navigation("/newmealplan", { state: { data: stdata } });
    }
    if (val === "Program") {
      navigation("/planhistory", { state: { data: data } });
    }
    if (val === "Client Progress") {
      navigation("/clienthistory", { state: { data: data } });
    }
    if (val === "Blood History") {
      navigation("/bloodtracker", { state: { data: data } });
    }
    if (val === "Period History") {
      navigation("/periodtracker", { state: { data: data } });
    }
    if (val === "Meal Plan History") {
      navigation("/mealplanhistory", { state: { data: data } });
    }
    if (val === "Print Meal Plan") {
      localStorage.setItem(
        "planpdf",
        JSON.stringify({
          idd: data?.id,
          Firstname: data?.Firstname,
          Lastname: data?.Lastname,
          Weight: data?.Weight,
          latestWeight: data?.latestWeight,
        })
      );
      window.open("/planpdf");
    }
  }

  //---------------------------------------------------------On Item Click Function-----------------------------------------------------------------------//

  return (
    <div className="" style={{ width: "100%", position: "relative" }}>
      <a
        role="button"
        onClick={() => {
          setShow(true);
          setMask(true);
        }}
      >
        <img src={table_dropDown_icon} />
      </a>
      {show && (
        <div className={styles["dropCont"]} onBlur={() => setShow(false)}>
          {options?.map((val, i) => {
            return (
              <a
                key={String(i)}
                onClick={() => {
                  onItemClick(val);
                  setShow(false);
                  setMask(false);
                }}
                className={styles["items"]}
                style={{
                  backgroundColor:
                    val === selectedItem ? "#E6F8F1" : "transparent",
                }}
              >
                {val}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
