//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from "react";
import crossButton from "../assets/images/notificationCloseButotn.png";
import styles from "../assets/styles/Parent.module.css";
import { useDispatch, useSelector } from "react-redux";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ReminderNotification({ body, id, setNotiList, notiList }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const notiCount = useSelector((state) => state?.allData?.notification_count);
  let temp = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //------------------------------------------------------------------- Function to Remove Notification count-----------------------------------------------------------------------------//

  function removeNotification() {
    let a = notiList;
    a[id].isNotified = true;
    temp?.Role === "Client" && localStorage.setItem("reminderclient", JSON.stringify(a));
    temp?.Role === "Admin" && localStorage.setItem("reminderadmin", JSON.stringify(a));
    setNotiList(a);
    dispatch({ type: "NOTIFICATION_COUNT", payload: notiCount - 1 });
  }

  //------------------------------------------------------------------- Function to Remove Notification count-----------------------------------------------------------------------------//

  return (
    <>
      <div className={styles.divider}></div>
      <div className={styles.notificationTwo}>
        <div className={styles.dotDiv}></div>
        <label className={styles.notificationTwoLabel}>Reminder: {body}.</label>
        <img
          className={styles.crossButtonNoti}
          onClick={() => {
            removeNotification();
          }}
          src={crossButton}
          alt=""
        />
      </div>
    </>
  );
}

export default ReminderNotification;
