//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/styles/weightTrackerModal.module.css";
import { useSelector } from "react-redux";
import { BASE_URL } from "./baseURL";
import Request from "axios";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function WeightTrackerModal({ setShowUpdateInfoModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const Session = useRef();
  const Day = useRef();
  const Weight = useRef();
  const Weightdifference = useRef();
  let initialDetails = useSelector((state) => state.allData?.user_details);
  const [userDetails, setUserDetails] = useState(initialDetails);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  useEffect(() => {
    let userDetailsTemp = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userDetailsTemp);
    document.getElementById("date").valueAsDate = new Date();
  }, []);

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let checklist = [
      // { id: "session", name: "errorMessageSession", ref: Session },
      // { id: "day", name: "errorMessageDay", ref: Day },
      { id: "weight", name: "errorMessageWeight", ref: Weight },
      // { id: "date", name: "errorMessageDate", ref: Date },
    ];
    let error = false;
    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
      console.log("Error", error);
    });
    addweighloss(error);
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  //---------------------------------------------------------Add weight Loss Function-----------------------------------------------------------------------//

  function addweighloss(error) {
    !error &&
      Request.post(`${BASE_URL}tracker/add_weightLossTracker`, {
        Session: Session?.current?.value,
        Day: Day?.current?.value,
        Weight: Weight?.current?.value,
        Weightdifference: Weightdifference?.current?.value,
        Date: Date?.current?.value,
        Client_id: userDetails?.id,
      })
        .then((res) => {
          if (res?.data?.status === 200) {
            alert("weightloss data added!");
            setShowUpdateInfoModal(false);
          } else if (res?.data?.message === "Weight tracker exausted for the session") {
            alert("Maximum entries of weight reached for this session!");
            setShowUpdateInfoModal(false);
          } else if (res?.data?.message === "No meal plan assigned") {
            alert("Please wait till a meal plan is assigned to you!");
            setShowUpdateInfoModal(false);
          } else {
            alert("Error addding weightloss tracking");
            console.log("Error adding weight tracking data", res?.data);
          }
        })
        .catch((err) => {
          console.error("Error adding weight tracking data", err);
        });
  }

  //---------------------------------------------------------Add weight Loss Function-----------------------------------------------------------------------//


  return (
    <>
      {/* <div className={styles.modalBackground}></div>
      <div className={styles.modal}>
        
      </div> */}
      <div className={styles.modalContent}>
        <div>
          <p className={styles.title}>Weight Tracker </p>
        </div>
        <div className={styles.mainContainerWB}>
          {/* <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Session <span className={styles.span}>*</span>
              </label>
            </div>

            <input
              ref={Session}
              type="text"
              className={styles.input}
              placeholder="Session"
              required
              id="session"
            ></input>
            <span className={styles.errorSpan} id="errorMessageSession"></span>
          </div> */}
          {/* <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Day <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Day}
                type="text"
                className={styles.input}
                placeholder="Day"
                required
                id="day"
              ></input>
              <span className={styles.errorSpan} id="errorMessageDay"></span>
            </div>
          </div> */}
        </div>

        <div className={styles.mainContainerWB + " " + styles.margintopmain}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Weight <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Weight}
                type="text"
                className={styles.input}
                placeholder="0 Kg"
                required
                id="weight"
              ></input>
              <span className={styles.errorSpan} id="errorMessageWeight"></span>
            </div>
          </div>

          {/* <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Weight Difference <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Weightdifference}
                type="text"
                className={styles.input}
                placeholder="Weight Difference"
                required
              ></input>
            </div>
          </div> */}
        </div>
        <div className={styles.mainContainerWB + " " + styles.margintopmain}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Date <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                // ref={Date}
                type="date"
                className={styles.date}
                // placeholder="Session"
                id="date"
                readOnly
                required
              ></input>
              <span className={styles.errorSpan} id="errorMessageDate"></span>
            </div>
          </div>
          {/* <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Weight Difference <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Weightdifference}
                type="text"
                className={styles.input}
                placeholder="Weight Difference"
                required
              ></input>
            </div>
          </div> */}
        </div>

        <div className={styles.footer}>
          <div className={styles.forsizeoffifty}>
            <button
              onClick={() => {
                Validate();
              }}
              className={styles.submit + " " + styles.forsizeofone}
            >
              <label className={styles.sumitText}>Submit</label>
            </button>
          </div>
          <div className={styles.forsizeoffifty}>
            <button
              className={styles.cancel + " " + styles.forsizeofone}
              onClick={() => {
                setShowUpdateInfoModal(false);
              }}
              id="cancelBtn"
            >
              <label className={styles.cancelText}>Cancel</label>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeightTrackerModal;
