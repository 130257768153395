//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/ManageClients.module.css";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import $ from "jquery";
import Request from "axios";
import style from "../../assets/styles/inchTrackerGrid.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function InchTracker() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [mask] = useState();
  const navigation = useNavigate();
  const userDetails = useSelector((state) => state?.allData?.user_details);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to get Inch Loss Tracker-----------------------------------------------------------------------//

  useEffect(() => {
    dispatch({ type: "ROUTES", payload: "Inch Tracker" });
    let temp = JSON.parse(localStorage?.getItem("user"));
    Request.get(
      `${BASE_URL}tracker/inchLosstracker?id=${userDetails?.id ? userDetails?.id : temp?.id}`
    )
      .then((res) => {
        setList(res?.data?.data);
        $(() => {
          $("#myTable").DataTable();
        });
        setTimeout(() => {
          styleChanges();
        }, [200]);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      });
  }, []);

  //---------------------------------------------------------UseEffect to get Inch Loss Tracker-----------------------------------------------------------------------//

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//

  function styleChanges() {
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginBottom = "40px"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.overflow = "scroll"));
    document.querySelectorAll(".dataTables_wrapper").forEach((el) => (el.style.position = "unset"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginRight = "20px"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginBottom = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach(
        (el) =>
          (el.style.background = `url(https://static.thenounproject.com/png/101791-200.png) no-repeat`)
      );
    document.querySelectorAll(".dataTables_length").forEach((el) => (el.style.marginLeft = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundSize = "22px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundPosition = "386px 15px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.paddingRight = "25px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundColor = "#f5f5f5"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.width = "420px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.height = "50px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.border = "unset"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.borderRadius = "50px"));
    document
      .querySelectorAll(".dataTables_wrapper .dataTables_info")
      .forEach((el) => (el.style.paddingLeft = "10px"));
    document.querySelectorAll(".paginate_button").forEach((el) => (el.style.color = "#FFFFFF"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.background = "#00C1BD"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.border = "1px solid #00C1BD"));
    document.querySelectorAll(".paginate_button").forEach((el) => (el.style.borderRadius = "4px"));
  }

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to style Table-----------------------------------------------------------------------//

  useEffect(() => {
    document
      .querySelectorAll("#myTable")
      .forEach((el) => (el.style.paddingBottom = mask ? "150px" : "50px"));
  }, [mask]);

  //---------------------------------------------------------UseEffect to style Table-----------------------------------------------------------------------//


  return (
    <div className={style.inchTrackerMainDiv}>
      <div
        className={style.backButtonDiv}
        onClick={() => {
          navigation("/clientdashboard");
        }}
      >
        <label> &lt; Back</label>
      </div>

      <table
        id="myTable"
        className={bootstrap[("table", "table-striped")] + " " + styles.tableCustomcls}
      >
        <thead>
          <tr>
            <th className={styles.Tablefontcls}>Date</th>
            <th className={styles.Tablefontcls}>Session</th>
            <th className={styles.Tablefontcls}>Chest</th>
            <th className={styles.Tablefontcls}>Waist</th>
            <th className={styles.Tablefontcls}>Hips</th>
            <th className={styles.Tablefontcls}>Stomach</th>
            <th className={styles.Tablefontcls}>Thighs</th>
            <th className={styles.Tablefontcls}>Arms</th>
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list?.map((val, i) => {
              return (
                <tr key={String(i)}>
                  <td className={styles.Tablefonttdcls}>
                    {moment(val?.Date).format("YYYY-MM-DD")}
                  </td>
                  <td className={styles.Tablefonttdcls}>{val?.Session}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Chest}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Waist}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Hips}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Stomach}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Thighs}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Arms}</td>
                </tr>
              );
            })
          ) : (
            <>No data to preview</>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default InchTracker;
