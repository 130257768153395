//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA7 = forwardRef(
  (
    {
      setPageIndex,
      adminData,
      profileData,
      wakeTime,
      prefer,
      preferOther,
      breakfastTime,
      breakfastFood,
      lunchFood,
      eveningSnack,
      dinnerFood,
      wholeDayFood,
      bedTime,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [, setPreferRadio] = useState("Tea");
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [
        { id: "wakeupTime", name: "errorMessagesWakeupTime", ref: wakeTime },
        { id: "timeofbreakfast", name: "errorMessagebreakfast", ref: breakfastTime },
        { id: "breakfastfood", name: "errorMessagebreakfastfood", ref: breakfastFood },
        { id: "lunch", name: "errorMessageLunch", ref: lunchFood },
        { id: "eveningsnack", name: "errorMessageeveningsnack", ref: eveningSnack },
        { id: "dinnerfood", name: "errorMessagedinnerfood", ref: dinnerFood },
        { id: "anyfood", name: "errorMessageanyfood", ref: wholeDayFood },
      ];
      let erId2 = -1;
      checklist.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);
        // let regrx = new RegExp(
        //   val.type === "email"
        //     ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        //     : val.type === "password"
        //     ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
        //     : val.type === "number"
        //     ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
        //     : ""
        // );
        if (val.ref?.current?.value?.length < 1) {
          if (erId2 == -1) {
            erId2 = val.id;
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
          document.getElementById(val.id).focus();
        } 
       
        else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }
        if (erId2 != -1) {
          document.getElementById(erId2).focus();
        }

        if (document.getElementById("otherpreferRadio").checked) {
          let id = "preferOtherfield";
          let name = "errorMessagepreferother";
          let propStyle = document.getElementById(id);
          let propText = document.getElementById(name);
          let ref = preferOther;
          if (ref?.current?.value?.length < 1) {
            error = true;
            propStyle.style.border = "2px solid red";
            propText.style.display = "block";
            propText.textContent = `Please Enter this field`;
          } else {
            propStyle.style.border = "1.5px solid #D9D9D9";
            propText.style.display = "none";
            propText.textContent = "none";
          }
        }
        
      });
      // !error && profileData && updateClientAssessment();

      !error && setPageIndex(8);
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//
    
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      prefer.current.value = "Tea";
      let cache = JSON.parse(localStorage.getItem("CA7"));
      let data = adminData ? adminData : profileData;
      if (adminData) {
        breakfastTime.current.value = data?.BreakfastTime ? data?.BreakfastTime : null;
        breakfastFood.current.value = data?.BreakfastFood ? data?.BreakfastFood : null;
        lunchFood.current.value = data?.LunchFood ? data?.LunchFood : null;
        eveningSnack.current.value = data?.EveningSnack ? data?.EveningSnack : null;
        dinnerFood.current.value = data?.DinnerFood ? data?.DinnerFood : null;
        wholeDayFood.current.value = data?.WholeDayFood ? data?.WholeDayFood : null;
        bedTime.current.value = data?.BedTime ? data?.BedTime : null;
        wakeTime.current.value = data?.WakeTime ? data?.WakeTime : null;
        if (data?.Prefer === "Tea") {
          setPreferRadio("Tea");
          prefer.current.value = "Tea";
        } else if (data?.Prefer === "Coffee") {
          setPreferRadio("Coffee");
          prefer.current.value = "Coffee";
        } else if (data?.Prefer === "Milk") {
          setPreferRadio("Milk");
          prefer.current.value = "Milk";
        } else if (data?.Prefer === "None") {
          setPreferRadio("None");
          prefer.current.value = "None";
        } else {
          setPreferRadio("Other");
          prefer.current.value = "Other";
          preferOther.current.value = data?.Prefer;
        }
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        breakfastTime.current.value = data?.BreakfastTime ? data?.BreakfastTime : null;
        breakfastFood.current.value = data?.BreakfastFood ? data?.BreakfastFood : null;
        lunchFood.current.value = data?.LunchFood ? data?.LunchFood : null;
        eveningSnack.current.value = data?.EveningSnack ? data?.EveningSnack : null;
        dinnerFood.current.value = data?.DinnerFood ? data?.DinnerFood : null;
        wholeDayFood.current.value = data?.WholeDayFood ? data?.WholeDayFood : null;
        bedTime.current.value = data?.BedTime ? data?.BedTime : null;
        wakeTime.current.value = data?.WakeTime ? data?.WakeTime : null;
        if (data?.Prefer === "Tea") {
          setPreferRadio("Tea");
          prefer.current.value = "Tea";
        } else if (data?.Prefer === "Coffee") {
          setPreferRadio("Coffee");
          prefer.current.value = "Coffee";
        } else if (data?.Prefer === "Milk") {
          setPreferRadio("Milk");
          prefer.current.value = "Milk";
        } else if (data?.Prefer === "None") {
          setPreferRadio("None");
          prefer.current.value = "None";
        } else {
          setPreferRadio("Other");
          prefer.current.value = "Other";
          preferOther.current.value = data?.Prefer;
        }
      }
    }, [adminData, profileData]);
   
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      breakfastTime.current.value = cache?.BreakfastTime ? cache?.BreakfastTime : null;
      breakfastFood.current.value = cache?.BreakfastFood ? cache?.BreakfastFood : null;
      lunchFood.current.value = cache?.LunchFood ? cache?.LunchFood : null;
      eveningSnack.current.value = cache?.EveningSnack ? cache?.EveningSnack : null;
      dinnerFood.current.value = cache?.DinnerFood ? cache?.DinnerFood : null;
      wholeDayFood.current.value = cache?.WholeDayFood ? cache?.WholeDayFood : null;
      bedTime.current.value = cache?.BedTime ? cache?.BedTime : null;
      wakeTime.current.value = cache?.WakeTime ? cache?.WakeTime : null;

      if (cache?.Prefer === "Tea") {
        setPreferRadio("Tea");
        prefer.current.value = "Tea";
      } else if (cache?.Prefer === "Coffee") {
        setPreferRadio("Coffee");
        prefer.current.value = "Coffee";
      } else if (cache?.Prefer === "Milk") {
        setPreferRadio("Milk");
        prefer.current.value = "Milk";
      } else if (cache?.Prefer === "None") {
        setPreferRadio("None");
        prefer.current.value = "None";
      } else {
        setPreferRadio("Other");
        prefer.current.value = "Other";
        preferOther.current.value = cache?.Prefer;
      }
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.dietaryRecallForm}>
        <label className={style.dietaryRecallFormLabel}>Dietary Recall</label>
        <div className={style.firstRowOfDR}>
          <div className={style.wakeupTimeDiv}>
            <label>
              What time you wake up ?<span> *</span>
            </label>
            <input
              type="text"
              placeholder="Time"
              id="wakeupTime"
              ref={wakeTime}
              onChange={(text) => {
                wakeTime.current.value = text.target.value;
              }}
            />
            <span className={style.errorSpan} id="errorMessagesWakeupTime"></span>
          </div>
          <div className={style.preferRadioInput} ref={prefer}>
            <label>
              Prefer<span> *</span>
            </label>
            <div className={style.preferRadioDiv}>
              <div className={style.preferRadioTea}>
                <input
                  id="teaRadio"
                  onChange={() => {
                    setPreferRadio("Tea");
                    prefer.current.value = "Tea";
                  }}
                  type="radio"
                  name="preferRadioSelection"
                  checked={prefer.current?.value === "Tea" ? true : false}
                />
                <label htmlFor="teaRadio">Tea</label>
              </div>
              <div className={style.preferRadioCoffee}>
                <input
                  id="coffeeRadio"
                  onChange={() => {
                    setPreferRadio("Coffee");
                    prefer.current.value = "Coffee";
                  }}
                  type="radio"
                  name="preferRadioSelection"
                  checked={prefer.current?.value === "Coffee" ? true : false}
                />
                <label htmlFor="coffeeRadio">Coffee</label>
              </div>
              <div className={style.preferRadioMilk}>
                <input
                  id="milkRadio"
                  onChange={() => {
                    setPreferRadio("Milk");
                    prefer.current.value = "Milk";
                  }}
                  type="radio"
                  name="preferRadioSelection"
                  checked={prefer.current?.value === "Milk" ? true : false}
                />
                <label htmlFor="milkRadio">Milk</label>
              </div>
              <div className={style.preferRadioNone}>
                <input
                  id="nonepreferRadio"
                  onChange={() => {
                    setPreferRadio("None");
                    prefer.current.value = "None";
                  }}
                  type="radio"
                  name="preferRadioSelection"
                  checked={prefer.current?.value === "None" ? true : false}
                />
                <label htmlFor="nonepreferRadio">None</label>
              </div>
              <div className={style.preferRadioOther}>
                <input
                  id="otherpreferRadio"
                  onChange={() => {
                    setPreferRadio("Other");
                    prefer.current.value = "Other";
                  }}
                  type="radio"
                  name="preferRadioSelection"
                  checked={prefer.current?.value === "Other" ? true : false}
                />
                <label htmlFor="otherpreferRadio">Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.secondRowOfDR}>
          <div className={style.preferOtherInputMC}>
            <label>
              Other<span></span>
            </label>
            <input
              ref={preferOther}
              onChange={(text) => {
                preferOther.current.value = text.target.value;
              }}
              type="text"
              placeholder="Other"
              id="preferOtherfield"
            />
            <span className={style.errorSpan} id="errorMessagepreferother"></span>
          </div>
        </div>
        <div className={style.thirdRowOfDR}>
          <div className={style.breaskfastInputDR}>
            <label>
              {" "}
              What time you consume your breakfast?<span> *</span>{" "}
            </label>
            <input
              ref={breakfastTime}
              onChange={(text) => {
                breakfastTime.current.value = text.target.value;
              }}
              type="text"
              placeholder="Breakfast Time"
              id="timeofbreakfast"
            />
            <span className={style.errorSpan} id="errorMessagebreakfast"></span>
          </div>
          <div className={style.breakfastFoodInputDR}>
            <label>
              {" "}
              What are the food items prepared for Breakfast?<span> *</span>{" "}
            </label>
            <input
              ref={breakfastFood}
              onChange={(text) => {
                breakfastFood.current.value = text.target.value;
              }}
              type="text"
              placeholder="Food Item"
              id="breakfastfood"
            />
            <span className={style.errorSpan} id="errorMessagebreakfastfood"></span>
          </div>
        </div>
        <div className={style.fourRowOfDR}>
          <div className={style.lunchInputDR}>
            <label>
              {" "}
              What do you have for your Lunch?<span> *</span>{" "}
            </label>
            <input
              ref={lunchFood}
              onChange={(text) => {
                lunchFood.current.value = text.target.value;
              }}
              type="text"
              placeholder="Lunch"
              id="lunch"
            />
            <span className={style.errorSpan} id="errorMessageLunch"></span>
          </div>
          <div className={style.snackInputDR}>
            <label>
              {" "}
              Evening Snack?<span> *</span>{" "}
            </label>
            <input
              ref={eveningSnack}
              onChange={(text) => {
                eveningSnack.current.value = text.target.value;
              }}
              type="text"
              placeholder="Evening Snack"
              id="eveningsnack"
            />
            <span className={style.errorSpan} id="errorMessageeveningsnack"></span>
          </div>
        </div>
        <div className={style.fiveRowOfDR}>
          <div className={style.dinnerInputDR}>
            <label>
              {" "}
              What do you usually eat for your dinner?<span> *</span>{" "}
            </label>
            <input
              ref={dinnerFood}
              onChange={(text) => {
                dinnerFood.current.value = text.target.value;
              }}
              type="text"
              placeholder="Dinner"
              id="dinnerfood"
            />
            <span className={style.errorSpan} id="errorMessagedinnerfood"></span>
          </div>
          <div className={style.anyfoodInputDR}>
            <label>
              {" "}
              Anything else you prefer eating in the whole day?<span> *</span>{" "}
            </label>
            <input
              ref={wholeDayFood}
              onChange={(text) => {
                wholeDayFood.current.value = text.target.value;
              }}
              type="text"
              placeholder="Describe"
              id="anyfood"
            />
            <span className={style.errorSpan} id="errorMessageanyfood"></span>
          </div>
        </div>
        <div className={style.sixRowOfDR}>
          <div className={style.bedTimeInputMC}>
            <label>
              What time you go to bed?<span></span>
            </label>
            <input
              ref={bedTime}
              onChange={(text) => {
                bedTime.current.value = text.target.value;
              }}
              type="text"
              placeholder="Other"
              id="bedTimeOtherfield"
            />
            <span className={style.errorSpan} id="errorMessagebedTimeOtherfield"></span>
          </div>
        </div>
      </div>
    );
  }
);
export default CA7;
