//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA2 = forwardRef(
  (
    {
      height,
      weight,
      lastWeight,
      dreamWeight,
      activityPattern,
      activityPatternOther,
      occupation,
      jobTime,
      workTravel,
      personalTravel,
      travelCountry,
      setPageIndex,
      adminData,
      profileData,
      physicalactivity,
      physicalactivityother,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [activityPatternRadio, setActivityPatternRadio] = useState("Sedentary");
    const [travelRadio, setTravelRadio] = useState("Yes");
    const [travelOften, setTravelOften] = useState("None");
    const [physicalActivityRadio, setPhysicalActivityRadio] = useState("Walking");
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [
        { id: "height", name: "errorMessageHeight", ref: height },
        { id: "weight", name: "errorMessageWeight", ref: weight },
        { id: "lastWeight", name: "errorMessagelastWeight", ref: lastWeight },
        { id: "dreamWeight", name: "errorMessagedreamWeight", ref: dreamWeight },
        { id: "occupation", name: "errorMessageoccupation", ref: occupation },
        { id: "jobTime", name: "errorMessagejobTime", ref: jobTime },
      ];
      let erId2 = -1;
      checklist.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);
        let regrx = new RegExp(
          val.type === "email"
            ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            : val.type === "password"
            ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
            : val.type === "number"
            ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
            : ""
        );
        if (val.ref?.current?.value?.length < 1) {
          if (erId2 == -1) {
            erId2 = val.id;
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
          document.getElementById(val.id).focus();
        } else if (!regrx.test(val.ref?.current?.value)) {
          if (erId2 == -1) {
            erId2 = val.id;
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter Valid ${val.type}`;
        } else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }
        if (erId2 != -1) {
          document.getElementById(erId2).focus();
        }

        if (document.getElementById("activityPatternOther").checked) {
          let id = "activityPatternOtherfield";
          let name = "errorMessageother";
          let propStyle = document.getElementById(id);
          let propText = document.getElementById(name);
          let ref = activityPatternOther;
          if (ref?.current?.value?.length < 1) {
            error = true;
            propStyle.style.border = "2px solid red";
            propText.style.display = "block";
            propText.textContent = `Please Enter this field`;
          } else {
            propStyle.style.border = "1.5px solid #D9D9D9";
            propText.style.display = "none";
            propText.textContent = "none";
          }
        }
        if (!document.getElementById("personalTravelNone").checked) {
          if (travelCountry.current.value < 1) {
            let propText = document.getElementById("errorMessageTravelCountry");
            error = true;
            document.getElementById("travelCountry").style.border = "2px solid red";
            propText.style.display = "block";
            propText.textContent = `Please Enter this field`;
          } else {
            propStyle.style.border = "1.5px solid #D9D9D9";
            propText.style.display = "none";
            propText.textContent = "none";
          }
        }
        if (document.getElementById("physicalactivityother").checked) {
          if (physicalactivityother.current.value < 1) {
            let propText = document.getElementById("errorMessageotheractivity");
            error = true;
            document.getElementById("physicalactivityotherfield").style.border = "2px solid red";
            propText.style.display = "block";
            propText.textContent = `Please Enter this field`;
          } else {
            propStyle.style.border = "1.5px solid #D9D9D9";
            propText.style.display = "none";
            propText.textContent = "none";
          }
        }
      });
      !error && setPageIndex(3);
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//
    
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      activityPattern.current.value = "Sedentary";
      workTravel.current.value = 1;
      physicalactivity.current.value = "Walking";
      setTravelRadio("Yes");
      let cache = JSON.parse(localStorage.getItem("CA2"));
      let data = adminData ? adminData : profileData;
      if (adminData) {
        height.current.value = data?.Height;
        weight.current.value = data?.Weight;
        lastWeight.current.value = data?.Lastweight;
        dreamWeight.current.value = data?.Goal;
        if (data?.Activitypattern?.length > 0) {
          if (data?.Activitypattern === "Sedentary") {
            activityPattern.current.value = "Sedentary";

            setActivityPatternRadio("Sedentary");
          } else if (data?.Activitypattern === "Moderate") {
            activityPattern.current.value = "Moderate";

            setActivityPatternRadio("Moderate");
          } else if (data?.Activitypattern === "Heavy") {
            activityPattern.current.value = "Heavy";

            setActivityPatternRadio("Heavy");
          } else {
            activityPattern.current.value = "Other";

            activityPatternOther.current.value = data?.Activitypattern ? data.Activitypattern : "";
            setActivityPatternRadio("Other");
          }
        }
        if (data?.PhysicalActivity === "Walking") {
          setPhysicalActivityRadio("Walking");
          physicalactivity.current.value = "Walking";
        } else if (data?.PhysicalActivity === "Weight Training") {
          setPhysicalActivityRadio("Weight Training");
          physicalactivity.current.value = "Weight Training";
        } else if (data?.PhysicalActivity === "Swimming") {
          setPhysicalActivityRadio("Swimming");
          physicalactivity.current.value = "Swimming";
        } else if (data?.PhysicalActivity === "Cycling") {
          setPhysicalActivityRadio("Cycling");
          physicalactivity.current.value = "Cycling";
        } else if (data?.PhysicalActivity === "Yoga") {
          setPhysicalActivityRadio("Yoga");
          physicalactivity.current.value = "Yoga";
        } else {
          setPhysicalActivityRadio("Other");
          physicalactivity.current.value = "Other";
          physicalactivityother.current.value = data?.PhysicalActivity
            ? data?.PhysicalActivity
            : "";
        }
        if (data?.Activitypattern?.length > 0) {
          if (data?.Activitypattern === "Sedentary") {
            setActivityPatternRadio("Sedentary");
          } else if (data?.Activitypattern === "Moderate") {
            setActivityPatternRadio("Moderate");
          } else if (data?.Activitypattern === "Heavy") {
            setActivityPatternRadio("Heavy");
          } else {
            activityPatternOther.current.value = data?.Activitypattern ? data?.Activitypattern : "";
            setActivityPatternRadio("Other");
          }
        }
        occupation.current.value = data?.Occupation;
        jobTime.current.value = data?.Jobtiming;
        data?.Travelling === 0 ? setTravelRadio("No") : setTravelRadio("Yes");
        travelCountry.current.value = data?.Traveloften !== "None" ? data?.Travelwithin : "";

        data?.Traveloften === "Frequently"
          ? setTravelOften("Frequently")
          : data?.Traveloften === "Once a month"
          ? setTravelOften("Once a month")
          : data?.Traveloften === "2 - 3 times a month"
          ? setTravelOften("2 - 3 times a month")
          : setTravelOften("None");
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        height.current.value = data?.Height;
        weight.current.value = data?.Weight;
        lastWeight.current.value = data?.Lastweight;
        dreamWeight.current.value = data?.Goal;
        if (data?.Activitypattern?.length > 0) {
          if (data?.Activitypattern === "Sedentary") {
            activityPattern.current.value = "Sedentary";

            setActivityPatternRadio("Sedentary");
          } else if (data?.Activitypattern === "Moderate") {
            activityPattern.current.value = "Moderate";

            setActivityPatternRadio("Moderate");
          } else if (data?.Activitypattern === "Heavy") {
            activityPattern.current.value = "Heavy";

            setActivityPatternRadio("Heavy");
          } else {
            activityPattern.current.value = "Other";

            activityPatternOther.current.value = data?.Activitypattern ? data.Activitypattern : "";
            setActivityPatternRadio("Other");
          }
        }
        if (data?.PhysicalActivity === "Walking") {
          setPhysicalActivityRadio("Walking");
          physicalactivity.current.value = "Walking";
        } else if (data?.PhysicalActivity === "Weight Training") {
          setPhysicalActivityRadio("Weight Training");
          physicalactivity.current.value = "Weight Training";
        } else if (data?.PhysicalActivity === "Swimming") {
          setPhysicalActivityRadio("Swimming");
          physicalactivity.current.value = "Swimming";
        } else if (data?.PhysicalActivity === "Cycling") {
          setPhysicalActivityRadio("Cycling");
          physicalactivity.current.value = "Cycling";
        } else if (data?.PhysicalActivity === "Yoga") {
          setPhysicalActivityRadio("Yoga");
          physicalactivity.current.value = "Yoga";
        } else {
          setPhysicalActivityRadio("Other");
          physicalactivity.current.value = "Other";
          physicalactivityother.current.value = data?.PhysicalActivity
            ? data?.PhysicalActivity
            : "";
        }
        if (data?.Activitypattern?.length > 0) {
          if (data?.Activitypattern === "Sedentary") {
            setActivityPatternRadio("Sedentary");
          } else if (data?.Activitypattern === "Moderate") {
            setActivityPatternRadio("Moderate");
          } else if (data?.Activitypattern === "Heavy") {
            setActivityPatternRadio("Heavy");
          } else {
            activityPatternOther.current.value = data?.Activitypattern ? data?.Activitypattern : "";
            setActivityPatternRadio("Other");
          }
        }
        occupation.current.value = data?.Occupation;
        jobTime.current.value = data?.Jobtiming;
        data?.Travelling === 0 ? setTravelRadio("No") : setTravelRadio("Yes");
        travelCountry.current.value = data?.Traveloften !== "None" ? data?.Travelwithin : "";

        data?.Traveloften === "Frequently"
          ? setTravelOften("Frequently")
          : data?.Traveloften === "Once a month"
          ? setTravelOften("Once a month")
          : data?.Traveloften === "2 - 3 times a month"
          ? setTravelOften("2 - 3 times a month")
          : setTravelOften("None");
      }
    }, [adminData, profileData]);
    
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      height.current.value = cache.Height;
      weight.current.value = cache.Weight;
      lastWeight.current.value = cache.LastWeight;
      dreamWeight.current.value = cache.Goal;
      if (cache?.Activitypattern?.length > 0) {
        if (cache?.Activitypattern === "Sedentary") {
          setActivityPatternRadio("Sedentary");
        } else if (cache?.Activitypattern === "Moderate") {
          setActivityPatternRadio("Moderate");
        } else if (cache?.Activitypattern === "Heavy") {
          setActivityPatternRadio("Heavy");
        } else {
          activityPatternOther.current.value = cache?.Activitypattern ? cache?.Activitypattern : "";
          setActivityPatternRadio("Other");
        }
      }
      if (cache?.PhysicalActivity === "Walking") {
        setPhysicalActivityRadio("Walking");
        physicalactivity.current.value = "Walking";
      } else if (cache?.PhysicalActivity === "Weight Training") {
        setPhysicalActivityRadio("Weight Training");
        physicalactivity.current.value = "Weight Training";
      } else if (cache?.PhysicalActivity === "Swimming") {
        setPhysicalActivityRadio("Swimming");
        physicalactivity.current.value = "Swimming";
      } else if (cache?.PhysicalActivity === "Cycling") {
        setPhysicalActivityRadio("Cycling");
        physicalactivity.current.value = "Cycling";
      } else if (cache?.PhysicalActivity === "Yoga") {
        setPhysicalActivityRadio("Yoga");
        physicalactivity.current.value = "Yoga";
      } else {
        setPhysicalActivityRadio("Other");
        physicalactivity.current.value = "Other";
        physicalactivityother.current.value = cache?.PhysicalActivity
          ? cache?.PhysicalActivity
          : "";
      }
      occupation.current.value = cache.Occupation;
      jobTime.current.value = cache.Jobtiming;
      cache.Travelling === 0 ? setTravelRadio("No") : setTravelRadio("Yes");
      workTravel.current.value = cache.Travelling;
      // personalTravel.current.value = cache.Traveloften;
      travelCountry.current.value = cache.Traveloften !== "None" ? cache.Travelwithin : "";

      cache.Traveloften === "Frequently"
        ? setTravelOften("Frequently")
        : cache.Traveloften === "Once a month"
        ? setTravelOften("Once a month")
        : cache.Traveloften === "2 - 3 times a month"
        ? setTravelOften("2 - 3 times a month")
        : setTravelOften("None");
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.physicalInformationForm}>
        <label className={style.physicalInformationLabel}>Physical Information</label>
        <div className={style.firstRowOfPHI + " " + style.maroftop}>
          <div className={style.heightInput}>
            <label>
              {" "}
              Height<span> *</span>{" "}
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={height}
              type="text"
              placeholder="Height"
              id="height"
            />
            <span className={style.errorSpan} id="errorMessageHeight"></span>
          </div>
          <div className={style.weightInput}>
            <label>
              {" "}
              Weight<span> *</span>{" "}
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={weight}
              type="text"
              placeholder="Weight"
              id="weight"
            />
            <span className={style.errorSpan} id="errorMessageWeight"></span>
          </div>
        </div>
        <div className={style.secondRowOfPHI}>
          <div className={style.lastWeightInput}>
            <label>
              {" "}
              Last Weight you saw on scale<span> *</span>
            </label>
            <input
              ref={lastWeight}
              type="text"
              placeholder="Last Weight you saw on scale"
              id="lastWeight"
            />
            <span className={style.errorSpan} id="errorMessagelastWeight"></span>
          </div>
          <div className={style.dreamWeightInput}>
            <label>
              {" "}
              Dream Weight (Realistic Goal)<span> *</span>{" "}
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={dreamWeight}
              type="text"
              placeholder="Dream Weight (Realistic Goal)"
              id="dreamWeight"
            />
            <span className={style.errorSpan} id="errorMessagedreamWeight"></span>
          </div>
        </div>
        <div className={style.thirdRowOfPHI + " " + style.marofbot}>
          <div className={style.activityPatternRadioInput}>
            <label ref={activityPattern}>
              {" "}
              Activity Pattern<span> *</span>{" "}
            </label>
            <div className={style.activityPatternRadioDiv}>
              <div className={style.activityPatternRadioSed}>
                <input
                  id="activityPatternSedentary"
                  onChange={() => {
                    if (!adminData) {
                      setActivityPatternRadio("Sedentary");
                      activityPattern.current.value = "Sedentary";
                    }
                  }}
                  type="radio"
                  name="activityPatternRadioSelection"
                  checked={activityPatternRadio === "Sedentary" ? true : false}
                />
                <label htmlFor="activityPatternSedentary">
                  Sedentary (Desk Work, Not much movement)
                </label>
              </div>
              <div className={style.activityPatternRadioMod}>
                <input
                  id="activityPatternModerate"
                  onChange={() => {
                    if (!adminData) {
                      setActivityPatternRadio("Moderate");
                      activityPattern.current.value = "Moderate";
                    }
                  }}
                  type="radio"
                  name="activityPatternRadioSelection"
                  checked={activityPatternRadio === "Moderate" ? true : false}
                />
                <label htmlFor="activityPatternModerate">Moderate (Involves moving around)</label>
              </div>
              <div className={style.activityPatternRadioHeavy}>
                <input
                  id="activityPatternHeavy"
                  onChange={() => {
                    if (!adminData) {
                      setActivityPatternRadio("Heavy");
                      activityPattern.current.value = "Heavy";
                    }
                  }}
                  type="radio"
                  name="activityPatternRadioSelection"
                  checked={activityPatternRadio === "Heavy" ? true : false}
                />
                <label htmlFor="activityPatternHeavy">Heavy (Lifting goods etc)</label>
              </div>
              <div className={style.activityPatternRadioNone}>
                <input
                  id="activityPatternOther"
                  onChange={() => {
                    if (!adminData) {
                      activityPattern.current.value = "Other";
                      setActivityPatternRadio("Other");
                    }
                  }}
                  type="radio"
                  name="activityPatternRadioSelection"
                  checked={activityPatternRadio === "Other" ? true : false}
                />
                <label htmlFor="activityPatternOther">Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.foruthRowOfPHI}>
          <div className={style.otherInputPHI}>
            <label>
              Other<span></span>
            </label>
            <input
              readOnly={adminData ? true : false}
              onChange={() => {}}
              ref={activityPatternOther}
              type="text"
              placeholder="Other"
              id="activityPatternOtherfield"
            />
            <span className={style.errorSpan} id="errorMessageother"></span>
          </div>
          <div className={style.occupationInput}>
            <label>
              Occupation<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={occupation}
              type="text"
              placeholder="Occupation"
              id="occupation"
            />
            <span className={style.errorSpan} id="errorMessageoccupation"></span>
          </div>
        </div>
        <div className={style.fifthRowOfPHI}>
          <div className={style.workTravelRadioInput}>
            <label ref={workTravel}>
              Does your work includes Travel?<span> *</span>
            </label>
            <div className={style.workTravelRadioDiv}>
              <div
                className={style.workTravelRadioYes}
                onClick={() => {
                  if (!adminData) {
                    workTravel.current.value = 1;
                    setTravelRadio("Yes");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="workTravelYes"
                  type="radio"
                  name="workTravelRadioSelection"
                  checked={travelRadio === "Yes" ? true : false}
                />
                <label>Yes</label>
              </div>
              <div
                className={style.workTravelRadioNo}
                onClick={() => {
                  if (!adminData) {
                    workTravel.current.value = 0;
                    setTravelRadio("No");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="workTravelNo"
                  type="radio"
                  name="workTravelRadioSelection"
                  checked={travelRadio === "No" ? true : false}
                />
                <label>No</label>
              </div>
            </div>
          </div>
          <div className={style.jobTimeInput}>
            <label>
              Job Timings (Type NA if it is not applicable)<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={jobTime}
              type="text"
              placeholder="Job Timings"
              id="jobTime"
            />
            <span className={style.errorSpan} id="errorMessagejobTime"></span>
          </div>
        </div>
        <div className={style.sixthRowOfPHI}>
          <div className={style.personalTravelRadioInput}>
            <label ref={personalTravel}>
              How often do you Travel?<span> </span>
            </label>
            <div className={style.personalTravelRadioDiv}>
              <div
                className={style.personalTravelRadioFrequent}
                onClick={() => {
                  if (!adminData) {
                    personalTravel.current.value = "Frequently";
                    setTravelOften("Frequently");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="personalTravelFrequently"
                  type="radio"
                  name="personalTravelRadioSelection"
                  checked={travelOften === "Frequently" ? true : false}
                />
                <label>Frequently</label>
              </div>
              <div
                className={style.personalTravelRadioOneTime}
                onClick={() => {
                  if (!adminData) {
                    personalTravel.current.value = "Once a month";
                    setTravelOften("Once a month");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="personalTravelOneTime"
                  type="radio"
                  name="personalTravelRadioSelection"
                  checked={travelOften === "Once a month" ? true : false}
                />
                <label>Once a month</label>
              </div>
              <div
                className={style.personalTravelRadioThreeTime}
                onClick={() => {
                  if (!adminData) {
                    personalTravel.current.value = "2 - 3 times a month";
                    setTravelOften("2 - 3 times a month");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="personalTravelThreeTime"
                  type="radio"
                  name="personalTravelRadioSelection"
                  checked={travelOften === "2 - 3 times a month" ? true : false}
                />
                <label>2 - 3 times a month</label>
              </div>
              <div
                className={style.personalTravelRadioNone}
                onClick={() => {
                  if (!adminData) {
                    personalTravel.current.value = "None";
                    setTravelOften("None");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="personalTravelNone"
                  type="radio"
                  name="personalTravelRadioSelection"
                  checked={travelOften === "None" ? true : false}
                />
                <label>None</label>
              </div>
            </div>
          </div>
          <div className={style.countryTravelInput}>
            <label>
              Travel within country / Foreign<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={travelCountry}
              type="text"
              placeholder="Country Name"
              id="travelCountry"
            />
            <span className={style.errorSpan} id="errorMessageTravelCountry"></span>
          </div>
        </div>
        <div className={style.sixthRowOfPHI}>
          <div className={style.personalTravelRadioInput} ref={physicalactivity}>
            <label ref={personalTravel}>
              What kind of Physical Activity you do? <span> *</span> <span> </span>
            </label>
            <div className={style.personalTravelRadioDiv}>
              <div
                className={style.personalTravelRadioFrequent}
                onClick={() => {
                  if (!adminData) {
                    physicalactivity.current.value = "Walking";
                    setPhysicalActivityRadio("Walking");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="physicalactivitywalking"
                  type="radio"
                  name="physicalActivityRadioSelection"
                  checked={physicalActivityRadio === "Walking" ? true : false}
                />
                <label htmlFor="physicalactivitywalking">Walking</label>
              </div>
              <div
                className={style.personalTravelRadioOneTime}
                onClick={() => {
                  if (!adminData) {
                    physicalactivity.current.value = "Weight Training";
                    setPhysicalActivityRadio("Weight Training");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="physicalactivityweighttraining"
                  type="radio"
                  name="physicalActivityRadioSelection"
                  checked={physicalActivityRadio === "Weight Training" ? true : false}
                />
                <label htmlFor="physicalactivityweighttraining">Weight Training</label>
              </div>
              <div
                className={style.personalTravelRadioThreeTime}
                onClick={() => {
                  if (!adminData) {
                    physicalactivity.current.value = "Swimming";
                    setPhysicalActivityRadio("Swimming");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="physicalactivityswimming"
                  type="radio"
                  name="physicalActivityRadioSelection"
                  checked={physicalActivityRadio === "Swimming" ? true : false}
                />
                <label htmlFor="physicalactivityswimming">Swimming</label>
              </div>
              <div
                className={style.personalTravelRadioNone}
                onClick={() => {
                  if (!adminData) {
                    physicalactivity.current.value = "Cycling";
                    setPhysicalActivityRadio("Cycling");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="physicalactivitycycling"
                  type="radio"
                  name="physicalActivityRadioSelection"
                  checked={physicalActivityRadio === "Cycling" ? true : false}
                />
                <label htmlFor="physicalactivitycycling">Cycling</label>
              </div>
              <div
                className={style.personalTravelRadioNone}
                onClick={() => {
                  if (!adminData) {
                    physicalactivity.current.value = "Yoga";
                    setPhysicalActivityRadio("Yoga");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="physicalactivityyoga"
                  type="radio"
                  name="physicalActivityRadioSelection"
                  checked={physicalActivityRadio === "Yoga" ? true : false}
                />
                <label htmlFor="physicalactivityyoga">Yoga</label>
              </div>
              <div
                className={style.personalTravelRadioNone}
                onClick={() => {
                  if (!adminData) {
                    physicalactivity.current.value = "Other";
                    setPhysicalActivityRadio("Other");
                  }
                }}
              >
                <input
                  onChange={() => {}}
                  id="physicalactivityother"
                  type="radio"
                  name="physicalActivityRadioSelection"
                  checked={physicalActivityRadio === "Other" ? true : false}
                />
                <label htmlFor="physicalactivityother">Any other, please specify</label>
              </div>
            </div>
          </div>
          <div className={style.otherInputPHI}>
            <label>
              Other<span></span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={physicalactivityother}
              onChange={(text) => {
                physicalactivityother.current.value = text.target.value;
              }}
              type="text"
              placeholder="Other"
              id="physicalactivityotherfield"
            />
            <span className={style.errorSpan} id="errorMessageotheractivity"></span>
          </div>
        </div>
      </div>
    );
  }
);

export default CA2;
