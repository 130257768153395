//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import notificationCloseButotn from "../assets/images/notificationCloseButotn.png";
import styles from "../assets/styles/Parent.module.css";
import PlanNotification from "./PlanNotification";
import React, { useEffect, useState } from "react";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import { useDispatch } from "react-redux";
import RegisterNotification from "./RegisterNotification";
import MealPlanNotification from "./MealPlanNotification";
import AssessmentNotification from "./AssessmentNotification";
import TrackerNotification from "./TrackerNotification";
import moment from "moment";
import ReminderNotification from "./ReminderNotification";
import MealApproveNotification from "./MealApproveNotification";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function NotificationPanel({ closeNav }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [notiList, setNotiList] = useState([]);
  const dispatch = useDispatch();
  let temp = JSON.parse(localStorage.getItem("user"));
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------------Client Reminder Function-----------------------------------------------------------------//

  function Clientreminders(prop) {
    let date = temp?.PlanStartDate;
    let todays_date = moment();
    let dateDiff = todays_date.diff(date, "days");
    let Expdate = new Date(temp?.Enddate);
    let expDiff = todays_date.diff(Expdate, "days");
    let tempList = prop;
    let remcache = JSON.parse(localStorage.getItem("reminderclient"));
    if (remcache) {
      remcache?.map((val) => {
        if (val.isNotified && todays_date.diff(val.date, "days") < 3) {
        } else {
          if (val.tableName === "reminder") {
            if (dateDiff == 6) {
              if (
                !val.isNotified &&
                val.text !== "Please update your weight for this session"
              ) {
                tempList = [
                  ...tempList,
                  {
                    id: tempList?.length ? tempList?.length : 0 + 1,
                    tableName: "reminder",
                    isNotified: false,
                    date: moment(),
                    text: "Please update your weight for this session",
                  },
                ];
              }
            } else if (dateDiff == 14) {
              if (
                !val.isNotified &&
                val.text !==
                  "Please update your Weight,Inch and Photo for this session"
              ) {
                tempList = [
                  ...tempList,
                  {
                    id: tempList?.length ? tempList?.length : 0 + 1,
                    tableName: "reminder",
                    isNotified: false,
                    date: moment(),
                    text: "Please update your Weight,Inch and Photo for this session",
                  },
                ];
              }
            } else if (expDiff == -7) {
              if (
                !val.isNotified &&
                val.text !==
                  "Please update your Weight,Inch and Photo for this session"
              ) {
                tempList = [
                  ...tempList,
                  {
                    id: tempList?.length ? tempList?.length : 0 + 1,
                    tableName: "reminder",
                    isNotified: false,
                    date: moment(),
                    text: "Please update your meal plan",
                  },
                ];
              }
            } else if (expDiff == -3) {
              if (
                !val.isNotified &&
                val.text !==
                  "Please update your Weight,Inch and Photo for this session"
              ) {
                tempList = [
                  ...tempList,
                  {
                    id: tempList?.length ? tempList?.length : 0 + 1,
                    tableName: "reminder",
                    isNotified: false,
                    date: moment(),
                    text: "Please update your meal plan",
                  },
                ];
              }
            }
          }
        }
      });
    } else {
      if (dateDiff == 6) {
        tempList = [
          ...tempList,
          {
            id: tempList?.length ? tempList?.length : 0 + 1,
            tableName: "reminder",
            isNotified: false,
            date: moment(),
            text: "Please update your weight for this session",
          },
        ];
      } else if (dateDiff == 14) {
        tempList = [
          ...tempList,
          {
            id: tempList?.length ? tempList?.length : 0 + 1,
            tableName: "reminder",
            isNotified: false,
            date: moment(),
            text: "Please update your Weight,Inch and Photo for this session",
          },
        ];
      }
      //  else if (expDiff == -7) {
      //   tempList = [
      //     ...tempList,
      //     {
      //       id: tempList?.length ? tempList?.length : 0 + 1,
      //       tableName: "reminder",
      //       isNotified: false,
      //       date: moment(),
      //       text: "Please update your meal plan",
      //     },
      //   ];
      // } else if (expDiff == -3) {
      //   tempList = [
      //     ...tempList,
      //     {
      //       id: tempList?.length ? tempList?.length : 0 + 1,
      //       tableName: "reminder",
      //       isNotified: false,
      //       date: moment(),
      //       text: "Please update your meal plan",
      //     },
      //   ];
      // }
    }
    localStorage.setItem("reminderclient", JSON.stringify(tempList));
    setNotiList(tempList);
    dispatch({ type: "NOTIFICATION_COUNT", payload: tempList?.length });
  }

  //---------------------------------------------------------------Client Reminder Function-----------------------------------------------------------------//

  //---------------------------------------------------------------Admin Reminder Function-----------------------------------------------------------------//

  function adminReminders(prop) {
    let tempList = prop;
    function caching(tempList, todays_date) {
      let remcache = JSON.parse(localStorage.getItem("reminderadmin"));
      let tempCount = 0;
      remcache &&
        tempList?.map((val, i) => {
          if (val?.tableName === "reminder") {
            for (var i = 0; i < remcache?.length; i++) {
              if (remcache[i]?.tableName === "reminder") {
                if (todays_date.diff(val.date, "days") < 3) {
                  if (
                    val?.text === remcache[i]?.text &&
                    remcache[i]?.isNotified
                  ) {
                    let ind = tempList?.indexOf(val);
                    tempList[ind].isNotified = true;
                    tempCount = tempCount + 1;
                  }
                }
              }
            }
          }
        });
      setNotiList(() => tempList);
      localStorage.setItem("reminderadmin", JSON.stringify(tempList));
      dispatch({
        type: "NOTIFICATION_COUNT",
        payload: tempList?.length - tempCount,
      });
    }
    Request.get(`${BASE_URL}plans/getPlansAdmin`)
      .then((res) => {
        let todays_date = moment();
        if (res?.data?.status === 200) {
          res?.data?.data?.map((val) => {
            let endDate = moment(val?.Enddate);
            let dateDiff = endDate.diff(todays_date, "days");
            if (dateDiff <= 0 && val.PlanStatus == 1) {
              tempList = [
                ...tempList,
                {
                  id: tempList?.length ? tempList?.length : -1 + 1,
                  tableName: "reminder",
                  isNotified: false,
                  text: `Please make a meal for ${val.Firstname} ${
                    val.Lastname
                  }. Last date of meal plan is ${moment(endDate).format(
                    "YYYY-MM-DD"
                  )}`,
                  date: moment().format("YYYY-MM-DD"),
                },
              ];
            }
          });
          caching(tempList, todays_date);
        } else {
          alert("Errror fetching admin reminders");
        }
      })
      .catch((err) => {
        console.log("Error fetching admin notifications!", err);
      });
  }

  //---------------------------------------------------------------Admin Reminder Function-----------------------------------------------------------------//

  //---------------------------------------------------------------Admin Notification Function-----------------------------------------------------------------//

  async function getAdminNotifications() {
    Request.get(`${BASE_URL}notifications/getAdminNotifications`)
      .then((res) => {
        if (res?.data?.status === 200) {
          setNotiList(res?.data?.data);
          adminReminders(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("Error fetching admin notifications!", err);
      });
  }

  //---------------------------------------------------------------Admin Notification Function-----------------------------------------------------------------//

  //---------------------------------------------------------------Client Notification Function-----------------------------------------------------------------//

  async function getClientNotifications() {
    Request.post(`${BASE_URL}notifications/getClientNotifications`, {
      Client_id: temp?.id,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          setNotiList(res?.data?.data);
          Clientreminders(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("Error fetching admin notifications!", err);
      });
  }

  //---------------------------------------------------------------Client Notification Function-----------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set Notifications-----------------------------------------------------------------------//

  useEffect(() => {
    temp?.Role === "Admin" && getAdminNotifications();
    temp?.Role === "Client" && getClientNotifications();
    const interval = setInterval(() => {
      temp?.Role === "Admin" && getAdminNotifications();
      temp?.Role === "Client" && getClientNotifications();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //---------------------------------------------------------UseEffect to set Notifications-----------------------------------------------------------------------//

  return (
    <div id="notificationPanel" className={styles.notificationPanel}>
      <div className={styles.notificationHeading}>
        <label className={styles.notificationLabel}>Notifications</label>
        <img
          src={notificationCloseButotn}
          onClick={closeNav}
          className={styles.closebtn}
          alt=""
        />
      </div>
      {notiList?.length > 0 ? (
        <>
          {[]
            .concat(notiList)
            ?.sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1))
            ?.map((val, i) => {
              return (
                <div key={String(i)}>
                  {notiList ? <></> : <></>}
                  {val.tableName === "planhistory" && (
                    <PlanNotification
                      name={val.Firstname + " " + val.Lastname}
                      email={val.EmailId}
                      Type={val.TimeDuration}
                      refresh={getAdminNotifications}
                      id={val.Notification_id}
                      MobileNumber={val.Mobilenumber}
                      Receipt={val.Createdby}
                      Purchasedate={val.CreatedDate}
                    />
                  )}
                  {val.tableName === "mealplan" && temp?.Role === "Admin" && (
                    <MealApproveNotification
                      name={val.Firstname + " " + val.Lastname}
                      email={val.EmailId}
                      Type={val.Type}
                      refresh={getAdminNotifications}
                      id={val.Notification_id}
                      MobileNumber={val.Mobilenumber}
                      Receipt={"demo"}
                      client={temp?.Role === "Client"}
                      Purchasedate={val.CreatedDate}
                      Createdby={val.Createdby}
                    />
                  )}
                  {val.tableName === "registration" && (
                    <RegisterNotification
                      val={val}
                      refresh={getAdminNotifications}
                    />
                  )}
                  {val.tableName === "mealplan" && (
                    <MealPlanNotification
                      val={val}
                      refresh={getClientNotifications}
                      client={temp?.Role === "Client"}
                      date={val.Createddate}
                    />
                  )}
                  {val.tableName === "healthassessment" && (
                    <AssessmentNotification
                      val={val}
                      refresh={getAdminNotifications}
                      Purchasedate={val.CreatedDate}
                    />
                  )}
                  {val.tableName === "weightracker" && (
                    <TrackerNotification
                      tracker={"Weight"}
                      val={val}
                      refresh={getAdminNotifications}
                    />
                  )}
                  {val.tableName === "inchlosstracker" && (
                    <TrackerNotification
                      tracker={"Inch"}
                      val={val}
                      refresh={getAdminNotifications}
                    />
                  )}
                  {val.tableName === "Invoice" && (
                    <TrackerNotification
                      tracker={"Invoice"}
                      val={val}
                      refresh={getClientNotifications}
                      client={temp?.Role === "Client"}
                      date={val.Createddate}
                    />
                  )}
                  {val.tableName === "phototracker" && (
                    <TrackerNotification
                      tracker={"Photo"}
                      val={val}
                      refresh={getAdminNotifications}
                      client={temp?.Role === "Client"}
                    />
                  )}
                  {val.tableName === "reminder" && !val.isNotified && (
                    <ReminderNotification
                      id={val?.id}
                      notiList={notiList}
                      setNotiList={setNotiList}
                      isNotified={val.isNotified}
                      body={val.text}
                      val={val}
                      refresh={getAdminNotifications}
                      clientRefresh={getClientNotifications}
                      client={temp?.Role === "Client"}
                    />
                  )}
                </div>
              );
            })}
        </>
      ) : (
        <>No notifications to show</>
      )}
    </div>
  );
}

export default NotificationPanel;
