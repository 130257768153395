//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../assets/styles/PlansPage.module.css";
import bronzeCardImage from "../assets/images/bronzeCard.png";
import goldCardImage from "../assets/images/goldCard.png";
import silverCardImage from "../assets/images/silverCard.png";
import rupee from "../assets/images/rupee.png";
import arrow from "../assets/images/arrow.png";
import mostPopular from "../assets/images/mostPopular.png";
import popularMobile from "../assets/images/popularMobile.png";
import { useDispatch, useSelector } from "react-redux";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import PaymentModal from "./PaymentModal";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function PlansPage() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState(
    useSelector((state) => state.allData?.user_details)
  );
  const dispatch = useDispatch();
  const [planId, setPlanId] = useState();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //-------------------------------------------------------------------Get Login Details Function-----------------------------------------------------------------------------//

  function getLoginDetails() {
    Request.post(`${BASE_URL}login/loginDetails`, { Client_id: userDetails?.id }).then((res) => {
      let temp = userDetails;
      temp.PlanStatus = res?.data?.data[0]?.PlanStatus;
      temp.PlanType = res?.data?.data[0]?.Type;
      temp.PlanPurchasedate = res?.data?.data[0]?.Purchasedate;
      temp.PlanExpired = res?.data?.data[0]?.isExpired;
      temp.PlanStartDate = res?.data?.data[0]?.PlanStartDate;
      temp.Planid = res?.data?.data[0]?.Planid;
      temp.Logindate = res?.data?.data[0]?.Logindate;
      setUserDetails(temp);
      dispatch({ type: "USER_DETAILS", payload: temp });
      localStorage.setItem("user", JSON.stringify(temp));
    });
  }

  //-------------------------------------------------------------------Get Login Details Function-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------Check Purchase Plan Function-----------------------------------------------------------------------------//

  function purchasePlan(img1) {
    if (
      userDetails &&
      ((userDetails?.PlanExpired == 0 && userDetails?.PlanStatus == 1) ||
        userDetails?.PlanStatus == 2)
    ) {
      alert("A plan of yours is already active");
    } else {
      const data = new FormData();
      data.append("photo", img1);
      data.append("Client_id", userDetails?.id);
      data.append("Plan_id", planId);
      data.append("Emailid", userDetails?.Emailid);
      Request.post(`${BASE_URL}plans/purchasePlan`, data)
        .then((res) => {
          if (res?.data?.status === 200) {
            if (userDetails) {
              getLoginDetails();
              window.location.reload();
            }
          } else {
            alert("Error purchasing plan");
          }
        })
        .catch((err) => {
          console.log("Error sending request for plan purchase", err);
          alert("Error sending purchase plan request");
        });
    }
  }

  //-------------------------------------------------------------------Check Purchase Plan Function-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------USEffect to Get Login Details-----------------------------------------------------------------------------//

  useEffect(() => {
    if (!userDetails) {
      setUserDetails(JSON.parse(localStorage.getItem("user")));
    }

    if (userDetails) {
      getLoginDetails();
    }
  }, [userDetails]);

  //-------------------------------------------------------------------USEffect to Get Login Details-----------------------------------------------------------------------------//

  //--------------------------------------------------------------------UseEffect to dispatch current route---------------------------------------------------------------------------//

  useEffect(() => {
    dispatch({ type: "ROUTES", payload: "Plans" });
  }, []);

  //--------------------------------------------------------------------UseEffect to dispatch current route---------------------------------------------------------------------------//

  //--------------------------------------------------------------------UseEffect to set plan---------------------------------------------------------------------------//

  useEffect(() => {
    !showModal && setPlanId(-1);
  }, [showModal]);

  //--------------------------------------------------------------------UseEffect to set plan---------------------------------------------------------------------------//
  return (
    <div className={styles.sectionContainer}>
      {showModal && <PaymentModal purchasePlan={purchasePlan} setShowModal={setShowModal} />}
      <div className={styles.topHeadings}>
        <label className={styles.mainHeading}>
          {userDetails?.PlanExpired == 1
            ? "Your current plan is expired"
            : userDetails?.PlanStatus == 2
            ? "Your plan will be approved soon by the admin"
            : userDetails?.PlanStatus == 1 || userDetails?.PlanStatus == 4
            ? "Your plan is already active"
            : "Please select a plan"}
        </label>
        <label className={styles.mainHeading}>Choose Your Meal Plan</label>
      </div>
      <div className={styles.planCardsMain}>
        <div className={styles.bronzeCard}>
          <img src={bronzeCardImage} className={styles.topCardImage} alt=""></img>
          <div className={styles.mainContantCard}>
            <div className={styles.topContant}>
              <label className={styles.PlanHeading}>30 Days Program</label>
              <label className={styles.labelMargin}>
                <span>
                  <img src={rupee} alt="" className={styles.rupeeSize}></img>
                </span>
                3999/- <span className={styles.residentcls}>Indian Residents</span>
              </label>
              <label className={styles.labelMargin}>
                <span>
                  <img src={rupee} alt="" className={styles.rupeeSize}></img>
                </span>
                5999/- <span className={styles.residentcls}>Non Indian Resident</span>
              </label>
              {/* <label className={styles.topSmallContant}>Bronze</label> */}
            </div>
          </div>
          <div className={styles.detailContant}>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              1 plan = 15 days each
            </label>
            <label>
              {" "}
              <span>
                <img src={arrow} alt=""></img>
              </span>
              2 Meal Plans
            </label>{" "}
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              1 Video call consultation before meal planning.
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Personalized & Sustainable Plans{" "}
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Healthy Recipes
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Exercise Guidance
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Travel & Eat out guidance
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Daily monitoring & WhatsApp support
            </label>
            {/* <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Lorem ipsum dolor sit amet
            </label> */}
          </div>
          <div className={styles.btnwidset}>
            <button
              disabled={
                userDetails?.PlanExpired != 1 &&
                userDetails?.PlanStatus != null &&
                userDetails?.PlanStatus != 0
                  ? true
                  : false
              }
              className={
                userDetails?.PlanExpired != 1 &&
                userDetails?.PlanStatus != null &&
                userDetails?.PlanStatus != 0
                  ? styles.disableButton
                  : styles.currentButton
              }
              type="button"
              onClick={() => {
                setPlanId(1);
                setShowModal(true);
              }}
            >
              {userDetails?.PlanStatus != 0 && userDetails?.PlanType == "Bronze"
                ? "Current"
                : "Choose Plan"}
            </button>
          </div>
        </div>

        <div className={styles.mainGoldCard}>
          <img src={mostPopular} alt="" className={styles.mostPopularCard}></img>
          <img src={popularMobile} alt="" className={styles.mobilePopularCard}></img>
          <div className={styles.goldCard}>
            <img
              src={goldCardImage}
              className={styles.topCardImage + " " + styles.newstyleadded}
              alt=""
            ></img>

            <div className={styles.mainContantCard}>
              <div className={styles.topContant}>
                <label className={styles.PlanHeading}>60 Days Program</label>
                <label className={styles.labelMargin}>
                  <span>
                    <img src={rupee} alt="" className={styles.rupeeSize}></img>
                  </span>
                  5999/- <span className={styles.residentcls}>Indian Residents</span>
                </label>
                <label className={styles.labelMargin}>
                  <span>
                    <img src={rupee} alt="" className={styles.rupeeSize}></img>
                  </span>
                  8999/- <span className={styles.residentcls}>Non Indian Resident</span>
                </label>
                {/* <label className={styles.topSmallContant}>Bronze</label> */}
              </div>
            </div>
            <div className={styles.detailContant + " " + styles.addnewtransform}>
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                1 plan = 15 days each
              </label>
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                4 Meal Plans
              </label>
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                1 Video call consultation before meal planning.
              </label>
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                Personalized & Sustainable Plans{" "}
              </label>
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                Healthy Recipes
              </label>
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                Exercise Guidance
              </label>
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                Travel & Eat out guidance
              </label>{" "}
              <label>
                <span>
                  <img src={arrow} alt=""></img>
                </span>
                Daily monitoring & WhatsApp support
              </label>
              {/* <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Lorem ipsum dolor sit amet
            </label> */}
            </div>
            <div className={styles.goldButtonDiv}>
              <button
                disabled={
                  userDetails?.PlanExpired != 1 &&
                  userDetails?.PlanStatus != null &&
                  userDetails?.PlanStatus != 0
                    ? true
                    : false
                }
                className={
                  userDetails?.PlanExpired != 1 &&
                  userDetails?.PlanStatus != null &&
                  userDetails?.PlanStatus != 0
                    ? styles.disableButton
                    : styles.currentButton
                }
                id="goldButton"
                type="button"
                onClick={() => {
                  setPlanId(3);
                  setShowModal(true);
                }}
              >
                {userDetails?.PlanStatus != 0 && userDetails?.PlanType == "Silver"
                  ? "Current"
                  : "Choose Plan"}
              </button>
            </div>
          </div>
        </div>

        <div className={styles.silverCard}>
          <img src={silverCardImage} className={styles.topCardImage} alt=""></img>
          <div className={styles.mainContantCard}>
            <div className={styles.topContant}>
              <label className={styles.PlanHeading}>90 Days Program</label>
              <label className={styles.labelMargin}>
                <span>
                  <img src={rupee} alt="" className={styles.rupeeSize}></img>
                </span>
                9999/- <span className={styles.residentcls}>Indian Residents</span>
              </label>
              <label className={styles.labelMargin}>
                <span>
                  <img src={rupee} alt="" className={styles.rupeeSize}></img>
                </span>
                13999/- <span className={styles.residentcls}>Non Indian Resident</span>
              </label>
              {/* <label className={styles.topSmallContant}>Bronze</label> */}
            </div>
          </div>
          <div className={styles.detailContant}>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              1 plan = 15 days each
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              6 Meal Plans
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              1 Video call consultation before meal planning.
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Personalized & Sustainable Plans{" "}
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Healthy Recipes
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Exercise Guidance
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Travel & Eat out guidance
            </label>
            <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Daily monitoring & WhatsApp support
            </label>
            {/* <label><span><img src={arrow} alt=""></img></span>Terms & Conditions Apply</label> */}
            {/* <label>
              <span>
                <img src={arrow} alt=""></img>
              </span>
              Lorem ipsum dolor sit amet
            </label> */}
          </div>
          <div className={styles.btnwidset}>
            <button
              disabled={
                userDetails?.PlanExpired != 1 &&
                userDetails?.PlanStatus != null &&
                userDetails?.PlanStatus != 0
                  ? true
                  : false
              }
              className={
                userDetails?.PlanExpired != 1 &&
                userDetails?.PlanStatus != null &&
                userDetails?.PlanStatus != 0
                  ? styles.disableButton
                  : styles.currentButton
              }
              type="button"
              onClick={() => {
                setPlanId(2);
                setShowModal(true);
              }}
            >
              {userDetails?.PlanStatus != 0 && userDetails?.PlanType == "Gold"
                ? "Current"
                : "Choose Plan"}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.thenewflex}>
        <p className={styles.bluelabel}>If you think, “WELLNESS IS EXPENSIVE, TRY ILLNESS”</p>
        <div className={styles.thecolflex}>
          <p className={styles.redlabel}>Note:</p>
          <p className={styles.redlabel}>
            No Refund Policy. Price difference is due to the change in time zones and additional
            efforts required for search of local and regional food produce.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PlansPage;
