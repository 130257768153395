//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/styles/LoginPage.module.css";
import login_bg_right from "../assets/images/login_bg_right.png";
import login_bg_rightTabPhone from "../assets/images/login_bg_rightTabPhone.png";
import login_bg_rightPhone from "../assets/images/login_bg_rightPhone.png";
import login_bg_top from "../assets/images/login_bg_top.png";
import login_bg_bottom from "../assets/images/login_bg_bottom.png";
import login_bg_topTabPhone from "../assets/images/login_bg_topTabPhone.png";
import login_bg_bottomTabPhone from "../assets/images/login_bg_bottomTabPhone.png";
import login_bg_centre from "../assets/images/login_bg_centre.png";
import A_jain_logo from "../assets/images/A_Jain_logo.png";
import instagramHandle from "../assets/images/instagram.png";
import facebookHandle from "../assets/images/facebook.png";
import closedEye from "../assets/images/closedEye.png";
import openEye from "../assets/images/openEye.png";
import errorIcon from "../assets/images/error-icon.png";
import MediumPassword from "../assets/images/error_medium.png";
import strongPassword from "../assets/images/error_strong.png";
import Request from "axios";
import { BASE_URL } from "../components/baseURL";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ChangePassword() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [passVali, setPassVali] = useState();
  const [showPass, setShowPass] = useState(false);
  const [showPassconfirm, setShowPassconfirm] = useState(false);
  const currentPass = useRef();
  const pass = useRef();
  const navigation = useNavigate();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  // async function validate() {
  //   if (pass.current.value?.length < 1 || currentPass.current.value < 1) {
  //     alert("Fill all fields");
  //   } else if (pass.current.value === currentPass.current.value) {
  //     changePass();
  //   } else {
  //     alert("Fields doesn't match");
  //   }
  // }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function validate() {
    let error = false;
    let checklist = [
      { id: "currentPass", name: "errorMessageNewpassword", ref: currentPass, type: "password" },
      {
        id: "pass",
        name: "errorMessageConfirmPassword",
        ref: pass,
        type: "password",
      },
    ];
    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      let regrx = new RegExp(
        val.type === "password" ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i : ""
      );
      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else if (!regrx.test(val.ref.current.value)) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter Valid ${val.type}`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
    });
    if (currentPass.current.value !== pass.current.value) {
      error = true;
      let propStyle = document.getElementById("currentPass");
      let propText = document.getElementById("errorMessageConfirmPassword");
      propStyle.style.border = "2px solid red";
      propText.style.display = "block";
      propText.textContent = `Please Enter same password`;
    } else {
      let propStyle = document.getElementById("pass");
      let propText = document.getElementById("errorMessageConfirmPassword");
      propStyle.style.border = "1.5px solid #D9D9D9";
      propText.style.display = "none";
      propText.textContent = "none";
    }
    !error && changePass();
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  //---------------------------------------UseEffect to validate password----------------------------------------------------------------//

  useEffect(() => {
    // if (firstLoad) {
    if (passVali === "") {
      document.getElementById("passwordTooltipicn").style.display = "block";
      document.getElementById("mediumPasswordTooltipicn").style.display = "none";
      document.getElementById("strongPasswordTooltipicn").style.display = "none";
      document.getElementById("showPasswordid").style.right = "21px";
    } else {
      // if (firstLoad) {
      if (passVali == null) {
        document.getElementById("passwordTooltipicn").style.display = "block";
        document.getElementById("mediumPasswordTooltipicn").style.display = "none";
        document.getElementById("strongPasswordTooltipicn").style.display = "none";
        document.getElementById("showPasswordid").style.right = "1rem";
      } else {
        document.getElementById("passwordBlank").style.display = "none";
        document.getElementById("passwordTooltipicn").style.display = "none";
        document.getElementById("mediumPasswordTooltipicn").style.display = "none";
        document.getElementById("strongPasswordTooltipicn").style.display = "none";
        document.getElementById("passworderrorWeak").style.display = "none";
        document.getElementById("passworderrorMedium").style.display = "none";
        document.getElementById("passworderrorStrong").style.display = "none";
        document.getElementById("showPasswordid").style.right = "52px";
      }

      if (passVali?.length < 8 && passVali <= 18) {
        document.getElementById("passwordTooltipicn").style.display = "block";
        document.getElementById("mediumPasswordTooltipicn").style.display = "none";
        document.getElementById("strongPasswordTooltipicn").style.display = "none";
        document.getElementById("passwordBlank").style.display = "none";
        document.getElementById("passworderrorWeak").style.display = "block";
        document.getElementById("passworderrorMedium").style.display = "none";
        document.getElementById("passworderrorStrong").style.display = "none";
        document.getElementById("showPasswordid").style.right = "52px";
      } else if (
        (passVali?.search(/[a-z]/) < 0 && passVali?.search(/[A-Z]/) < 0) ||
        passVali?.search(/\d/) < 0 ||
        passVali?.search(/[^a-zA-Z\d]/) < 0
      ) {
        document.getElementById("passwordTooltipicn").style.display = "none";
        document.getElementById("mediumPasswordTooltipicn").style.display = "block";
        document.getElementById("strongPasswordTooltipicn").style.display = "none";
        document.getElementById("passwordBlank").style.display = "none";
        document.getElementById("passworderrorWeak").style.display = "none";
        document.getElementById("passworderrorMedium").style.display = "block";
        document.getElementById("passworderrorStrong").style.display = "none";
        document.getElementById("showPasswordid").style.right = "52px";
      } else if (
        (passVali?.match(/[a-z]/) && passVali?.match(/[A-Z]/)) ||
        passVali?.match(/\d/) ||
        passVali?.match(/[^a-zA-Z\d]/)
      ) {
        document.getElementById("passwordTooltipicn").style.display = "none";
        document.getElementById("mediumPasswordTooltipicn").style.display = "none";
        document.getElementById("strongPasswordTooltipicn").style.display = "block";
        document.getElementById("passwordBlank").style.display = "none";
        document.getElementById("passworderrorWeak").style.display = "none";
        document.getElementById("passworderrorMedium").style.display = "none";
        document.getElementById("passworderrorStrong").style.display = "block";
        document.getElementById("showPasswordid").style.right = "52px";
      }

      //  else {
      //   setFirstLoad(true);
      // }
      // }
    }
  }, [passVali]);

  //---------------------------------------UseEffect to validate password----------------------------------------------------------------//

  //---------------------------------------------------------Change Password Function-----------------------------------------------------------------------//

  async function changePass() {
    let link = window.location.href;
    link = link.substring(37).split(/([=,&])/);
    if (link?.length >= 6) {
      let auth = link[2];
      let id = link[6];
      Request.post(`${BASE_URL}client/resetpassword`, {
        Authtoken: auth,
        id: id,
        Password: pass.current.value,
      })
        .then((res) => {
          if (res?.data?.status === 200) {
            alert("Password updated");
            navigation("/");
          } else {
            console.log("Error reset password", res?.data);
          }
        })
        .catch((err) => {
          console.log("Error reset password", err);
        });
    }
    // navigation("/");
  }
  
  //---------------------------------------------------------Change Password Function-----------------------------------------------------------------------//

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftInputs}>
        <img className={styles.AJainLogo} src={A_jain_logo} alt="" />
        <label className={styles.signInText}>Reset Password</label>
        <label className={styles.belowSignInText}>Please reset your password</label>
        <div className={styles.inputCred + " " + styles.marginofbot}>
          <div className={styles.passwordWithEye}>
            <input
              type={showPass ? "text" : "password"}
              name="pwd"
              placeholder="New Password"
              ref={currentPass}
              id="currentPass"
              onChange={(text) => setPassVali(text?.target?.value)}
            />
            <button
              type="button"
              className={styles.showPasswd}
              id="showPasswordid"
              onClick={() => {
                setShowPass(() => !showPass);
              }}
            >
              <img src={showPass ? openEye : closedEye} alt="" />
            </button>
            <div
              className={styles.tooltipinput + " " + styles.passwordTooltip}
              id="passwordTooltip"
            >
              <img
                className={styles.cvvinputicncls}
                src={errorIcon}
                alt=""
                id="passwordTooltipicn"
                style={{ display: "none" }}
              />
              <img
                className={styles.cvvinputicncls}
                src={MediumPassword}
                alt=""
                id="mediumPasswordTooltipicn"
                style={{ display: "none" }}
              />
              <img
                className={styles.cvvinputicncls}
                src={strongPassword}
                alt=""
                id="strongPasswordTooltipicn"
                style={{ display: "none" }}
              />
              <label
                className={styles.tooltiptextinput + " " + styles.passwordBlankcls}
                style={{ display: "none" }}
                id="passwordBlank"
              >
                Please enter Password.
              </label>
              <label
                className={styles.tooltiptextinput + " " + styles.passworderrorWeakcls}
                id="passworderrorWeak"
                style={{ display: "none" }}
              >
                . Weak: (should have at least 8 character long.)
              </label>
              <label
                className={styles.tooltiptextinput + " " + styles.passworderrorMediumcls}
                id="passworderrorMedium"
                style={{ display: "none" }}
              >
                . Medium: (Password must have at least one alphabet, number and special character).
              </label>
              <label
                className={styles.tooltiptextinput + " " + styles.passworderrorStrongcls}
                id="passworderrorStrong"
                style={{ display: "none" }}
              >
                . Strong Password.
              </label>
            </div>
          </div>
          <span className={styles.errorSpan} id="errorMessageNewpassword"></span>
          <div className={styles.passwordWithEye}>
            <input
              type={showPassconfirm ? "text" : "password"}
              name="pwd"
              placeholder="Confirm Password"
              ref={pass}
              id="pass"
            />
            <button
              type="button"
              className={styles.showPasswd}
              onClick={() => {
                setShowPassconfirm(() => !showPassconfirm);
              }}
            >
              <img src={showPassconfirm ? openEye : closedEye} alt="" />
            </button>
          </div>
          <span className={styles.errorSpan} id="errorMessageConfirmPassword"></span>
        <button className={styles.signInButton} onClick={validate}>
          Reset Password
        </button>
        </div>


        <div className={styles.followUsTextAndTag}>
          <div className={styles.followUsText}>
            <label>Follow Us</label>
          </div>
          <div className={styles.followUsTag}>
            <a
              href="https://instagram.com/dt.arpita_healvick?igshid=NGVhN2U2NjQ0Yg=="
              target="_blank"
            >
              <img src={instagramHandle} alt="" />
            </a>
            <a href="https://www.facebook.com/healvick?mibextid=hrBMPu" target="_blank">
              <img src={facebookHandle} alt="" />
            </a>
            {/* <img src={twitterHandle} alt="" /> */}
          </div>
        </div>
      </div>
      <div className={styles.rightImages}>
        <div className={styles.rightImagesInsideParentOne}>
          <div className={styles.rightImagesInsideChildOne}>
            <img className={styles.loginBgTop} src={login_bg_top} alt="" />
            <img className={styles.loginBgBottom} src={login_bg_bottom} alt="" />
          </div>
          <img className={styles.loginBgRight} src={login_bg_right} alt="" />
        </div>
        <div className={styles.rightImagesInsideParentTwo}>
          <img className={styles.loginBgCentre} src={login_bg_centre} alt="" />
        </div>
      </div>
      <div className={styles.rightImagesTabPhone}>
        <div className={styles.rightImagesInsideParentOneTabPhone}>
          <img className={styles.loginBgRightTabPhone} src={login_bg_rightTabPhone} alt="" />
          <img className={styles.loginBgRightPhone} src={login_bg_rightPhone} alt="" />
          <div className={styles.rightImagesInsideParentTwoTabPhone}>
            <img className={styles.loginBgCentreTabPhone} src={login_bg_centre} alt="" />
          </div>
          <div className={styles.rightImagesInsideChildOneTabPhone}>
            <img className={styles.loginBgTopTabPhone} src={login_bg_topTabPhone} alt="" />
            <img className={styles.loginBgBottomTabPhone} src={login_bg_bottomTabPhone} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChangePassword;
