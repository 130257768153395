//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useRef, useEffect, useState } from "react";
import styles from "../assets/styles/weightTrackerModal.module.css";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import style from "../assets/styles/Invoice.module.css";
import bootstrap from "../assets/bootstrapStyles/bootstrap.min.module.css";
import ClientDropdown from "./ClientDropdown";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Invoice() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const DateField = useRef();
  const Invoiceno = useRef();
  const Duration = useRef();
  const Discount = useRef();
  const Amount = useRef();
  const navigation = useNavigate();
  const [clientNames, setClientNames] = useState([]);
  const [mask, setMask] = useState();
  const [selectedName, setSelectedName] = useState("Select a client");
  const dispatch = useDispatch();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let error = false;
    let checklist = [{ id: "amount", name: "errorMessageamount", ref: Amount }];

    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
    });
    !error && addInvoice();
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to get Client Names-----------------------------------------------------------------------//

  useEffect(() => {
    Request.get(`${BASE_URL}client/getClientsName`)
      .then((res) => {
        if (res?.data?.status === 200) {
          setClientNames(res?.data?.data);
        } else {
          alert("Error fetching client names", res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("Error fetching client names: ", err);  
      });
  }, []);
  console.log("SelectionInside", selectedName);

  //---------------------------------------------------------UseEffect to get Client Names-----------------------------------------------------------------------//

  //---------------------------------------------------------Add Invoice Function-----------------------------------------------------------------------//

  function addInvoice() {
    let Total =
      (1 - parseFloat(Discount?.current?.value) / 100) *
      parseFloat(Amount?.current?.value).toFixed(3);
    Request.post(`${BASE_URL}plans/addInvoice`, {
      Date: DateField?.current?.value,
      Invoiceno: Invoiceno?.current?.value,
      // Services: Services?.current?.value,
      Duration: Duration?.current?.value,
      Discount: Discount?.current?.value,
      Amount: Amount?.current?.value,
      Total: Total,
      Client_id: selectedName?.id,
      Planhistory_id: selectedName?.planHistoryId,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("addInvoice data added!", Total);
          DateField.current.value = "";
          Invoiceno.current.value = "";
          // Services.current.value = "";
          Duration.current.value = "";
          Discount.current.value = "";
          Amount.current.value = "";
          setSelectedName();
        } else {
          alert("Error addding inchloss tracking");
          console.log("Error adding addInvoice data", res?.data?.message);
        }
      })
      .catch((err) => {
        console.error("Error adding addInvoice data", err);
      });
  }

  //---------------------------------------------------------Add Invoice Function-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

  useEffect(() => {
    document.getElementById("date").valueAsDate = new Date();
    if (selectedName !== "Select a client") {
      document.getElementById("emailinput").value = selectedName?.EmailId;
    }
    if (!selectedName?.planHistoryId) {
      DateField.current.value = "";
      // Services.current.value = "";
      Invoiceno.current.value = "";
      Duration.current.value = "";
      Discount.current.value = "";
      Amount.current.value = "";
    }
  }, [selectedName]);
  
  //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to dispatch current route-----------------------------------------------------------------------//

  useEffect(() => {
    dispatch({ type: "ROUTES", payload: "Invoice" });
  }, []);

  //---------------------------------------------------------UseEffect to dispatch current route-----------------------------------------------------------------------//
  return (
    <div className={style.maindiv}>
      <div className={style.widset}>
        <div>
          <p className={style.billtag}>Create Bill</p>
        </div>
        <div className={bootstrap["bootstrap-iso"] + " " + style.mainbtflex}>
          <div className={bootstrap["form-row"] + " " + style.theflex}>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Client Name <span className={style.redlabel}>*</span>
              </label>
              <ClientDropdown
                setMask={setMask}
                mask={mask}
                data={clientNames}
                selectedItem={selectedName}
                setSelectedItem={setSelectedName}
                bill={true}
              />
            </div>
            <div
              className={
                bootstrap[("form-group", "col-md-6")] +
                " " +
                style.btflex +
                " " +
                style.mobileMargin
              }
            >
              <label className={style.blacklabel + " " + style.marginofeight}>
                Email ID <span className={style.redlabel}>*</span>
              </label>
              <input
                id="emailinput"
                className={
                  bootstrap[("form-control", "inputfld")] +
                  " " +
                  style.fontst +
                  " " +
                  style.readonlycolor
                }
                readOnly
                placeholder="Email ID"
              ></input>
            </div>
          </div>
          <div className={bootstrap["form-row"] + " " + style.theflex}>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Date <span className={style.redlabel}>*</span>
              </label>
              <input
                ref={DateField}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Date"
                type="date"
                id="date"
              ></input>
              <span className={styles.errorSpan} id="errorMessageDate"></span>
            </div>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Invoice No. <span className={style.redlabel}>*</span>
              </label>
              <input
                value={Date.now()}
                ref={Invoiceno}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Invoice No."
              ></input>
              <span className={styles.errorSpan} id="errorMessageFirst"></span>
            </div>
          </div>
          <div className={bootstrap["form-row"] + " " + style.theflex} style={{ width: "100%" }}>
            {/* <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Program/ Services <span className={style.redlabel}>*</span>
              </label>
              <input
                value={selectedName?.type ? selectedName?.type : Services?.current?.value}
                // onChange={(text)=>{Services?.current?.value=text}}
                ref={Services}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Services"
              ></input>
              <span className={styles.errorSpan} id="errorMessageFirst"></span>
            </div> */}
            <div
              className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}
              style={{ width: "100%" }}
            >
              <label className={style.blacklabel + " " + style.marginofeight}>
                Duration <span className={style.redlabel}>*</span>
              </label>
              <input
                value={selectedName?.Timeduration ? selectedName?.Timeduration + " program" : ""}
                ref={Duration}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Duration"
              ></input>
              <span className={styles.errorSpan} id="errorMessageFirst"></span>
            </div>
          </div>
          <div className={bootstrap["form-row"] + " " + style.theflex}>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Discount<span className={style.redlabelif}> (if any)</span>
              </label>
              <input
                type="number"
                ref={Discount}
                className={bootstrap[("form-control", "inputfld")] + " " + style.fontst}
                placeholder="Discount"
              ></input>
              <span className={styles.errorSpan} id="errorMessageFirst"></span>
            </div>
            <div className={bootstrap[("form-group", "col-md-6")] + " " + style.btflex}>
              <label className={style.blacklabel + " " + style.marginofeight}>
                Total Amount <span className={style.redlabel}>*</span>
              </label>
              <input
                type="number"
                ref={Amount}
                className={bootstrap[("form-control", "inputfld")] + " " + style.amountcls}
                placeholder="Amount"
                id="amount"
                required
              ></input>
              <span className={styles.errorSpan} id="errorMessageamount"></span>
            </div>
          </div>
        </div>
        <div className={style.theflexend}>
          <button
            className={style.updatebtn}
            onClick={() => {
              if (selectedName !== "Select a client") {
                Validate();
              } else {
                alert("Please select a client first!");
              }
            }}
          >
            {" "}
            Submit{" "}
          </button>
          <button
            className={style.cancelbtn}
            onClick={() => {
              navigation("/admindashboard");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
