//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA4 = forwardRef(
  (
    {
      preferredCuisine,
      smoking,
      smokingOther,
      alcohol,
      alcoholOther,
      frequencyOfAlcohol,
      preferredAlcohol,
      waterIntake,
      religiousRestrictions,
      fasting,
      foodAllergies,
      foodDislikes,
      setPageIndex,
      adminData,
      profileData,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [smokingSet, setSmoke] = useState("Yes");
    const [alcoholSet, setAlcohol] = useState("Yes");
    const [alcoholfrequencySet, setfrequencyAlcohol] = useState("Yes");
    const [waterIntakeSet, setwaterIntake] = useState("Yes");
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [
        { id: "preferredCuisine", name: "errorMessagepreferredCuisine", ref: preferredCuisine },
        {
          id: "religiousRestrictions",
          name: "errorMessagereligiousRestrictions",
          ref: religiousRestrictions,
        },
        { id: "fasting", name: "errorMessagefasting", ref: fasting },
        { id: "foodAllergies", name: "errorMessagefoodAllergies", ref: foodAllergies },
        { id: "foodDislikes", name: "errorMessagefoodDislikes", ref: foodDislikes },
      ];
      let erId4=-1
      checklist.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);
        let regrx = new RegExp(
          val.type === "email"
            ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            : val.type === "password"
            ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
            : val.type === "number"
            ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
            : ""
        );
        if (val.ref?.current?.value?.length < 1) {
          if(erId4==-1){
            erId4=val.id
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
          document.getElementById(val.id).focus();
        } else if (!regrx.test(val.ref?.current?.value)) {
          if(erId4==-1){
            erId4=val.id
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter Valid ${val.type}`;
        } else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }if(erId4!=-1){
          document.getElementById(erId4).focus();
        }
        if (document.getElementById("otherSmoking").checked) {
          if (smokingOther.current.value?.length < 1) {
            error = true;
            document.getElementById("otherSmokingField").style.border = "2px solid red";
            document.getElementById("errorMessagesmokingOther").style.display = "block";
            document.getElementById(
              "errorMessagesmokingOther"
            ).textContent = `Please Enter this field`;
          } else {
            document.getElementById("otherSmokingField").style.border = "1.5px solid #D9D9D9";
            document.getElementById("errorMessagesmokingOther").style.display = "none";
            document.getElementById("errorMessagesmokingOther").textContent = `none`;
          }
        }

        if (document.getElementById("alcoholOther").checked) {
          if (alcoholOther.current.value?.length < 1) {
            error = true;
            document.getElementById("alchoholOtherField").style.border = "2px solid red";
            document.getElementById("errorMessagesalcoholOther").style.display = "block";
            document.getElementById(
              "errorMessagesalcoholOther"
            ).textContent = `Please Enter this field`;
          } else {
            document.getElementById("alchoholOtherField").style.border = "1.5px solid #D9D9D9";
            document.getElementById("errorMessagesalcoholOther").style.display = "none";
            document.getElementById("errorMessagesalcoholOther").textContent = `none`;
          }
        }
      });
      !error && setPageIndex(5);
    }
    
    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//
    
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      setwaterIntake("< 1 Liter");
      waterIntake.current.value = "< 1 Liter";
      setSmoke("Yes");
      smoking.current.value = "Yes";
      setAlcohol("Yes");
      alcohol.current.value = "Yes";
      let cache = JSON.parse(localStorage.getItem("CA4"));
      let data = adminData ? adminData : profileData;
      if (adminData) {
        preferredCuisine.current.value = data.Preferredcuisine;
        if (data.Smoking == "Yes") {
          setSmoke("Yes");
          smoking.current.value = "Yes";
        } else if (data.Smoking == "No") {
          setSmoke("No");
          smoking.current.value = "No";
        } else if (data.Smoking == "Occasionally") {
          setSmoke("Occasionally");
          smoking.current.value = "Occasionally";
        } else {
          setSmoke("Other");
          smoking.current.value = "Other";
          smokingOther.current.value = data.Smoking;
        }

        if (data.Alcohol == "Yes") {
          setAlcohol("Yes");
          alcohol.current.value = "Yes";
        } else if (data.Alcohol == "No") {
          setfrequencyAlcohol();
          frequencyOfAlcohol.current.value = "";
          setAlcohol("No");
          alcohol.current.value = "No";
        } else if (data.Alcohol == "Occasionally") {
          setAlcohol("Occasionally");
          alcohol.current.value = "Occasionally";
        } else {
          setAlcohol("Other");
          alcohol.current.value = "Other";
          alcoholOther.current.value = data.Alcohol;
        }
        if (data.Alcoholconsumption == "Daily") {
          setfrequencyAlcohol("Daily");
          frequencyOfAlcohol.current.value = "Daily";
        } else if (data.Alcoholconsumption == "Once a Week") {
          setfrequencyAlcohol("Once a Week");
          frequencyOfAlcohol.current.value = "Once a Week";
        } else if (data.Alcoholconsumption == "Once a Month") {
          setfrequencyAlcohol("Once a Month");
          frequencyOfAlcohol.current.value = "Once a Month";
        } else if (data.Alcoholconsumption == "Occasionally") {
          setfrequencyAlcohol("Occasionally");
          frequencyOfAlcohol.current.value = "Occasionally";
        }
        if (data.Waterintake == "< 1 Liter") {
          setwaterIntake("< 1 Liter");
          waterIntake.current.value = "< 1 Liter";
        } else if (data.Waterintake == "1-2 Liters") {
          setwaterIntake("1-2 Liters");
          waterIntake.current.value = "1-2 Liters";
        } else if (data.Waterintake == "2-3 Liters") {
          setwaterIntake("2-3 Liters");
          waterIntake.current.value = "2-3 Liters";
        } else if (data.Waterintake == "Occasionally") {
          setwaterIntake("Occasionally");
          waterIntake.current.value = "Occasionally";
        } else if (data.Waterintake == "More than 3 Liters") {
          setwaterIntake("More than 3 Liters");
          waterIntake.current.value = "More than 3 Liters";
        }

        preferredAlcohol.current.value = data.Preferredalcohol;

        // waterIntake.current.value = data.Waterintake;

        religiousRestrictions.current.value = data.Religiousrestrictions;
        fasting.current.value = data.Fastingfrequency;
        foodAllergies.current.value = data.Foodallergies;
        foodDislikes.current.value = data.Fooddislike;
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        preferredCuisine.current.value = data.Preferredcuisine;
        if (data.Smoking == "Yes") {
          setSmoke("Yes");
          smoking.current.value = "Yes";
        } else if (data.Smoking == "No") {
          setSmoke("No");
          smoking.current.value = "No";
        } else if (data.Smoking == "Occasionally") {
          setSmoke("Occasionally");
          smoking.current.value = "Occasionally";
        } else {
          setSmoke("Other");
          smoking.current.value = "Other";
          smokingOther.current.value = data.Smoking;
        }

        if (data.Alcohol == "Yes") {
          setAlcohol("Yes");
          alcohol.current.value = "Yes";
        } else if (data.Alcohol == "No") {
          setfrequencyAlcohol();
          frequencyOfAlcohol.current.value = "";
          setAlcohol("No");
          alcohol.current.value = "No";
        } else if (data.Alcohol == "Occasionally") {
          setAlcohol("Occasionally");
          alcohol.current.value = "Occasionally";
        } else {
          setAlcohol("Other");
          alcohol.current.value = "Other";
          alcoholOther.current.value = data.Alcohol;
        }
        if (data.Alcoholconsumption == "Daily") {
          setfrequencyAlcohol("Daily");
          frequencyOfAlcohol.current.value = "Daily";
        } else if (data.Alcoholconsumption == "Once a Week") {
          setfrequencyAlcohol("Once a Week");
          frequencyOfAlcohol.current.value = "Once a Week";
        } else if (data.Alcoholconsumption == "Once a Month") {
          setfrequencyAlcohol("Once a Month");
          frequencyOfAlcohol.current.value = "Once a Month";
        } else if (data.Alcoholconsumption == "Occasionally") {
          setfrequencyAlcohol("Occasionally");
          frequencyOfAlcohol.current.value = "Occasionally";
        }
        if (data.Waterintake == "< 1 Liter") {
          setwaterIntake("< 1 Liter");
          waterIntake.current.value = "< 1 Liter";
        } else if (data.Waterintake == "1-2 Liters") {
          setwaterIntake("1-2 Liters");
          waterIntake.current.value = "1-2 Liters";
        } else if (data.Waterintake == "2-3 Liters") {
          setwaterIntake("2-3 Liters");
          waterIntake.current.value = "2-3 Liters";
        } else if (data.Waterintake == "Occasionally") {
          setwaterIntake("Occasionally");
          waterIntake.current.value = "Occasionally";
        } else if (data.Waterintake == "More than 3 Liters") {
          setwaterIntake("More than 3 Liters");
          waterIntake.current.value = "More than 3 Liters";
        }

        preferredAlcohol.current.value = data.Preferredalcohol;

        // waterIntake.current.value = data.Waterintake;

        religiousRestrictions.current.value = data.Religiousrestrictions;
        fasting.current.value = data.Fastingfrequency;
        foodAllergies.current.value = data.Foodallergies;
        foodDislikes.current.value = data.Fooddislike;
      }
    }, []);

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      preferredCuisine.current.value = cache.Preferredcuisine;

      if (cache.Smoking == "Yes") {
        setSmoke("Yes");
        smoking.current.value = "Yes";
      } else if (cache.Smoking == "No") {
        setSmoke("No");
        smoking.current.value = "No";
      } else if (cache.Smoking == "Occasionally") {
        setSmoke("Occasionally");
        smoking.current.value = "Occasionally";
      } else {
        setSmoke("Other");
        smoking.current.value = "Other";
        smokingOther.current.value = cache.Smoking;
      }

      if (cache.Alcohol == "Yes") {
        setAlcohol("Yes");
        alcohol.current.value = "Yes";
      } else if (cache.Alcohol == "No") {
        setfrequencyAlcohol();
        setAlcohol("No");
        alcohol.current.value = "No";
      } else if (cache.Alcohol == "Occasionally") {
        setAlcohol("Occasionally");
        alcohol.current.value = "Occasionally";
      } else {
        setAlcohol("Other");
        alcohol.current.value = "Other";
        alcoholOther.current.value = cache.Alcohol;
      }

      // alcohol.current.value = cache.Alcohol;
      // alcoholOther.current.value = cache.City;
      // frequencyOfAlcohol.current.value = cache.Alcoholconsumption;

      if (cache.Alcoholconsumption == "Daily") {
        setfrequencyAlcohol("Daily");
        frequencyOfAlcohol.current.value = "Daily";
      } else if (cache.Alcoholconsumption == "Once a Week") {
        setfrequencyAlcohol("Once a Week");
        frequencyOfAlcohol.current.value = "Once a Week";
      } else if (cache.Alcoholconsumption == "Once a Month") {
        setfrequencyAlcohol("Once a Month");
        frequencyOfAlcohol.current.value = "Once a Month";
      } else if (cache.Alcoholconsumption == "Occasionally") {
        setfrequencyAlcohol("Occasionally");
        frequencyOfAlcohol.current.value = "Occasionally";
      }

      preferredAlcohol.current.value = cache.Preferredalcohol;

      // waterIntake.current.value = cache.Waterintake;

      if (cache.Waterintake == "< 1 Liter") {
        setwaterIntake("< 1 Liter");
        waterIntake.current.value = "< 1 Liter";
      } else if (cache.Waterintake == "1-2 Liters") {
        setwaterIntake("1-2 Liters");
        waterIntake.current.value = "1-2 Liters";
      } else if (cache.Waterintake == "2-3 Liters") {
        setwaterIntake("2-3 Liters");
        waterIntake.current.value = "2-3 Liters";
      } else if (cache.Waterintake == "Occasionally") {
        setwaterIntake("Occasionally");
        waterIntake.current.value = "Occasionally";
      } else if (cache.Waterintake == "More than 3 Liters") {
        setwaterIntake("More than 3 Liters");
        waterIntake.current.value = "More than 3 Liters";
      }

      religiousRestrictions.current.value = cache.Religiousrestrictions;
      fasting.current.value = cache.Fastingfrequency;
      foodAllergies.current.value = cache.Foodallergies;
      foodDislikes.current.value = cache.Fooddislike;
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.additionalInformationOneForm}>
        <label className={style.additionalInformationOneLabel}>Additional Information</label>
        <div className={style.firstRowOfAI2 + " " + style.maroftop} id={style.hideafteronefour}>
          <div className={style.preferredCuisineInput}>
            <label>
              Preferred Cuisine (LIKES)<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={preferredCuisine}
              type="text"
              placeholder="Preferred Cuisine"
              id="preferredCuisine"
            />
            <span className={style.errorSpan} id="errorMessagepreferredCuisine"></span>
          </div>
          <div className={style.workTravelInput}>
            <label ref={smoking}>
              Smoking<span> *</span>
            </label>
            <div className={style.workTravelDiv}>
              <div className={style.workTravelYes}>
                <input
                  readOnly={adminData ? true : false}
                  id="smokingyesradio"
                  onChange={() => {
                    setSmoke("Yes");
                    smoking.current.value = "Yes";
                  }}
                  type="radio"
                  name="workTravelSelection"
                  checked={smokingSet == "Yes" ? true : false}
                />
                <label htmlFor="smokingyesradio">Yes</label>
              </div>
              <div className={style.workTravelNo}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    setSmoke("No");
                    smoking.current.value = "No";
                  }}
                  type="radio"
                  name="workTravelSelection"
                  id="smokingnoradio"
                  checked={smokingSet == "No" ? true : false}
                />
                <label htmlFor="smokingnoradio">No</label>
              </div>
              <div className={style.workTravelOccasionally}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    setSmoke("Occasionally");
                    smoking.current.value = "Occasionally";
                  }}
                  type="radio"
                  name="workTravelSelection"
                  id="occasionallysmokingradio"
                  checked={smokingSet == "Occasionally" ? true : false}
                />
                <label htmlFor="occasionallysmokingradio">Occasionally</label>
              </div>
              <div className={style.workTravelOther}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    setSmoke("Other");
                    smoking.current.value = "Other";
                  }}
                  type="radio"
                  name="workTravelSelection"
                  id="otherSmoking"
                  checked={smokingSet == "Other" ? true : false}
                />
                <label htmlFor="otherSmoking">Other</label>
              </div>
            </div>
          </div>
          <div className={style.smokingOtherInput + " " + style.maroftop}>
            <label>Other</label>
            <input
              readOnly={adminData ? true : false}
              ref={smokingOther}
              type="text"
              placeholder="Other"
              id="otherSmokingField"
            />
            <span className={style.errorSpan} id="errorMessagesmokingOther"></span>
          </div>
        </div>

        <div className={style.secondRowOfAI2}>
          <div className={style.alcoholRadioInput}>
            <label ref={alcohol}>
              {" "}
              Alcohol<span> *</span>{" "}
            </label>
            <div className={style.alcoholRadioDiv}>
              <div className={style.alcoholRadioYes}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    setAlcohol("Yes");
                    alcohol.current.value = "Yes";
                  }}
                  type="radio"
                  name="alcoholRadioSelection"
                  id="alcoholyesradio"
                  checked={alcoholSet == "Yes" ? true : false}
                />
                <label htmlFor="alcoholyesradio">Yes</label>
              </div>
              <div className={style.alcoholRadioNo}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    setAlcohol("No");
                    alcohol.current.value = "No";
                    setfrequencyAlcohol();
                  }}
                  type="radio"
                  name="alcoholRadioSelection"
                  id="alcoholnoradio"
                  checked={alcoholSet == "No" ? true : false}
                />
                <label htmlFor="alcoholnoradio">No</label>
              </div>
              <div className={style.alcoholRadioOccasional}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    setAlcohol("Occasionally");
                    alcohol.current.value = "Occasionally";
                  }}
                  type="radio"
                  name="alcoholRadioSelection"
                  id="alcoholoccasionallyradio"
                  checked={alcoholSet == "Occasionally" ? true : false}
                />
                <label htmlFor="alcoholoccasionallyradio">Occasionally</label>
              </div>
              <div className={style.alcoholRadioOther}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    setAlcohol("Other");
                    alcohol.current.value = "Other";
                  }}
                  type="radio"
                  name="alcoholRadioSelection"
                  id="alcoholOther"
                  checked={alcoholSet == "Other" ? true : false}
                />
                <label htmlFor="alcoholOther">Other</label>
              </div>
            </div>
          </div>
          <div className={style.alcoholOtherInput}>
            <label>Other</label>
            <input
              readOnly={adminData ? true : false}
              ref={alcoholOther}
              type="text"
              placeholder="Other"
              id="alchoholOtherField"
            />
            <span className={style.errorSpan} id="errorMessagesalcoholOther"></span>
          </div>
        </div>
        <div className={style.thirdRowOfAI2}>
          <div className={style.alcoholConsumptionRadioInput}>
            <label ref={frequencyOfAlcohol}>Frequency of Alcohol Consumption</label>
            <div className={style.alcoholConsumptionRadioDiv}>
              <div className={style.alcoholConsumptionRadioDaily}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    frequencyOfAlcohol.current.value = "Daily";
                    setfrequencyAlcohol("Daily");
                  }}
                  type="radio"
                  name="alcoholConsumptionRadioSelection"
                  id="dailyfrequencyradio"
                  checked={alcoholfrequencySet == "Daily" ? true : false}
                />
                <label htmlFor="dailyfrequencyradio">Daily</label>
              </div>
              <div className={style.alcoholConsumptionRadioWeek}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    frequencyOfAlcohol.current.value = "Once a Week";
                    setfrequencyAlcohol("Once a Week");
                  }}
                  type="radio"
                  name="alcoholConsumptionRadioSelection"
                  id="weekfrequencyradio"
                  checked={alcoholfrequencySet == "Once a Week" ? true : false}
                />
                <label htmlFor="weekfrequencyradio">Once a Week</label>
              </div>
              <div className={style.alcoholConsumptionRadioMonth}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    frequencyOfAlcohol.current.value = "Once a Month";
                    setfrequencyAlcohol("Once a Month");
                  }}
                  type="radio"
                  name="alcoholConsumptionRadioSelection"
                  id="monthfrequencyradio"
                  checked={alcoholfrequencySet == "Once a Month" ? true : false}
                />
                <label htmlFor="monthfrequencyradio">Once a Month</label>
              </div>
              <div className={style.alcoholConsumptionRadioOccasional}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    frequencyOfAlcohol.current.value = "Occasionally";
                    setfrequencyAlcohol("Occasionally");
                  }}
                  type="radio"
                  name="alcoholConsumptionRadioSelection"
                  id="Occasionallyfrequencyradio"
                  checked={alcoholfrequencySet == "Occasionally" ? true : false}
                />
                <label htmlFor="Occasionallyfrequencyradio">Occasionally</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.foruthRowOfAI2}>
          <div className={style.alcoholChoiceInput}>
            <label>Preferred Drinks in Alcohol</label>
            <input
              readOnly={adminData ? true : false}
              ref={preferredAlcohol}
              type="text"
              placeholder="Preferred Drinks in Alcohol"
            />
          </div>

          <div className={style.waterIntakeRadioInput}>
            <label ref={waterIntake}>
              Water Intake<span> *</span>
            </label>
            <div className={style.waterIntakeRadioDiv}>
              <div className={style.waterIntakeRadioOne}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    waterIntake.current.value = "< 1 Liter";
                    setwaterIntake("< 1 Liter");
                  }}
                  type="radio"
                  name="waterIntakeRadioSelection"
                  checked={waterIntakeSet == "< 1 Liter" ? true : false}
                  id="oneltrwater"
                />
                <label htmlFor="oneltrwater"> &gt; 1 Liter </label>
              </div>
              <div className={style.waterIntakeRadioTwo}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    waterIntake.current.value = "1-2 Liters";
                    setwaterIntake("1-2 Liters");
                  }}
                  type="radio"
                  name="waterIntakeRadioSelection"
                  id="onetwoltrwater"
                  checked={waterIntakeSet == "1-2 Liters" ? true : false}
                />
                <label htmlFor="onetwoltrwater">1-2 Liters</label>
              </div>
              <div className={style.waterIntakeRadioThree}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    waterIntake.current.value = "2-3 Liters";
                    setwaterIntake("2-3 Liters");
                  }}
                  type="radio"
                  name="waterIntakeRadioSelection"
                  id="twothreeltrwater"
                  checked={waterIntakeSet == "2-3 Liters" ? true : false}
                />
                <label htmlFor="twothreeltrwater">2-3 Liters </label>
              </div>
              <div className={style.waterIntakeRadioMore}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    waterIntake.current.value = "More than 3 Liters";
                    setwaterIntake("More than 3 Liters");
                  }}
                  type="radio"
                  name="waterIntakeRadioSelection"
                  id="grtthreeltrwater"
                  checked={waterIntakeSet == "More than 3 Liters" ? true : false}
                />
                <label htmlFor="grtthreeltrwater">More than 3 Liters</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.fifthRowOfAI2}>
          <div className={style.religiousRestrictionsInput}>
            <label>
              Any Religious Restrictions<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={religiousRestrictions}
              type="text"
              placeholder="Any Religious Restrictions"
              id="religiousRestrictions"
            />
            <span className={style.errorSpan} id="errorMessagereligiousRestrictions"></span>
          </div>
          <div className={style.fastingInput}>
            <label>
              Frequency of Fasting?<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={fasting}
              type="text"
              placeholder="Frequency of Fasting"
              id="fasting"
            />
            <span className={style.errorSpan} id="errorMessagefasting"></span>
          </div>
        </div>
        <div className={style.sixthRowOfAI2}>
          <div className={style.foodAllergiesInput}>
            <label>
              Food Allergies<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={foodAllergies}
              type="text"
              placeholder="Food Allergies"
              id="foodAllergies"
            />
            <span className={style.errorSpan} id="errorMessagefoodAllergies"></span>
          </div>
          <div className={style.foodDislikesInput}>
            <label>
              Food Dislikes<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={foodDislikes}
              type="text"
              placeholder="Food Dislikes"
              id="foodDislikes"
            />
            <span className={style.errorSpan} id="errorMessagefoodDislikes"></span>
          </div>
        </div>
      </div>
    );
  }
);

export default CA4;
