import React, { useEffect } from "react";
import LoginPage from "../pages/LoginPage";
import { Route, Routes, useLocation } from "react-router";
import ErrorPage from "../pages/ErrorPage";
import ClientDashboard from "../pages/client/ClientDashboard";
import AdminDashboard from "../pages/admin/AdminDashboard";
import ChangePassword from "../pages/ChangePassword";
import PlansPage from "./PlansPage";
import SignupPage from "../pages/client/SignupPage";
import ManageClients from "../pages/admin/ManageClients";
import NewMealPlan from "../pages/admin/NewMealPlanPage";
import ClientAssessmentForm from "../pages/client/ClientAssessmentForm";
import Parent from "./Parent";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import Planpdf from "./Planpdf";
import InvoicePdf from "./InvoicePdf";
import Invoice from "./Invoice";
import ForgotPassword from "../pages/ForgotPasswordPage";
import { useDispatch } from "react-redux";
import ViewClient from "../pages/admin/ViewClient";
import MyProfileAdmin from "../pages/admin/MyProfileAdmin";
import MyProfileClient from "../pages/client/MyProfileClient";
import AccessControl from "../pages/admin/AccessControl";
import InchTracker from "../pages/client/InchTracker";
import WeightTracker from "../pages/client/WeightTracker";
import PlanHistory from "../pages/admin/PlanHistory";
import ClientHistory from "../pages/admin/ClientHistory";
import ManagerHistory from "../pages/admin/ManagerHistory";
import BloodTracker from "../pages/client/BloodTrackerGrid";
import PeriodTracker from "../pages/client/PeriodTrackerGrid";
import MealPlanHistory from "../pages/admin/MealPlanHistory";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Navigation() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const location = useLocation();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //--------------------------------------------------------------------UseEffect to dispatch User Deatails---------------------------------------------------------------------------//

  useEffect(() => {
    let usercache = localStorage.getItem("user");
    if (usercache) {
      dispatch({ type: "USER_DETAILS", payload: JSON.parse(usercache) });
    }
    // if (!JSON.parse(localStorage.getItem("user"))?.id) {
    //   navigation("/");
    // }
  }, []);

  //--------------------------------------------------------------------UseEffect to dispatch User Deatails---------------------------------------------------------------------------//
  return (
    <>
      <Parent routeName={location.pathname}>
        <Routes>
          <Route
            path="/"
            element={<LoginPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="changepassword"
            element={<ChangePassword />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="clientdashboard"
            element={<ClientDashboard />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="inchtracker"
            element={<InchTracker />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="weighttracker"
            element={<WeightTracker />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="periodtracker"
            element={<PeriodTracker />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="admindashboard"
            element={<AdminDashboard />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="plans"
            element={<PlansPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="signup"
            element={<SignupPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="clientassessment"
            element={<ClientAssessmentForm />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="viewclient"
            element={<ViewClient />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="myprofileadmin"
            element={<MyProfileAdmin />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="myprofileclient"
            element={<MyProfileClient />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="planpdf"
            element={<Planpdf />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="planhistory"
            element={<PlanHistory />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="clienthistory"
            element={<ClientHistory />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="managerhistory"
            element={<ManagerHistory />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="invoicepdf"
            element={<InvoicePdf />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="invoice"
            element={<Invoice />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="forgotpassword"
            element={<ForgotPassword />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="manageclients"
            element={<ManageClients />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="newmealplan"
            element={<NewMealPlan />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="accesscontrol"
            element={<AccessControl />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="bloodtracker"
            element={<BloodTracker />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="mealplanhistory"
            element={<MealPlanHistory />}
            errorElement={<ErrorPage />}
          />
        </Routes>
      </Parent>
    </>
  );
}

export default Navigation;
