//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/styles/InvoicePdf.module.css";
import A_Jain_logo from "../assets/images/A_Jain_logo.png";
import login_bg_top from "../assets/images/login_bg_top.png";
import login_bg_bottom from "../assets/images/login_bg_bottom.png";
import bg_bottom_right from "../assets/images/bg_bottom_right.png";
import sms from "../assets/images/sms.png";
import instagramf from "../assets/images/instagramf.png";
import browse from "../assets/images/browse.png";
import contact from "../assets/images/contact.png";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function InvoicePdf() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
const componentRef = useRef();
  let temp = JSON.parse(localStorage.getItem("pageData"));
  const [details, setDetails] = useState([]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Healvick Invoice`,
    onAfterPrint: () => {
      window.close();
    },
  });
//--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

//---------------------------------------------------------UseEffect to Get Invoice By ID-----------------------------------------------------------------------//

  useEffect(() => {
    Request.get(`${BASE_URL}plans/getInvoice?id=${temp}`)
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.data?.length < 1) {
            alert("No invoice generated for you. Please contact the admin");
            window.close();
            localStorage.remove("pageData");
          } else {
            setDetails(res?.data?.data[res?.data?.data?.length - 1]);
            let temp = res?.data?.data[res?.data?.data?.length - 1];
            document.getElementById("invoiceId").innerHTML = temp?.Invoiceno;
            document.getElementById("name").innerHTML = `${temp?.Firstname} ${temp?.Lastname}`;
            document.getElementById("date").innerHTML = moment(temp?.Date).format("YYYY-MM-DD");
            document.getElementById("email").innerHTML = temp?.EmailId;
            document.getElementById("service").innerHTML = temp?.Services;
            document.getElementById("duration").innerHTML = `${temp?.Duration}`;
            document.getElementById("discount").innerHTML = `Discount (if any) : ${temp?.Discount}`;
            document.getElementById("total").innerHTML = `Total: ${temp?.Total} ₹`;
            handlePrint();
          }
        } else {
          alert("Error fetching invoice details");
          window.close();
          localStorage.remove("pageData");
        }
      })
      .catch((err) => {
        console.log("Error fetching invoice details", err);
        // window.close();
      });
  }, []);

  //---------------------------------------------------------UseEffect to Get Invoice By ID-----------------------------------------------------------------------//
  return (
    <div ref={componentRef}>
      <div className={[styles.firstmain]}>
        <img src={A_Jain_logo} className={styles.logosize} alt=""></img>
        <div className={styles.contactdiv}>
          <img src={contact} className={styles.contactimg} alt=""></img>
          <p className={styles.contactno}>+91 1234567890</p>
          <div className={styles.bgtopdiv}>
            <img src={login_bg_top} className={styles.logintop} alt=""></img>
          </div>
        </div>
      </div>

      <div className={styles.theinvoice}>
        <p>Invoice</p>
      </div>

      <div className={styles.flexcols}>
        <div className={styles.clientname}>
          <p className={styles.greyname}>Invoice No.: </p>
          <p id="invoiceId" className={styles.blackname}>
            {" "}
            {details?.Invoiceno}
          </p>
        </div>
        <div className={styles.clientname}>
          <p className={styles.greyname}>Date: </p>
          <p className={styles.blackname} id="date">
            {moment(details?.Date).format("YYYY-MM-DD")}
          </p>
        </div>
        <div className={styles.clientname}>
          <p className={styles.greyname}>Client Name: </p>
          <p className={styles.blackname} id="name">
            {" "}
            {details?.Firstname} {details?.Lastname}
          </p>
        </div>
        <div className={styles.clientname}>
          <p className={styles.greyname}>Email ID: </p>
          <p className={styles.blackname} id="email">
            {" "}
            {details?.EmailId}
          </p>
        </div>
      </div>

      <div className={styles.tablediv}>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th className={styles.thstyle + " " + styles.fixtablewidth}>Program / Services</th>
              <th className={styles.thstyle + " " + styles.fixtablewidth}>Duration</th>
              {/* <th>Food Plan</th> */}
            </tr>
            <tr>
              <td className={styles.tdstyle + " " + styles.borderbottom} id="service">
                {details?.Services}
              </td>
              <td className={styles.tdstyle + " " + styles.borderbottom} id="duration">
                {details?.Duration}
              </td>
            </tr>
            <tr>
              <td className={styles.tdstyle + " " + styles.borderbottom}></td>
              <td className={styles.tdstyle + " " + styles.borderbottom} id="discount">
                Discount (if any) : {details?.Discount}
              </td>
              {/* <td className={styles.borderbottom}>1.Lorem ipsum dolor sit amet consectetur.</td> */}
            </tr>
            <tr>
              <td className={styles.tdstyle + " " + styles.borderbottom}></td>
              <td className={styles.tdstyle + " " + styles.borderbottom}></td>
              {/* <td className={styles.borderbottom}>1.Lorem ipsum dolor sit amet consectetur.</td> */}
            </tr>
            <tr>
              <td className={styles.tdstyle}></td>
              <td className={styles.tdstyle} id="total">
                Total: {parseFloat(details?.Total).toFixed(3)}
              </td>
              {/* <td>1.Lorem ipsum dolor sit amet consectetur.</td> */}
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles.bottomonefifty}>
        <p className={styles.thered}>* Do read the text which is marked in red color.</p>
      </div>

      <div className={styles.footer}>
        <img src={bg_bottom_right} className={styles.logintop} alt=""></img>
        <div className={styles.footermiddle}>
          <div className={styles.middleflex}>
            <div className={styles.footermiddlein + " " + styles.setmarginleft}>
              <img src={instagramf} className={styles.contactimg} alt=""></img>
              <p className={styles.footermiddlep}>dt.arpita_healvick</p>
            </div>
            <div className={styles.footermiddlein}>
              <img src={sms} className={styles.contactimg} alt=""></img>
              <p className={styles.footermiddlep}>dt.arpita@healvick.com</p>
            </div>
            <div className={styles.footermiddlein + " " + styles.setmarginright}>
              <img src={browse} className={styles.contactimg} alt=""></img>
              <p className={styles.footermiddlep}>www.healvick.com</p>
            </div>
          </div>
        </div>
        <img src={login_bg_bottom} className={styles.logintop} alt=""></img>
      </div>
    </div>
  );
}

export default InvoicePdf;
