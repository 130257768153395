//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
import { useImperativeHandle } from "react";
import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA6 = forwardRef(
  (
    {
      setPageIndex,
      adminData,
      profileData,
      medicalHistory,
      medicalHistoryOther,
      currentMedication,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));

    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [img1, setImg1] = useState();
    const [medicalHistoryRadio, setMedicalHistoryRadio] = useState("Diabetes");
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [
        { id: "medicalHistoryOther", name: "errorMessageotherHistory", ref: medicalHistoryOther },
      ];
      checklist.map((val) => {
        if (document.getElementById("medicalHistoryOther").checked) {
          let id = "activityPatternOtherfield";
          let name = "errorMessageotherHistory";
          let propStyle = document.getElementById(id);
          let propText = document.getElementById(name);
          let ref = medicalHistoryOther;
          if (ref?.current?.value?.length < 1) {
            error = true;
            propStyle.style.border = "2px solid red";
            propText.style.display = "block";
            propText.textContent = `Please Enter this field`;
          } else {
            propStyle.style.border = "1.5px solid #D9D9D9";
            propText.style.display = "none";
            propText.textContent = "none";
          }
        }
      });
      !error && setPageIndex(7);
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      medicalHistory.current.value = "Diabetes";
      let cache = JSON.parse(localStorage.getItem("CA6"));
      let data = adminData ? adminData : profileData;

      if (adminData) {
        currentMedication.current.value = data?.CurrentMedication ? cache?.CurrentMedication : null;
        if (data?.MedicalHistory?.length > 0) {
          if (data?.MedicalHistory === "Diabetes") {
            medicalHistory.current.value = "Diabetes";

            setMedicalHistoryRadio("Diabetes");
          } else if (data?.MedicalHistory === "Blood Pressure") {
            medicalHistory.current.value = "Blood Pressure";

            setMedicalHistoryRadio("Blood Pressure");
          } else if (data?.MedicalHistory === "PCOS / Infertility") {
            medicalHistory.current.value = "PCOS / Infertility";

            setMedicalHistoryRadio("PCOS / Infertility");
          } else if (data?.MedicalHistory === "Hyper Thyroid") {
            medicalHistory.current.value = "Hyper Thyroid";

            setMedicalHistoryRadio("Hyper Thyroid");
          } else if (data?.MedicalHistory === "Hypo Thyroid") {
            medicalHistory.current.value = "Hypo Thyroid";

            setMedicalHistoryRadio("Hypo Thyroid");
          } else if (data?.MedicalHistory === "Breathing Difficulties") {
            medicalHistory.current.value = "Breathing Difficulties";

            setMedicalHistoryRadio("Breathing Difficulties");
          } else if (data?.MedicalHistory === "History of Heart Problems/ Stroke") {
            medicalHistory.current.value = "History of Heart Problems/ Stroke";

            setMedicalHistoryRadio("History of Heart Problems/ Stroke");
          } else if (data?.MedicalHistory === "Chronic Illness") {
            medicalHistory.current.value = "Chronic Illness";

            setMedicalHistoryRadio("Chronic Illness");
          } else if (data?.MedicalHistory === "Any kind of Surgery") {
            medicalHistory.current.value = "Any kind of Surgery";

            setMedicalHistoryRadio("Any kind of Surgery");
          } else if (data?.MedicalHistory === "Constipation") {
            medicalHistory.current.value = "Constipation";

            setMedicalHistoryRadio("Constipation");
          } else if (data?.MedicalHistory === "Acidity") {
            medicalHistory.current.value = "Acidity";

            setMedicalHistoryRadio("Acidity");
          } else {
            medicalHistory.current.value = "Other";
            medicalHistoryOther.current.value = data?.MedicalHistory ? data.MedicalHistory : "";
            currentMedication.current.value = data?.CurrentMedication;
            setMedicalHistoryRadio("Other");
          }
        }
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        currentMedication.current.value = data?.CurrentMedication ? data?.CurrentMedication : null;
        if (data?.MedicalHistory?.length > 0) {
          if (data?.MedicalHistory === "Diabetes") {
            medicalHistory.current.value = "Diabetes";

            setMedicalHistoryRadio("Diabetes");
          } else if (data?.MedicalHistory === "Blood Pressure") {
            medicalHistory.current.value = "Blood Pressure";

            setMedicalHistoryRadio("Blood Pressure");
          } else if (data?.MedicalHistory === "PCOS / Infertility") {
            medicalHistory.current.value = "PCOS / Infertility";

            setMedicalHistoryRadio("PCOS / Infertility");
          } else if (data?.MedicalHistory === "Hyper Thyroid") {
            medicalHistory.current.value = "Hyper Thyroid";

            setMedicalHistoryRadio("Hyper Thyroid");
          } else if (data?.MedicalHistory === "Hypo Thyroid") {
            medicalHistory.current.value = "Hypo Thyroid";

            setMedicalHistoryRadio("Hypo Thyroid");
          } else if (data?.MedicalHistory === "Breathing Difficulties") {
            medicalHistory.current.value = "Breathing Difficulties";

            setMedicalHistoryRadio("Breathing Difficulties");
          } else if (data?.MedicalHistory === "History of Heart Problems/ Stroke") {
            medicalHistory.current.value = "History of Heart Problems/ Stroke";

            setMedicalHistoryRadio("History of Heart Problems/ Stroke");
          } else if (data?.MedicalHistory === "Chronic Illness") {
            medicalHistory.current.value = "Chronic Illness";

            setMedicalHistoryRadio("Chronic Illness");
          } else if (data?.MedicalHistory === "Any kind of Surgery") {
            medicalHistory.current.value = "Any kind of Surgery";

            setMedicalHistoryRadio("Any kind of Surgery");
          } else if (data?.MedicalHistory === "Constipation") {
            medicalHistory.current.value = "Constipation";

            setMedicalHistoryRadio("Constipation");
          } else if (data?.MedicalHistory === "Acidity") {
            medicalHistory.current.value = "Acidity";

            setMedicalHistoryRadio("Acidity");
          } else {
            medicalHistory.current.value = "Other";
            medicalHistoryOther.current.value = data?.MedicalHistory ? data.MedicalHistory : "";
            setMedicalHistoryRadio("Other");
          }
        }
      }
    }, [adminData, profileData]);

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      currentMedication.current.value = cache?.CurrentMedication ? cache?.CurrentMedication : null;
      if (cache?.MedicalHistory?.length > 0) {
        if (cache?.MedicalHistory === "Diabetes") {
          medicalHistory.current.value = "Diabetes";

          setMedicalHistoryRadio("Diabetes");
        } else if (cache?.MedicalHistory === "Blood Pressure") {
          medicalHistory.current.value = "Blood Pressure";

          setMedicalHistoryRadio("Blood Pressure");
        } else if (cache?.MedicalHistory === "PCOS / Infertility") {
          medicalHistory.current.value = "PCOS / Infertility";

          setMedicalHistoryRadio("PCOS / Infertility");
        } else if (cache?.MedicalHistory === "Hyper Thyroid") {
          medicalHistory.current.value = "Hyper Thyroid";

          setMedicalHistoryRadio("Hyper Thyroid");
        } else if (cache?.MedicalHistory === "Hypo Thyroid") {
          medicalHistory.current.value = "Hypo Thyroid";

          setMedicalHistoryRadio("Hypo Thyroid");
        } else if (cache?.MedicalHistory === "Breathing Difficulties") {
          medicalHistory.current.value = "Breathing Difficulties";

          setMedicalHistoryRadio("Breathing Difficulties");
        } else if (cache?.MedicalHistory === "History of Heart Problems/ Stroke") {
          medicalHistory.current.value = "History of Heart Problems/ Stroke";

          setMedicalHistoryRadio("History of Heart Problems/ Stroke");
        } else if (cache?.MedicalHistory === "Chronic Illness") {
          medicalHistory.current.value = "Chronic Illness";

          setMedicalHistoryRadio("Chronic Illness");
        } else if (cache?.MedicalHistory === "Any kind of Surgery") {
          medicalHistory.current.value = "Any kind of Surgery";

          setMedicalHistoryRadio("Any kind of Surgery");
        } else if (cache?.MedicalHistory === "Constipation") {
          medicalHistory.current.value = "Constipation";

          setMedicalHistoryRadio("Constipation");
        } else if (cache?.MedicalHistory === "Acidity") {
          medicalHistory.current.value = "Acidity";

          setMedicalHistoryRadio("Acidity");
        } else {
          medicalHistory.current.value = "Other";
          medicalHistoryOther.current.value = cache?.MedicalHistory ? cache.MedicalHistory : "";
          setMedicalHistoryRadio("Other");
        }
      }
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//
    return (
      <div className={style.medicalConditionForm}>
        <label className={style.medicalConditionFormLabel}>Medical Conditions</label>
        <div className={style.firstRowOfMC}>
          <div className={style.medicalHistoryRadioInput} ref={medicalHistory}>
            <label>
              Do you have any medical history? <span>*</span> <span> </span>
            </label>
            <div className={style.medicalHistoryRadioDiv}>
              <div className={style.medicalHistoryRadioDivRowOne}>
                <div
                  className={style.medicalHistoryDiabetes}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Diabetes";
                      setMedicalHistoryRadio("Diabetes");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryDiabetes"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Diabetes" ? true : false}
                  />
                  <label htmlFor="medicalHistoryDiabetes">Diabetes</label>
                </div>
                <div
                  className={style.medicalHistoryBloodPressure}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Blood Pressure";
                      setMedicalHistoryRadio("Blood Pressure");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryBloodPressure"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Blood Pressure" ? true : false}
                  />
                  <label htmlFor="medicalHistoryBloodPressure">Blood Pressure</label>
                </div>
                <div
                  className={style.medicalHistoryPcosInt}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "PCOS / Infertility";
                      setMedicalHistoryRadio("PCOS / Infertility");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryPcosInt"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "PCOS / Infertility" ? true : false}
                  />
                  <label htmlFor="medicalHistoryPcosInt">PCOS / Infertility</label>
                </div>
                <div
                  className={style.medicalHistoryHyperThyroid}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Hyper Thyroid";
                      setMedicalHistoryRadio("Hyper Thyroid");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryHyperThyroid"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Hyper Thyroid" ? true : false}
                  />
                  <label htmlFor="medicalHistoryHyperThyroid">Hyper Thyroid</label>
                </div>
                <div
                  className={style.medicalHistoryHypoThyroid}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Hypo Thyroid";
                      setMedicalHistoryRadio("Hypo Thyroid");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryHypoThyroid"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Hypo Thyroid" ? true : false}
                  />
                  <label htmlFor="medicalHistoryHypoThyroid">Hypo Thyroid</label>
                </div>
                <div
                  className={style.medicalHistoryBreathingDifficulties}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Breathing Difficulties";
                      setMedicalHistoryRadio("Breathing Difficulties");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryBreathingDifficulties"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Breathing Difficulties" ? true : false}
                  />
                  <label htmlFor="medicalHistoryBreathingDifficulties">
                    Breathing Difficulties
                  </label>
                </div>
              </div>
              <div className={style.medicalHistoryRadioDivRowTwo}>
                <div
                  className={style.medicalHistoryHeartProblemsStroke}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "History of Heart Problems/ Stroke";
                      setMedicalHistoryRadio("History of Heart Problems/ Stroke");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryHeartProblemsStroke"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={
                      medicalHistoryRadio === "History of Heart Problems/ Stroke" ? true : false
                    }
                  />
                  <label htmlFor="medicalHistoryHeartProblemsStroke">
                    History of Heart Problems/ Stroke
                  </label>
                </div>
                <div
                  className={style.medicalHistoryChronicIllness}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Chronic Illness";
                      setMedicalHistoryRadio("Chronic Illness");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryChronicIllness"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Chronic Illness" ? true : false}
                  />
                  <label htmlFor="medicalHistoryChronicIllness">Chronic Illness</label>
                </div>
                <div
                  className={style.medicalHistorySurgery}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Any kind of Surgery";
                      setMedicalHistoryRadio("Any kind of Surgery");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistorySurgery"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Any kind of Surgery" ? true : false}
                  />
                  <label htmlFor="medicalHistorySurgery">Any kind of Surgery</label>
                </div>
                <div
                  className={style.medicalHistoryConstipation}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Constipation";
                      setMedicalHistoryRadio("Constipation");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryConstipation"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Constipation" ? true : false}
                  />
                  <label htmlFor="medicalHistoryConstipation">Constipation</label>
                </div>
                <div
                  className={style.medicalHistoryAcidity}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Acidity";
                      setMedicalHistoryRadio("Acidity");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryAcidity"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Acidity" ? true : false}
                  />
                  <label htmlFor="medicalHistoryAcidity">Acidity</label>
                </div>
                <div
                  className={style.medicalHistoryOther}
                  onClick={() => {
                    if (!adminData) {
                      medicalHistory.current.value = "Other";
                      setMedicalHistoryRadio("Other");
                    }
                  }}
                >
                  <input
                    onChange={() => {}}
                    id="medicalHistoryOther"
                    type="radio"
                    name="physicalActivityRadioSelection"
                    checked={medicalHistoryRadio === "Other" ? true : false}
                  />
                  <label htmlFor="medicalHistoryOther">Other</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.secondRowOfMC}>
          <div className={style.otherInputMC}>
            <label>
              Other<span></span>
            </label>
            <input
              onChange={(text) => {
                medicalHistoryOther.current.value = text.target.value;
              }}
              type="text"
              placeholder="Other"
              id="activityPatternOtherfield"
              ref={medicalHistoryOther}
            />
            <span className={style.errorSpan} id="errorMessageotherHistory"></span>
          </div>
        </div>
        <div className={style.thirdRowOfMC}>
          <div className={style.medicationNow}>
            <label>
              Are you on any medication currently ?<span>*</span>
            </label>
            <input
              onChange={(text) => {
                currentMedication.current.value = text.target.value;
              }}
              type="text"
              ref={currentMedication}
              placeholder="Other"
              id="activityPatternOtherfield"
            />
            <span className={style.errorSpan} id="errorMessageother"></span>
          </div>
          {/* <div className={style.uploadFile}>
            <label className={style.uploadHeading}>Please attach if any medical reports</label>
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={(event) => {
                var file = event.target.files[0];
                setImg1(file);
              }}
            />
            {
              <div
                id="img-preview"
                className={style.imgPreview}
                style={{ display: !img1 && "none" }}
              ></div>
            }
            {!img1 && (
              <>
                <div className={style.fileuploader}>
                  <div id="upload-label">
                    <img className={style.uploadIconcls} src={uploadIcon} alt="" />
                  </div>
                  <div className={style.jpgtitle}>.jpg, .png</div>
                  <div className={style.medfileExcls}>Upload Medical Reports</div>
                </div>
                <div className={style.theLeftflexbtn}>
                  <button className={style.MedButtoncls}>Upload Medical Report</button>
                </div>
              </>
            )}
          </div> */}
        </div>
      </div>
    );
  }
);

export default CA6;
